<span>
  {{message}}
</span>
<div *ngIf="!selectAll" class="options-container">
  <span *ngFor="let option of options">
    {{option}}
  </span>
</div>
<button *ngIf="selectAll && !readOnly" class="options-container select-all-button"
  (click)="selectAllOptions.emit(); log('select all checkboxes')" type="button">
  <span class="selectAll">Select All</span>
</button>