import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeSignatureFormElement } from '../../models/DeTemplateForms';
import { FormElementComponent } from '../form-element.component';

import SignaturePad from 'signature_pad';

@Component({
  selector: 'de-signature-panel',
  templateUrl: './de-signature-panel.component.html',
  styleUrls: ['./de-signature-panel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeSignaturePanelComponent
    }
  ]
})
/**
 * See https://www.npmjs.com/package/signature_pad for documentation on the signature pad library being used here
 */
export class DeSignaturePanelComponent extends FormElementComponent<string> implements OnInit, ControlValueAccessor, AfterViewInit {

  /**
   * Optional event logger
   */
  @Input() logEvent: (eventName: string, eventParams?: any) => {};

  @Input() formElement: DeSignatureFormElement;

  @Input() readOnly: boolean;

  showSignaturePanel: boolean;
  signaturePad: SignaturePad;
  canvas: HTMLCanvasElement;

  @HostBinding('class.focused') get focused() {
    return this._focused;
  }
  @HostBinding('class.error') get hasError() {
    return this._touched && ((!this._hasValue && this.required) || this.regexValidationFailed);
  }
  @HostBinding('class.regexValidationFailed') get regexValidationFailed() {
    return !this._matchesRegexValidation;
  }
  @HostBinding('class.required') get required() {
    return this.formElement.validators?.required ?? false;
  }
  @HostBinding('class.hasValue') get hasValue() {
    return this._hasValue;
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.setSignaturePanelMethod(!!this._value?.match(/data:image\/png/) || !this._value);
  }

  get id() {
    return this.formElement.element_id;
  }

  get label() {
    return this.formElement.field_display ?? '';
  }

  get placeholder() {
    return this.formElement.placeholder ?? '';
  }

  get link() {
    return this.formElement.link;
  }

  get linkText() {
    return this.formElement.link_text;
  }

  get confirms() {
    return this.formElement.confirms;
  }

  valueChange(value: string, matchesValidators?: boolean): void {
    super.valueChange(value, matchesValidators);
  }

  setSignaturePanelMethod(showSignaturePanel: boolean) {
    this.showSignaturePanel = showSignaturePanel;
    if (showSignaturePanel) {
      setTimeout(() => {
        this.canvas = document.getElementById(`${this.id}_canvas`) as HTMLCanvasElement;
        this.resizeCanvas();
        this.configureSignaturePanel();
      });
    }
  }

  async configureSignaturePanel() {
    this.signaturePad = new SignaturePad(
      this.canvas,
      {
        penColor: (this.readOnly || this._disabled) ? 'transparent' : "#696969",
      }
    );
    if (this._value?.match(/data:image\/png/)) {
      await this.signaturePad.fromDataURL(this._value)
        .catch(() => {
          this.setSignaturePanelMethod(false)
          return;
        });
    }
    this.signaturePad.addEventListener('endStroke', () => {
      if (!(this.readOnly || this._disabled)) {
        this.valueChange(this.signaturePad.toDataURL('image/png'));
      }
    });
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext("2d").scale(ratio, ratio);
  }

  clear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.valueChange(null);
  }

  log(log: string) {
    if (this.logEvent) {
      this.logEvent(`[${this.formElement.field_name}] ${log}`);
    }
  }
}
