import { Injectable } from '@angular/core';
import { DeSnackbarService } from '@de-electron/electron-angular-components';
import { DeSnackbarType } from '@de-electron/electron-angular-components/lib/de-snackbar/de-snackbar-config';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: DeSnackbarService) { }

  /**
   * Displays the DE snackbar
   *
   * @param message Message to display
   * @param type Alert type
   */
  openSnackbar(message: string, type: DeSnackbarType, duration = 4000) {
    this.snackbar.show({
      type,
      text: message,
      clickEventContent: 'text',
      duration,
      desktopPosition: 'bottom',
      desktopOffset: 25,
      mobileOffset: 25
    });
  }
}
