import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { ILoggerCallback } from '@azure/msal-common';
import { env } from 'src/config/env';
import { environments } from 'src/config/env.config';

// const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azure_client_id,
      authority: `https://login.microsoftonline.com/${environments[env.env].azure_tenant}`,
      redirectUri: environments[env.env].app_url,
      postLogoutRedirectUri: `${environments[env.env].app_url}login`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', [`${environments[env.env].azure_client_id}/.default`]],
    [`${environments[env.env].apiBaseUrl}/contactus`, null],
    [`${environments[env.env].apiBaseUrl}/*`, [`${environments[env.env].azure_client_id}/.default`]],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`${environments[env.env].azure_client_id}/.default`],
    },
    loginFailedRoute: `${environments[env.env].app_url}login`
  };
}

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
  console.error(`${message}`);
};