<div class="header">
  <!-- <button #closeButton aria-label="Close" class="close-button" (click)="close()"></button> -->
  <h2>{{selectHeader}}</h2>
</div>

<div class="selection-container" *ngIf="selectFormElement">
  <div class="selection">
    <de-select [formElement]="selectFormElement" [formControl]="selectFormElement.form_control"></de-select>
  </div>

  <div class="actions">
    <button class="filled-button" (click)="confirm()"
      [disabled]="!selectFormElement.form_control.valid">{{selectActionText}}</button>
  </div>
</div>