import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeFormComponentType, DeSelectFormElement } from 'projects/de-template-forms/src/lib/models/DeTemplateForms';
import { Subject } from 'rxjs';
import { CDOverlayRef, DIALOG_DATA } from 'src/app/services/overlay/overlay.service';

export interface SelectDialogConfig {
  options: string[];
  selectHeader: string;
  selectActionText: string;
  initialSelection: string;
}

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent implements OnInit {
  // @ViewChild('closeButton') closeButtonRef: ElementRef<HTMLButtonElement>;

  options: string[] = [];
  selectHeader: string;
  selectActionText: string;

  formGroup = new UntypedFormGroup({
    selectFormElement: new UntypedFormControl(null, Validators.required)
  });

  selectFormElement: DeSelectFormElement;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private dialogRef: CDOverlayRef,
    @Inject(DIALOG_DATA) public data: SelectDialogConfig
  ) { }

  ngOnInit(): void {
    this.options = this.data?.options ?? [];
    this.selectHeader = this.data?.selectHeader ?? 'Select';
    this.selectActionText = this.data?.selectActionText ?? 'Confirm Selection';
    this.selectFormElement = {
      component_type: DeFormComponentType.Dropdown,
      is_data_field: true,
      element_id: 'options_select',
      field_name: 'options_select',
      field_display: this.selectHeader,
      placeholder: this.selectHeader,
      options: this.options,
      form_control: this.formGroup.get('selectFormElement') as UntypedFormControl
    };
    if (this.data?.initialSelection?.length > 0 && this.options.includes(this.data.initialSelection)) {
      this.selectFormElement.form_control.setValue([this.data.initialSelection]);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    // this.closeButtonRef?.nativeElement?.focus();
  }

  confirm() {
    this.dialogRef.close(this.selectFormElement.form_control.value?.[0]);
  }

  close() {
    this.dialogRef.close();
  }
}
