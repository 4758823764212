import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DeComponent, DeFormElement, DeFormComponentType, DeSection, DeTemplateForm, DeForm } from '../models/DeTemplateForms';
import { dependenciesAreMet, DeTemplateFormsService, generateValidators } from '../services/de-template-forms.service';
import { Location } from '@angular/common';

@Component({
  selector: 'de-template-form',
  templateUrl: './de-template-form.component.html',
  styleUrls: ['./de-template-form.component.scss']
})
export class DeTemplateFormComponent implements OnInit, OnDestroy {

  _form: DeForm;

  /**
   * Optional event logger
   */
  @Input() logEvent: (eventName: string, eventParams?: any) => {};

  @Input()
  set form(form: DeForm) {
    this._form = form;
    this.populateFormGroup();
  }
  @Input()
  set formValue(val: any) {
    this._form.form_value = val;
    this.populateFormGroup();
  }
  @Output() formValueChange = new EventEmitter<any>();
  @Output() formGroupRefChange = new EventEmitter<UntypedFormGroup>();
  @Output() save = new EventEmitter();

  _formTemplate: DeTemplateForm;
  _formGroup: UntypedFormGroup;

  _expandedSection: DeSection;

  readonly _destroying$ = new Subject<void>();

  loadingTemplate: boolean;

  constructor(
    private deTemplateFormsService: DeTemplateFormsService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) { }

  ngOnInit() {
    this.configureForm();

    this.deTemplateFormsService.currentFormReset$.subscribe(
      () => this.configureForm()
    );
  }

  ngOnDestroy() {
    this.deTemplateFormsService.closeSection();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  get anySectionExpanded() {
    return this.deTemplateFormsService.anySectionIsExpanded;
  }

  // get status() {
  //   return this.deTemplateFormsService.formIsComplete(this._form)
  //     ? 'Complete'
  //     : 'Incomplete';
  // }

  configureForm() {
    this.loadingTemplate = true;
    this.deTemplateFormsService.setForm(this._form)
      .then(
        template => {
          this._formTemplate = template;
          this.configureFormGroup();
        }
      )
      .catch(
        err => console.error('Error fetching form template')
      )
      .finally(
        () => this.loadingTemplate = false
      );
  }

  /**
   * Set value of form group and update form group validators
   */
  setFormGroupValueAndUpdateValidators() {
    this.populateFormGroup();
    this.updateFormGroupValidators(this._formTemplate.components);
  }

  async configureFormGroup() {
    // Generate form group using form template
    this._formGroup = this.deTemplateFormsService.generateFormGroup(this._formTemplate.components);

    this.setFormGroupValueAndUpdateValidators();

    const expanded: boolean[] = [];
    // Open each section to render and validate dynamic children
    await new Promise<void>(resolve => this._formTemplate.components?.forEach((c, i, arr) => {
      expanded[i] = (c as DeSection).expanded;
      this.deTemplateFormsService.openSection(c as DeSection);
      this.changeDetectorRef.detectChanges();
      this.deTemplateFormsService.closeSection();
      if (i === arr.length - 1) {
        // Preserves initial expanded state of sections
        this._formTemplate.components?.forEach((co, j) => (co as DeSection).expanded = expanded[j]);
        resolve();
      }
    }));

    // Set value of form group and update form group validators again
    // (some dynamic children may have been rendered after the previous step)
    this.setFormGroupValueAndUpdateValidators();

    this._formGroup.valueChanges
      .pipe(
        debounceTime(150),
        takeUntil(this._destroying$)
      )
      .subscribe(
        // Emit the raw value so that any disabled/hidden fields are included in the structue
        _ => {
          this.formValueChange.emit(this._formGroup.getRawValue());
          this.updateFormGroupValidators(this._formTemplate.components);
        }
      );

    this.formGroupRefChange.emit(this._formGroup);
  }

  updateFormGroupValidators(components: DeComponent[]) {
    components.forEach(component => {
      if ((component as DeSection).form_group instanceof UntypedFormGroup) {
        this.updateFormGroupValidators((component as DeSection).components ?? []);
        (component as DeSection).form_group.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      } else if ((component as DeFormElement).form_control instanceof UntypedFormControl
        // Validators for add/remove sections get set when they are first initialized or modified,
        // so we don't want to overwrite that here
        && component.component_type !== DeFormComponentType.AddRemoveSection) {
        const formElement = component as DeFormElement;
        formElement.form_control.setValidators(generateValidators(component, this._formGroup));
        if (formElement.remove_values_when_hidden && !dependenciesAreMet(this._formGroup, formElement.depends_on)) {
          formElement.form_control?.setValue?.(null, { emitEvent: false, onlySelf: true })
        }
        formElement.form_control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    });
  }

  populateFormGroup() {
    if (!this._form?.form_value || !this._formGroup) {
      return;
    }
    try {
      this._formGroup.setValue(this._form.form_value, { emitEvent: false });
    } catch (e) {
      // Handle any error so the previous form value isn't lost...
      // We need to fill in the new template where we can by recursively going
      // through the form value and setting the formgroup values when qualifying names match
      this.populateFormgroupWithFormValue(this._form.form_value);
    }

    this.formValueChange.emit(this._form.form_value);
  }

  /**
   * Recursively traverses a form value and sets formgroup values when qualifying names match
   *
   * @param form Form value
   * @param parentKey Key that mapped to the current form value
   */
  populateFormgroupWithFormValue(form: any, parentKey = '') {
    Object.keys(form ?? {}).forEach(k => {
      const key = `${parentKey}${parentKey?.length > 0 ? '.' : ''}${k}`;
      const formControl = this._formGroup.get(key);
      /**
       * If we can't find a matching form control (or there is an error populating the form)
       * and k maps to a non-list object, then we will recursively try to populate the form
       * group with those values
       */
      const valueIsNonListObject = form[k] && typeof form[k] === 'object' && !form[k].length;

      if (formControl) {
        try {
          formControl.setValue(form[k], { emitEvent: false });
          // console.log(`Successfully set ${key} to:`);
          // console.log(form[k]);
        } catch {
          if (valueIsNonListObject) {
            this.populateFormgroupWithFormValue(form[k], k);
          }
        }
      } else if (valueIsNonListObject) {
        this.populateFormgroupWithFormValue(form[k], k);
      }
    });
  }
}
