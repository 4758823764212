import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { DeAddRemoveSectionFormElement, DeComponent, DeFormElement, DeFormElementDependency, DeSection } from '../../models/DeTemplateForms';
import { dependenciesAreMet, DeTemplateFormsService } from '../../services/de-template-forms.service';
import { FormElementComponent } from '../form-element.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { CrewFavorite } from 'src/app/services/crew-favorites/crew-favorites.service';
import { LocalDatabaseService } from 'src/app/services/storage/local-database.service';
import { NavigationService } from 'src/app/services/routing/navigation.service';
import { UserSettingsService } from 'src/app/services/settings/user-settings.service';


const validate = (formGroups: UntypedFormGroup[]) => (control: AbstractControl): ValidationErrors | null => {
  return formGroups?.some(g => !g.valid)
    ? { componentHasError: true }
    : null;
}

const adjustDependency = (index: number) => (d: DeFormElementDependency) => ({
  ...d,
  // If a dependency is wrapped in {}, strips out the {} and adds '_index' to the end of the dependency.
  // This will indicate that dependencies need only be checked within the current add/remove section,
  // rather than the entire form itself
  dependency_field_name: /^{.+}$/i.test(d.dependency_field_name)
    ? `${d.dependency_field_name.slice(1, d.dependency_field_name.length - 1)}_${index}`
    : d.dependency_field_name
});

@Component({
  selector: 'de-add-remove-section',
  templateUrl: './de-add-remove-section.component.html',
  styleUrls: ['./de-add-remove-section.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeAddRemoveSectionComponent
    }
  ]
})
export class DeAddRemoveSectionComponent extends FormElementComponent<any[]> implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  /**
   * Optional event logger
   */
  @Input() logEvent: (eventName: string, eventParams?: any) => {};

  @Input() formElement: DeAddRemoveSectionFormElement;

  @Input() formGroupRef: UntypedFormGroup;

  @Input() readOnly: boolean;

  @Output() generatedFormComponents = new EventEmitter<DeComponent[][]>();
  @Output() sectionExpanded = new EventEmitter<DeSection>();

  componentGroups: DeComponent[][] = [];
  formGroups: UntypedFormGroup[] = [];

  valid: boolean;

  private readonly _destroying$ = new Subject<void>();

  @ViewChildren('componentGroups') componentGroupsQueryList: QueryList<ElementRef>;

  constructor(
    private deTemplateFormsService: DeTemplateFormsService,
    private localDB: LocalDatabaseService,
    private navigationService: NavigationService,
    private userSettings: UserSettingsService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.configureGroupsAndValidators();
  }

  ngOnDestroy() {
    this.configureGroupsAndValidators();
    this._destroying$.next();
    this._destroying$.complete();
  }

  get header() {
    return this.formElement.field_display;
  }

  get addText() {
    return this.formElement.add_text;
  }

  get deleteText() {
    return this.formElement.delete_text;
  }

  get secondaryActionText() {
    return this.formElement.secondary_action_text;
  }

  get secondaryActionEnabled() {
    return this.formElement.secondary_action_enabled;
  }

  get components() {
    return this.formElement.components ?? [];
  }

  get color() {
    return this.formElement.color ?? 'white';
  }

  get max() {
    return this.formElement.max;
  }

  configureGroupsAndValidators() {
    return new Promise<void>(
      resolve =>
        setTimeout(() => {
          this.updateComponentGroups(this._value);
          this.updateValidators();
          resolve();
        })
    );
  }

  updateValidators() {
    if (this._value) {
      this.formElement.form_control.setValidators([validate(this.formGroups)]);
      this.formElement.form_control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  async addSection() {
    this.valueChange([...(this._value ?? []), {}]);
    await this.configureGroupsAndValidators();
    const firstComponentInNewSection = this.componentGroups?.[this.componentGroups?.length - 1]?.[0];
    if (firstComponentInNewSection?.component_type === 'section') {
      const section = firstComponentInNewSection as DeSection;
      this.deTemplateFormsService.openSection(section);
      this.sectionExpanded.emit(section);
    } else if (this.componentGroups?.length > 0) {
      // If we're not opening a section, scroll the new component group to the center of the screen
      setTimeout(() => {
        const lastComponentGroupDiv = this.componentGroupsQueryList?.get?.(this.componentGroupsQueryList?.length - 1)?.nativeElement as HTMLDivElement;
        lastComponentGroupDiv?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }

  deleteSection(index: number) {
    this.deTemplateFormsService._isExpanded$.next({expanded: false, index: index});
    this.componentGroups.splice(index, 1);
    this.formGroups.splice(index, 1);
    this._value.splice(index, 1);
    this.valueChange(this._value);
    this.configureGroupsAndValidators();
  }

  secondaryAction(index: number) {
    this.formElement.secondary_action_callback?.(index);
  }

  updateComponentGroups(values: any[]) {
    this.componentGroups = [];
    this.formGroups = [];
    values?.forEach(
      (value, i) => this.createFormGroup(i, value)
    );
    this.generatedFormComponents.emit(this.componentGroups);
  }

  createFormGroup(index: number, initialValue?: any) {
    
    // Get userType
    let userType = '';
    this.userSettings.userType
      .pipe(takeUntil(this._destroying$))
      .subscribe(uType => userType = uType) 

    // Create a copy of all components in the section
    const copyOfComponents = this.formElement.components.map(c => ({
      ...c,
      field_name: `${c.field_name}_${index}`,
      components: (c as DeSection).components?.length > 0
        ? (c as DeSection).components.map(co => ({
          ...co,
          form_control: undefined,
          depends_on: co.depends_on?.map(adjustDependency(index)),
          validators: (co as DeFormElement).validators
            ? {
              ...(co as DeFormElement).validators,
              required: Array.isArray((co as DeFormElement).validators?.required)
                ? ((co as DeFormElement).validators?.required as DeFormElementDependency[])?.map(adjustDependency(index))
                : (co as DeFormElement).validators?.required
            }
            : (co as DeFormElement).validators
        }))
        : undefined,
      depends_on: c.depends_on?.map(adjustDependency(index)),
      validators: (c as DeFormElement).validators
        ? {
          ...(c as DeFormElement).validators,
          required: Array.isArray((c as DeFormElement).validators?.required)
            ? ((c as DeFormElement).validators?.required as DeFormElementDependency[])?.map(adjustDependency(index))
            : (c as DeFormElement).validators?.required
        }
        : (c as DeFormElement).validators
    }));

    // Generate form group
    let formGroup = this.deTemplateFormsService.generateFormGroup(copyOfComponents);

    // Set value of form group
    if (initialValue && Object.keys(initialValue ?? {}).length > 0) {
      Object.keys(initialValue).forEach(
        k => {
          const segments = k.split('_');
          const i = parseInt(segments.pop(), 10);
          if (i > -1 && i !== index) {
            const newKey = `${segments.join('_')}_${index}`;
            initialValue[newKey] = initialValue[k];
            delete initialValue[k];
          }
        }
      );
      formGroup.patchValue(initialValue);
    }


    // Find all dependency names for dependencies within this section
    const componentInnerDependencies = copyOfComponents
      .filter(c =>
        c.depends_on?.some(d => /_\d$/i.test(d.dependency_field_name)) || (userType === "transmission" && !location.href.includes('participant_sign_off') && c?.field_name?.includes("crew_member"))
      )
      .flatMap(c => c.depends_on?.map(d => d.dependency_field_name));

    // Filter out any components whose dependencies WITHIN THIS SECTION are NOT met
    const filteredCopy = copyOfComponents.filter(c => {
      return !c.depends_on?.some(d => /_\d$/i.test(d.dependency_field_name))
      || dependenciesAreMet(formGroup, c.depends_on)
  });
    filteredCopy.forEach(c => {
      if(c?.field_name?.includes("crew_member") && !formGroup?.value?.[c?.field_name]?.roles_and_responsibilities?.includes("Other Equipment Operator")) {
        c?.components?.splice(3,1);
      }
    });

    // Generate a new form group if components were filtered
    if (filteredCopy?.length !== copyOfComponents?.length) {
      formGroup = this.deTemplateFormsService.generateFormGroup(filteredCopy);

      formGroup.patchValue(initialValue);
    }

    const valueRef = this._value[index];
    const componentsRef = this.componentGroups[index];

    formGroup.valueChanges.pipe(
      takeUntil(this._destroying$)
    )
      .subscribe(
        _ => {
          let innerDependencyChanged = false;
          const rawValue = formGroup.getRawValue() ?? {};
          Object.keys(rawValue).forEach(
            k => {
              // An inner dependency has changed if the key is in componentInnerDependencies
              // and the original valueRef value is different
              innerDependencyChanged = innerDependencyChanged
                || (userType === "transmission" && !location.href.includes('participant_sign_off') && k?.includes("crew_member") ? JSON.stringify(valueRef[k]?.roles_and_responsibilities) !== JSON.stringify(rawValue[k]?.roles_and_responsibilities) : (componentInnerDependencies.includes(k) && valueRef[k] !== rawValue[k]))

              valueRef[k] = rawValue[k] ?? valueRef[k];
              // If the component(s) is/are section(s), go through their components
              // to update the form controls of their individual components to match the
              // ref of this formgroup's controls
              componentsRef
                ?.filter(c => (c as DeSection).components?.length > 0)
                .forEach(c =>
                  (c as DeSection).components.forEach(
                    co => (co as DeFormElement).form_control = formGroup.get(k) as UntypedFormControl
                  )
                );

                // Open section crew_member
                if(userType === "transmission"
                  && this.formElement.displayedComponents[index][0]?.field_name?.includes("crew_member")  
                  && innerDependencyChanged) {
                  this.deTemplateFormsService._isExpanded$.next({expanded: true, index: index});
                }
            }
          );

          setTimeout(() => this.updateValidators());

          if (!this.formElement.standalone && componentsRef?.some(c => (c as DeSection).popup)) {
            this.deTemplateFormsService.notifyOpenSectionSubscribers();
          }

          // This indicates we need to reconfigure the form group, since a dependency within
          // this section has changed and will result in an updated list of components
          if (innerDependencyChanged) {
            this._destroying$.next();
            this.configureGroupsAndValidators();
          }
        }
      );

      
    if(userType === "transmission" && !location.href.includes('participant_sign_off') && !location.href.includes('crew-favorites') && filteredCopy[0]?.field_name?.includes("crew_member")){
      // Close any opened popup sections of crew_member when clicking back or continue
      this.navigationService.expanded$
      .pipe(takeUntil(this._destroying$))
      .subscribe((expanded) => {
        setTimeout(() => {
          (filteredCopy[0] as DeSection).expanded = expanded;
        }, 10);
        
      });

      this.deTemplateFormsService.isExpanded$()
      .pipe(takeUntil(this._destroying$))
      .subscribe(val => {
        setTimeout(() => {
          if(!location.href.includes('participant_sign_off')){
            if(val.expanded && this.componentGroups[index] && this.componentGroups[index][0]?.field_name === `crew_member_${val.index}`){
              (this.componentGroups[index][0] as DeSection).expanded = val.expanded
            }
            if(!val.expanded && filteredCopy[0]){
              (filteredCopy[0] as DeSection).expanded = false;
            }
          }
      }, 10);
      });
    }

    this.componentGroups.push(filteredCopy);
    this.formGroups.push(formGroup);
    this.formElement.displayedComponents = [...this.componentGroups];
    if (!this.formElement.standalone) {
      this.deTemplateFormsService.notifyOpenSectionSubscribers();
    }
  }

  log(log: string) {
    if (this.logEvent) {
      this.logEvent(`[${this.formElement.field_name}] ${log}`);
    }
  }

  reorderCrewFavorites(event: CdkDragDrop<DeComponent[][]>) {
    moveItemInArray(this.componentGroups, event.previousIndex, event.currentIndex);
    const orderedCrewFavorites: CrewFavorite[] = [];
    this.componentGroups?.forEach(c => {
      const name: string = ((c[0] as DeSection)?.popup_primary_data as string);
      const phone: string = ((c[0] as DeSection)?.popup_secondary_data as string);
      orderedCrewFavorites.push({name: name, phone: phone});
    });
    this.localDB.postObjectToGenericTable('crew_favorites', orderedCrewFavorites)
          .then(() => console.log('Successfully updated crew favorites order in local DB.'))
          .catch(err => console.log(err));
  }
}
