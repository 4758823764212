import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeForm, DeTemplateForm, DeTemplateFormMetadata, fleet, FleetFormMetadata, line, LineFormMetadata, nonCraft, NonCraftFormMetadata, transmission, TransmissionFormMetadata } from 'projects/de-template-forms/src/lib/models/DeTemplateForms';
import { from, Observable, of, Subject, throwError, TimeoutError } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';
import { DynatraceService } from '../dynatrace/dynatrace.service';
import { UserType } from '../forms/Forms';
import { STATE_NAME_TO_CODE } from '../location/Location';
import { UserSettingsService } from '../settings/user-settings.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { LocalDatabaseService } from '../storage/local-database.service';
import { removeFalsyElementsFromArrayInPlace, trimStringValuesAndRemoveNonASCIICharsInObj } from '../utility';
import { resolve } from 'dns';

/**
 * Default page size (number of form GROUPS) when fetching forms
 */
export const DEFAULT_FORMS_API_PAGE_SIZE = 25;

/**
 * Mapping from the FE user type to the API path segment
 */
export const USER_TYPE_TO_PATH_SEGMENT: { [u in UserType]: string } = {
  'fleet': 'fleet',
  'line': 'line',
  'non-craft': 'short',
  'transmission': 'transmission'
};

const localLinePreJobTemplates = require('../form-templates/line/pre_job_template.json');
const localLinePostJobTemplates = require('../form-templates/line/post_job_template.json');
const localFleetPreJobTemplates = require('../form-templates/fleet/fleet_pre_job_template.json');
const localFleetPostJobTemplates = require('../form-templates/fleet/fleet_post_job_template.json');
const localNonCraftPreJobTemplates = require('../form-templates/non-craft/short_pre_job_template.json');
const localNonCraftPostJobTemplates = require('../form-templates/non-craft/short_post_job_template.json');
const localTransmissionPreJobTemplates = require('../form-templates/transmission/transmission_pre_job_template.json');
const localTransmissionPostJobTemplates = require('../form-templates/transmission/transmission_post_job_template.json');


export interface DeFormGetQueryParams {
  /**
   * opCenter for LINE and TRANSMISSION, fleetGarage for FLEET
   */
  location?: string,
  department_center?: string,
  searchValue?: string,
  startDate?: string,
  endDate?: string
  /**
   * Max number of form GROUPS to fetch
   */
  limit?: string;
  /**
   * Index to start from when fetching form GROUPS
   */
  startIndex?: string;
}

export interface AWSPresignedUrl {
  url: string;
  fields?: AWSPresignedUploadFormData;
}

export interface AWSPresignedUploadFormData {
  AWSAccessKeyId: string;
  key: string;
  policy: string;
  signature: string;
  'x-amz-security-token': string;
}

export interface CDApiResponse<T> {
  data: T;
  error: boolean;
  errorMessages: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _apiEndpoint = this.config.getConfig('apiBaseUrl');

  /**
   * Emits a list of form_uuids that were resubmitted successfully
   * when background resubmission occurs
   */
  formsResubmittedSuccessfully$ = new Subject<string[]>();

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private snackbar: SnackbarService,
    private dynatrace: DynatraceService,
    private db: LocalDatabaseService,
    private userSettings: UserSettingsService
  ) { }

  /**
   * Fetches all forms from the backend matching the provided parameters
   *
   * @param forCurrentUser Only fetch forms for the current user (based on their Azure token)
   * @param searchParams Query params for searching
   * @param deleteFormsNotInBackendResponse Local forms that should be deleted if they are not in the backend response
   */
  getForms(forCurrentUser: boolean, searchParams: DeFormGetQueryParams, deleteFormsNotInBackendResponse: DeForm[], group_uuid: string): Promise<DeTemplateFormMetadata[]> {
    // Remove any unused search params from object
    // Current backend assumption will be:
    //  - Search params => look across all users
    //  - No search params => look only at current user's forms (based on email in Azure)
    Object.keys(searchParams).forEach(k => {
      if (!(searchParams[k]?.length > 0) || (k !== 'startIndex' && searchParams[k] === '0')) {
        delete searchParams[k];
      }
    });
    // Remove 'location' key so that we can send as 'opCenter' or 'fleetGarage' to the backend
    const locationParam = searchParams?.location;
    delete searchParams?.location;

    return this.userSettings.userType.pipe(
      mergeMap(userType => {
        /**
         * Path params that include the group uuid for fetching form metadata by group,
         * or (for FLEET/NON-CRAFT/TRANSMISSION) the /mine or /search params if fetching the current user's
         * forms or searching for forms
         *
         * Currently, just fleet/non-craft/transmission will require a /group/ path param before the group uuid.
         */
        // $_FORM_TYPE_ADJUSTMENT_$ -> Path params for API calls
        const pathParams =
          (userType === 'fleet' || userType === 'non-craft' || userType === 'transmission')
            ? `/metadata${group_uuid?.length > 0 ? `/group/${group_uuid}` : forCurrentUser ? '/mine' : '/search'}`
            : userType === 'line'
              ? `${group_uuid?.length > 0 ? `/metadata/${group_uuid}` : ''}`
              : '';

        return this._requestFormsFromAPI(forCurrentUser, searchParams, userType, pathParams, locationParam)
          .pipe(
            map(response => {
              // $_FORM_TYPE_ADJUSTMENT_$ -> Cast response to correct type
              const formMetadata: DeTemplateFormMetadata[] =
                userType === 'fleet'
                  ? (response?.data as FleetFormMetadata[])
                  : userType === 'line'
                    ? (response?.data as LineFormMetadata[])
                    : userType === 'non-craft'
                      ? (response?.data as NonCraftFormMetadata[])
                      : userType === 'transmission'
                        ? (response?.data as TransmissionFormMetadata[])
                        : response?.data as DeTemplateFormMetadata[];
              // Update user type of each form to match the path param (since this is not stored on the backend for each form)
              formMetadata?.forEach?.(f => f.user_type = userType);
              if (
                // We are fetching forms on the 'My Forms' page
                forCurrentUser
                // We are online
                && navigator.onLine
                // We received a valid response
                && Array.isArray(formMetadata)
                // The list of local forms is greater than 0
                && deleteFormsNotInBackendResponse?.length > 0) {
                const formsToRemove = deleteFormsNotInBackendResponse.filter((localForm, i) => {
                  // Form was removed on the backend if the local form has a completed timestamp (and was actually submitted while online)
                  // and if the backend response doesn't include the form
                  const removedFromBackend = localForm.form_metadata?.completed_timestamp
                    && !localForm.form_metadata?.requiresBackendSubmission
                    && !formMetadata.some(f => f.form_uuid === localForm.form_metadata.form_uuid);
                  deleteFormsNotInBackendResponse[i] = removedFromBackend ? null : deleteFormsNotInBackendResponse[i];
                  return removedFromBackend;
                });
                if (formsToRemove.length > 0) {
                  console.log('Deleting the following submitted forms that exist only on the frontend:');
                  console.log(formsToRemove);
                  Promise.all(formsToRemove.map(f => this.db.deleteForm(f.form_metadata.form_uuid).catch(e => console.error(e))))
                    .then(() => console.log('Successfully removed forms from IndexedDB'))
                    .catch(e => console.error(e));
                  // Remove in-place from the source array of local forms
                  removeFalsyElementsFromArrayInPlace<DeForm>(deleteFormsNotInBackendResponse);
                  // Only return forms that weren't removed
                  return formMetadata.filter(backendForm => !formsToRemove.some(f => f.form_metadata.form_uuid === backendForm.form_uuid));
                }
              }
              return formMetadata;
            }),
            catchError(e => {
              const errorCode = e instanceof HttpErrorResponse ? ` (${e.status}: ${e.message})` : '';
              this.dynatrace.reportError(`Error fetching forms${errorCode}.`);
              // Throw the actual error if we're online and searching,
              // otherwise just return an empty array
              return navigator.onLine && !forCurrentUser && !group_uuid
                ? throwError(e)
                : of([]);
            })
          );
      }
      )).toPromise();
  }

  /**
   * Fetches forms from the backend, recursively fetching addition pages if on the My Forms page
   *
   * @param forCurrentUser Only fetch forms for the current user (based on their Azure token)
   * @param searchParams Query params for searching
   * @param userType User Type (fleet, line, etc.)
   * @param pathParams Path param string
   * @param locationParam Op center or fleet garage
   */
  private _requestFormsFromAPI(forCurrentUser: boolean, searchParams: DeFormGetQueryParams, userType: UserType, pathParams: string, locationParam: string): Observable<CDApiResponse<LineFormMetadata[] | FleetFormMetadata[]>> {
    return this.http.get<CDApiResponse<LineFormMetadata[] | FleetFormMetadata[]>>(
      `${this._apiEndpoint}/${USER_TYPE_TO_PATH_SEGMENT[userType]}/form${pathParams}`,
      {
        params: {
          ...searchParams,
          ...(
            // $_FORM_TYPE_ADJUSTMENT_$ -> Cast response to correct type ->
            // ?me=true query param is now only needed for line forms
            // in order to specify that we are fetching just the current
            // user's forms
            forCurrentUser && userType === 'line'
              ? { me: 'true' }
              : {}
          ),
          ...(
            // $_FORM_TYPE_ADJUSTMENT_$ -> Location param will be different depending on the form type
            // (region for non-craft, fleetGarage for fleet, opCenter for line and transmission)
            locationParam?.length > 0
              ? userType === 'fleet'
                ? { fleetGarage: locationParam }
                : userType === 'line'
                  ? { opCenter: locationParam }
                  : userType === 'non-craft'
                    ? { region: locationParam }
                    : userType === 'transmission'
                      ? { opCenter: locationParam }
                      : {}
              : {}
          )
        }
      }
    )
    // Uncomment the follow to enable fetching forms on My Forms page by page
    // .pipe(
    //   mergeMap(response =>
    //     // My forms page requires ALL forms for the current user, so fetch recursively until we have no more forms to fetch
    //     forCurrentUser
    //       && parseInt(searchParams?.limit, 10) > 0
    //       // Current page has hit the limit if the number of form groups in the response equals the limit
    //       && new Set(response?.data?.map(f => f.form_group_uuid))?.size === parseInt(searchParams.limit, 10)
    //       ? this._requestFormsFromAPI(
    //         forCurrentUser,
    //         { ...searchParams, startIndex: `${parseInt(searchParams.startIndex ?? '0', 10) + parseInt(searchParams.limit, 10)}` },
    //         userType,
    //         pathParams,
    //         locationParam
    //       ).pipe(
    //         map(additionalResponses => ({
    //           ...response,
    //           data: [...response.data, ...additionalResponses.data] as LineFormMetadata[] | DeTemplateFormFleetMetadataAPIModel[]
    //         }))
    //       )
    //       : of(response)
    //   )
    // );
  }

  getFormValue(formMetadata: DeTemplateFormMetadata): Promise<any> {
    return this.userSettings.userType.pipe(
      mergeMap(userType =>
        // Receive presigned url and download form json
        this.http.get<CDApiResponse<AWSPresignedUrl>>(`${this._apiEndpoint}/${USER_TYPE_TO_PATH_SEGMENT[userType]}/form/json/${formMetadata.form_uuid}`)
          .pipe(
            mergeMap(response => this.http.get<any>(response?.data?.url).pipe(
              catchError(e => {
                const errorCode = e instanceof HttpErrorResponse ? ` (${e.status}: ${e.message})` : '';
                this.dynatrace.reportError(`Error downloading form ${formMetadata.form_uuid} from presigned url${errorCode}.`);
                return of(undefined);
              })
            )),
            catchError(e => {
              const errorCode = e instanceof HttpErrorResponse ? ` (${e.status}: ${e.message})` : '';
              this.dynatrace.reportError(`Error getting presigned url to download form ${formMetadata.form_uuid}${errorCode}.`);
              return of(undefined);
            })
          )
      )).toPromise();
  }

  /**
   * Posts form metadata and uploads the value to S3
   *
   * @param form Form
   * @param showSnackbar (Optional) Whether or not to show the snackbar (default is true)
   * @param updateOnly (Optional) Indicates that the form is just being updated (default is false)
   */
  postFormAndUploadToS3(form: DeForm, showSnackbar = true, updateOnly = false): Promise<boolean> {
    // Ensure form template version is properly set
    form.form_metadata.template_version = form.form_metadata.template_version ?? form.form_template?.version;

    if (navigator.onLine) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const startTime = Date.now();

      return this.userSettings.userType.pipe(
        mergeMap(userType =>
          this.http.post<CDApiResponse<AWSPresignedUrl>>(
            `${this._apiEndpoint}/${USER_TYPE_TO_PATH_SEGMENT[userType]}/form?updateOnly=${updateOnly}`,
            {
              ...trimStringValuesAndRemoveNonASCIICharsInObj(form.form_metadata),
              requiresBackendSubmission: undefined,
              stopped: form.form_metadata.form_stopped,
              stopped_timstamp: form.form_metadata.form_stopped ? (form.form_metadata.stopped_timstamp ?? currentTime) : undefined,
              completed_timestamp: form.form_metadata.completed_timestamp ?? currentTime
            } as DeTemplateFormMetadata)
            .pipe(
              mergeMap(response => {

                if (response?.data?.url && response?.data?.fields) {
                  return this._postFormToPresignedUrl(form, response.data.url, response.data.fields)
                    .pipe(
                      // Timeoout the post request after 8secs(8000)
                      timeout(8000),
                      mergeMap(result => {
                        return from(new Promise<boolean>(async resolve => {
                          // Form no longer requires backend submission
                          form.form_metadata.requiresBackendSubmission = false;
                          // ...so we can also set a completed_timestamp and stopped_timstamp.
                          form.form_metadata.completed_timestamp = form.form_metadata.completed_timestamp ?? currentTime;
                          form.form_metadata.stopped_timstamp = form.form_metadata.form_stopped ? (form.form_metadata.stopped_timstamp ?? currentTime) : undefined;

                          // $_FORM_TYPE_ADJUSTMENT_$ -> User location preference being saved will depend on user type

                          // Update user's preferred op center if this is a pre job brief,
                          // the op center is filled out,
                          // and the op center is not a Training/PAQ op center
                          if (form.form_metadata?.form_type === 'pre_job_brief'
                            && line(form.form_metadata)?.op_center?.length > 0
                            && !/training|paq/i.test(line(form.form_metadata).op_center)) {
                            await this.userSettings.setOpCenter(line(form.form_metadata).op_center);
                          }
                          // Update user's preferred garage if this is a pre job brief
                          // AND the garage is filled out
                          if (form.form_metadata?.form_type === 'pre_job_brief'
                            && fleet(form.form_metadata)?.fleet_garage?.length > 0) {
                            await this.userSettings.setGarage(fleet(form.form_metadata).fleet_garage);
                          }
                          // Update user's preferred region if this is a pre job brief
                          // AND region is filled out
                          if (form.form_metadata?.form_type === 'pre_job_brief'
                            && nonCraft(form.form_metadata)?.region?.length > 0) {
                            await this.userSettings.setRegion(nonCraft(form.form_metadata).region);
                          }

                          if (form.form_metadata?.form_type === 'pre_job_brief' && userType === 'transmission') {
                            await this.userSettings.updateUserSettings({

                              // Update user's preferred transmission op center if this is a pre job brief
                              // AND op center is filled out
                              operation_center: transmission(form.form_metadata).op_center,

                              // Update user's preferred transmission department if this is a pre job brief
                              // AND department is filled out
                              department_center: transmission(form.form_metadata).department_center,

                              // Updates user's preferred transmission supervisor if the this is a pre job brief
                              // AND supervisor is filled out
                              supervisor: transmission(form.form_metadata).supervisor,

                              // Updates user's preferred transmission EIC if the this is a pre job brief
                              // AND EIC is filled out
                              eic: transmission(form.form_metadata).eic,

                              // Updates user's preferred transmission Job Type if the this is a pre job brief
                              // AND Job Type is filled out
                              job_type: transmission(form.form_metadata).job_type,

                              // Updates user's preferred transmission AED/First Aid/Burn Kit/Fire Ext. Location if the this is a pre job brief
                              // AND AED/First Aid/Burn Kit/Fire Ext. Location is filled out
                              aed_location: transmission(form.form_metadata).aed_location
                            });
                          }

                          // $_FORM_TYPE_ADJUSTMENT_$ -> Recent addresses are saved for fleet
                          if (form.form_metadata?.user_type === 'fleet'
                            && form.form_metadata?.form_type === 'pre_job_brief'
                            && fleet(form.form_metadata)?.traveling_required
                            && form.form_metadata?.location_address?.length > 0) {
                            await this.userSettings.addToRecentAddresses(
                              `${form.form_metadata.location_address.trim()}, ${form.form_metadata.location_city}, ${STATE_NAME_TO_CODE[form.form_metadata.location_state]}, ${form.form_metadata.location_zip}, USA`
                            );
                          }

                          if (showSnackbar) {
                            this.snackbar.openSnackbar(form.form_metadata.form_stopped ? 'All Stop submitted!' : 'Submitted form!', 'success');
                          }
                          this.dynatrace.logAction('Form submitted successfully!');

                          resolve(result);
                        }));
                      }),
                      catchError(e => {
                        // Form submit time took more than 8secs and will be cancelled.
                        form.form_metadata.requiresBackendSubmission = true;
                        if (e instanceof TimeoutError) {
                          this.snackbar.openSnackbar('Network unstable. Form will be submitted when network is stable again', 'warning');
                          this.dynatrace.reportError("Network unstable. Form will be submitted when network is stable again");
                        }
                        else if (showSnackbar) {
                          this.snackbar.openSnackbar('Error uploading form.', 'warning');
                        }
                        const errorCode = e instanceof HttpErrorResponse ? ` (${e.status}: ${e.message})` : '';
                        this.dynatrace.reportError(`Error uploading form value ${form.form_metadata?.form_uuid} to presigned URL${errorCode}.`);
                        return of(false);
                      })
                    );
                } else {
                  form.form_metadata.requiresBackendSubmission = true;
                  if (showSnackbar) {
                    this.snackbar.openSnackbar('Error uploading form.', 'warning');
                  }
                  console.error(`Error uploading form value ${form.form_metadata?.form_uuid}, bad presigned URL from form POST.`);
                  this.dynatrace.reportError(`Error uploading form value ${form.form_metadata?.form_uuid}, bad presigned URL from form POST.`);
                  return of(false);
                }
              }),
              catchError(e => {
                form.form_metadata.requiresBackendSubmission = true;
                if (showSnackbar) {
                  this.snackbar.openSnackbar('Error submitting form, please try again.', 'warning');
                }
                const errorCode = e instanceof HttpErrorResponse ? ` (${e.status}: ${e.message})` : '';
                this.dynatrace.reportError(`Error submitting form metadata for ${form.form_metadata?.form_uuid}${errorCode}.`);
                return of(false);
              })
            )
        )).toPromise();
    } else {
      alert('You\'re currently offline. Your job brief will be submitted when a network connection is found. No further action is needed.');
      form.form_metadata.requiresBackendSubmission = true;
      // Simulate successful submission.
      // The requiresBackendSubmission flag will trigger a resubmission when the app is online again.
      return of(true).toPromise();
    }
  }

  /**
   * Attempts to resubmit any forms that were submitted on the ui but not to the backend.
   *
   * This is currently called when the user goes from offline -> online
   * and when the user navigates to the 'My Forms' page.
   *
   * This form state is indicated by the requiresBackendSubmission metadata flag.
   */
  async submitAnyPendingForms() {
    if (navigator.onLine) {
      const userType = await this.userSettings.userType.toPromise();
      // Fetch local forms with the requiresBackendSubmission metadata flag
      const forms = (await this.db.getAllForms(userType).catch(_ => [] as DeForm[]))
        .filter(f => f.form_metadata.requiresBackendSubmission);

      if (forms.length > 0) {
        console.log('Found unsubmitted forms, attempting to re-submit...');
        const result = await Promise.all(forms.map(f => this.postFormAndUploadToS3(f, false))).catch(e => []);
        const success = result?.filter(f => f)?.length ?? 0;
        if (success > 0) {
          this.snackbar.openSnackbar(`${success} job brief${success > 1 ? 's' : ''} that ${success > 1 ? 'were' : 'was'} pending ${success > 1 ? 'have' : 'has'} been submitted.`, 'success');
          await Promise.all(forms.map(f => this.db.updateForm(f))).catch(e => console.error('Error saving resubmitted form(s) locally.'));
          this.formsResubmittedSuccessfully$.next(forms.filter(f => !f.form_metadata.requiresBackendSubmission).map(f => f.form_metadata.form_uuid));
        }
      } else {
        console.log('No unsubmitted forms were found locally.');
      }
    }
  }

  private _postFormToPresignedUrl(form: DeForm, presignedUrl: string, presignedFormData: AWSPresignedUploadFormData) {
    // Create form data object to be sent in S3 upload
    const formData = new FormData();
    // Add presigned information to form data
    Object.keys(presignedFormData).forEach(k => formData.append(k, presignedFormData[k]));

    // Convert form value into a file blob
    const jsonStr = JSON.stringify(form.form_value);
    const bytes = new TextEncoder().encode(jsonStr);
    const formBlob = new Blob([bytes], { type: "application/json;charset=utf-8" });

    // Add form value file to form data
    formData.append('file', formBlob, 'form_value.json');

    return this.http.post<any>(presignedUrl, formData).pipe(
      map(_ => true),
      catchError(_ => of(false))
    );
  }

  getFormTemplate(form: DeForm): Promise<DeTemplateForm> {
    const templateName = form.form_metadata.template_name;
    const templateVersion = form.form_metadata.template_version;

    // Backend should return the matching template if a version is supplied, or the most recent template if no version is provided
    // Receive presigned url to download form template json
    const templateFilename = templateName === 'pre_job_brief' ? 'pre_job_template.json' : 'post_job_template.json';
    return this.userSettings.userType.pipe(
      mergeMap(userType =>
        this.http.get<CDApiResponse<AWSPresignedUrl>>(
          `${this._apiEndpoint}/${USER_TYPE_TO_PATH_SEGMENT[userType]}/template`,
          { params: { name: templateFilename, version: templateVersion ?? 'latest' } }
        ).pipe(
          mergeMap(
            presignedUrlResponse => this.http.get<[DeTemplateForm]>(presignedUrlResponse?.data?.url).pipe(
              map(templates => templates?.[0]),
              catchError(_ => of(undefined))
            )
          ),
          catchError(_ => of(undefined))
        )
      )).toPromise();
  }

  getUICopyOfFormTemplate(form: DeForm) {
    const templateName = form.form_metadata.template_name;
    const templateVersion = form.form_metadata.template_version;

    return this.userSettings.userType.pipe(
      map(
        // $_FORM_TYPE_ADJUSTMENT_$ -> Form templates depend on user type
        (userType: UserType) => (JSON.parse(JSON.stringify(
          templateName === 'pre_job_brief'
            ? (
              userType === 'line'
                ? localLinePreJobTemplates
                : userType === 'fleet'
                  ? localFleetPreJobTemplates
                  : userType === 'non-craft'
                    ? localNonCraftPreJobTemplates
                    : userType === 'transmission'
                      ? localTransmissionPreJobTemplates
                      : []
            )
            : (
              userType === 'line'
                ? localLinePostJobTemplates
                : userType === 'fleet'
                  ? localFleetPostJobTemplates
                  : userType === 'non-craft'
                    ? localNonCraftPostJobTemplates
                    : userType === 'transmission'
                      ? localTransmissionPostJobTemplates
                      : []
            )
        )) as DeTemplateForm[])
          .find(
            (t, i, arr) =>
              // Template version matches form template version
              t.version === templateVersion
              // No form template version and we're at the last template
              || (!(templateVersion > -1) && i === arr.length - 1)
          )
      )
    ).toPromise();
  }
}
