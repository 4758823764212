<label for="{{id}}_{{extended ? 'textarea' : 'input'}}" [class.focused]="_focused" [class.hasValue]="_hasValue"
  [class.isTimeInput]="isTimeInput">{{label}}</label>
<input #inputElement *ngIf="!extended" id="{{id}}_input" name="{{id}}_input" [disabled]="readOnly || _disabled"
  [ngModel]="_value" (ngModelChange)="valueChange($event)" [placeholder]="_focused ? '' : placeholder"
  [autocomplete]="autocomplete" [class.hasValue]="_hasValue" [class.isTimeInput]="isTimeInput"
  [class.showUnderline]="showUnderline" (focus)="focus()" (blur)="blurInput($event)" [required]="required"
  [class.error]="hasError" [maxlength]="character_limit" [type]="type" [min]="minVal" [max]="maxVal">
<textarea #inputElement *ngIf="extended" id="{{id}}_textarea" name="{{id}}_textarea" [disabled]="readOnly || _disabled"
  [ngModel]="_value" (ngModelChange)="valueChange($event)" [placeholder]="_focused ? '' : placeholder"
  [autocomplete]="autocomplete" [class.hasValue]="_hasValue" [class.isTimeInput]="isTimeInput"
  [class.showUnderline]="showUnderline" (focus)="focus()" (blur)="blurInput($event)" [required]="required"
  [class.error]="hasError" [maxlength]="character_limit"></textarea>
<span *ngIf="character_limit > 0 && !hideCharacterCount">{{_value?.length || 0}}/{{character_limit}}</span>
<a *ngIf="link?.length > 0" href="{{link}}" target="_blank"
  class="{{linkVerticalPosition}} {{linkHorizontalPosition}}">{{linkText}}</a>

<!-- List of items shown beneath the text input, used for features like autopopulating addresses -->
<div *ngIf="showResultsDropdown" [class.selecting]="_focused" class="styled-select" [attr.aria-hidden]="!_focused" (blur)="blur()">
  <ul #resultsList>
    <!-- Results header -->
    <li *ngIf="results.length > 0 && resultsHeader?.length > 0" class="results-header" tabindex="-1">
      <button [attr.aria-label]="resultsHeader" disabled type="button">
        {{resultsHeader}}
      </button>
    </li>
    <!-- Actual list of results -->
    <li *ngFor="let result of results; let i = index" tabindex="-1">
      <button (click)="selectResult(result)" (blur)="blur()" [attr.aria-label]="result"
        [disabled]="readOnly || _disabled" type="button">
        {{result}}
      </button>
    </li>
    <!-- No result message -->
    <li *ngIf="results.length === 0" tabindex="-1">
      <button [attr.aria-label]="noResultMessage" disabled type="button">
        {{noResultMessage}}
      </button>
    </li>
  </ul>
</div>