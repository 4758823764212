import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeTemplateFormsModule } from 'projects/de-template-forms/src/public-api';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { RouterModule } from '@angular/router';
import { SharedModule } from './modules/shared/shared.module';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './config/msal.config';
import { OverlayModule } from '@angular/cdk/overlay';
import { DeSnackbarModule } from '@de-electron/electron-angular-components';
import { DtrumApi } from './services/dynatrace/Dynatrace';

declare global {
  interface Window { dtrum: DtrumApi; dT_: any; }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    OverlayModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DeTemplateFormsModule,
    SharedModule,
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RouterModule,
    DeSnackbarModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OfflineInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => {
    config.load();
  };
}