import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

export interface CDOverlayConfig {
  data?: any;
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  centerHorizontally?: boolean;
  centerVertically?: boolean;
  disableClosing?: boolean;
}

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 *
 * See referenced article at https://johnbwoodruff.com/posts/angular-cdk-dialog/
 */
export class CDOverlayRef {

  private _afterClosedSubject = new Subject<any>();

  constructor(private overlayRef: OverlayRef) { }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  public close(result?: any) {
    this.overlayRef.dispose();
    this._afterClosedSubject.next(result);
    this._afterClosedSubject.complete();
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<any> {
    return this._afterClosedSubject.asObservable();
  }
}

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(private overlay: Overlay, private injector: Injector) { }

  /**
   * Open a custom component in an overlay
   */
  open<T>(component: ComponentType<T>, config?: CDOverlayConfig & OverlayConfig): CDOverlayRef {
    const positionStrategy = config.centerHorizontally
      ? config.centerVertically
        ? this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically()
        : this.overlay
          .position()
          .global()
          .centerHorizontally()
      : config.centerVertically
        ? this.overlay
          .position()
          .global()
          .centerVertically()
        : this.overlay
          .position()
          .global();

    if (config?.left) {
      positionStrategy.left();
    }
    if (config?.top) {
      positionStrategy.top();
    }
    if (config?.right) {
      positionStrategy.right();
    }
    if (config?.bottom) {
      positionStrategy.bottom();
    }

    // Create the overlay with customizable options
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      ...config
    });

    // Close on backdrop click
    if (!config?.disableClosing) {
      overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
    }

    // Create overlay ref to return
    const cdOverlayRef = new CDOverlayRef(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: CDOverlayRef, useValue: cdOverlayRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    return cdOverlayRef;
  }
}
