/**
 * Converts a date object to a string in the form
 * 'YYYY-MM-DD'
 *
 * @param d Date object
 */
export const dateToYYYYMMDDWithHyphens = (d: Date) => {
  if (isNaN(d?.getDate())) {
    return '';
  }
  /**
   * 'MM/DD/YYYY, H:MM:TT AM'
   */
  const localStrParts = d.toLocaleString().split(',')[0].split('/').map(s => s.length > 1 ? s : `0${s}`);
  return `${localStrParts[2]}-${localStrParts[0]}-${localStrParts[1]}`;
}

export const metersToMiles = (meters: number) => isNaN(meters) ? 0 : meters / 1609.344;

export const valueIsEmptyArray = value => Array.isArray(value) && value?.length === 0;

/**
 * Returns a deep copy of an object by stringifying and then parsing the object
 *
 * @param obj Object
 */
export const deepCopyObject = (obj: any) => obj ? JSON.parse(JSON.stringify(obj)) : undefined;

export const nDaysAgoInMilliseconds = (n: number) => Date.now() - (n * 24 * 60 * 60 * 1000);

/**
 * Removes any false, null, undefined, empty, etc. values from an array, in place
 *
 * @param arr Array
 */
export const removeFalsyElementsFromArrayInPlace = <T>(arr: T[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (!arr[i]) {
      arr.splice(i, 1);
      i--;
    }
  }
};

/**
 * Checks for equality between two objects regardless of key names.
 *
 * For example, the following three objects would considered be equal:
 *
 * { a: 1, b: 2 }, { a: 1, b: 2 }, and { c: 1, d: 2 }
 *
 * ...but the following two object would NOT be considered equal:
 *
 * { a: 1, b: 2 } and { a: 2, b: 1 }
 *
 * Returns false if either object is undefined/not an object or if the
 * objects have a different number of keys.
 *
 * @param obj1 Obj 1
 * @param obj2 Obj 2
 */
export const objectValuesAreEqualAcrossKeys = (obj1, obj2) => {
  if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  return obj1Keys.length === obj2Keys.length && !Object.values(obj1).some((v, i) => v !== obj2[obj2Keys[i]]);
};

/**
 * Given a list of objects, returns a string representation
 * of the list of first values of each object
 *
 * @param list List of objects
 */
export const listOfObjectsToStringWithValues = (list: any[], maxLength?: number) =>
  list?.length > 0
    ? list.map(o => Object.values(o)[0]).join(', ').slice(0, maxLength)
    : undefined;

/**
 * Converts a date object to a string in the format 'mm/dd/yy, HH:MM'
 * @param date Date object
 */
export const dateToFormDetailsFormat = (date: Date) =>
  !isNaN(date.getDate())
    ? `${date.getMonth() + 1}/${date.getDate()}/${`${date.getFullYear()}`.slice(2, 4)}, ${padWithZero(date.getHours())}:${padWithZero(date.getMinutes())}`
    : undefined;

/**
 * Adds a `0` to the front of a number if it is less than two digits
 * Returns `00` if the value provided is undefined or an empty string
 * @param val Value to pad with zero
 */
export const padWithZero = (val: number | string) => {
  const originalValue = `${val || ''}`;
  return originalValue.length > 1
    ? originalValue
    : originalValue.length > 0
      ? `0${originalValue}`
      : '00';
}

/**
 * Sanitizes a string by removing JSON characters that would otherwise
 * need to be escaped. This is primarily to avoid a MySQL issue with escaped characters.
 *
 * @param str String to sanitize
 */
export const removeJSONCharsFromString = (str: string) => str?.replace(/\\./g, '');

/**
 * Trims all string values in an object and also removes non-ASCII (256) characters
 *
 * @param obj Object to trim
 */
export const trimStringValuesAndRemoveNonASCIICharsInObj = (obj: any) => {
  Object.keys(obj ?? {}).forEach(k => {
    switch (typeof obj[k]) {
      case 'string':
        obj[k] = removeJSONCharsFromString(obj[k].replace(/[^\x00-\xFF]/g, '').trim());
        break;
      case 'object':
        trimStringValuesAndRemoveNonASCIICharsInObj(obj[k]);
        break;
    }
  });
  return obj;
}

export const userAgentStr = navigator.userAgent ?? navigator.vendor;

// Extensive mobile user agent detection from http://detectmobilebrowsers.com/
export const isMobileUserAgent =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgentStr)
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgentStr.substring(0, 4));

export const isMobileSafariUserAgent = isMobileUserAgent && /iPhone|iPad/i.test(userAgentStr);

export const isStandalonePWA = (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;