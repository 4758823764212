import { DeForm, DeTemplateFormMetadata, DeTemplateFormType, FleetFormMetadata, LineFormMetadata, NonCraftFormMetadata, TransmissionFormMetadata } from "projects/de-template-forms/src/lib/models/DeTemplateForms";

export type UserType = DeTemplateFormType;
// $_FORM_TYPE_ADJUSTMENT_$ -> List of all user types
export const USER_TYPES: UserType[] = ['fleet', 'line', 'non-craft', 'transmission'];
// $_FORM_TYPE_ADJUSTMENT_$ -> User type options in selection popup
export const USER_TYPE_SELECTION_OPTIONS: { [s in string]: UserType } = {
  'Distribution Line': 'line',
  'Fleet': 'fleet',
  'Non-Craft': 'non-craft',
  'Transmission': 'transmission'
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Initial section names
export const LINE_PRE_JOB_BRIEF_INITIAL_SECTION_NAME = 'job_info_and_description';
export const FLEET_PRE_JOB_BRIEF_INITIAL_SECTION_NAME = 'job_information_and_scope';
export const NON_CRAFT_PRE_JOB_BRIEF_INITIAL_SECTION_NAME = 'job_info_and_description';
export const TRANSMISSION_PRE_JOB_BRIEF_INITIAL_SECTION_NAME = 'emergency_info';

export const ALL_STOP_SECTION_NAME = 'all_stop';

export const MAD_TABLE = {
  '50-300 V': {
    phase_to_ground: 'Avoid contact',
    phase_to_phase: 'Avoid contact'
  },
  '301-750 V': {
    phase_to_ground: '1 ft 1 in',
    phase_to_phase: '1 ft 1 in'
  },
  '751.0V-5.0 kV': {
    phase_to_ground: '2 ft 1 in',
    phase_to_phase: '2 ft 1 in'
  },
  '5.1-15.0 kV': {
    phase_to_ground: '2 ft 2 in',
    phase_to_phase: '2 ft 3 in'
  },
  '15.1-36.0 kV': {
    phase_to_ground: '2 ft 7 in',
    phase_to_phase: '2 ft 11 in'
  },
  '36.1-46.0 kV': {
    phase_to_ground: '2 ft 10 in',
    phase_to_phase: '3 ft 3 in'
  },
  '46.1-72.5 kV': {
    phase_to_ground: '3 ft 4 in',
    phase_to_phase: '4 ft 0 in'
  },
  '72.6-121.0 kV': {
    phase_to_ground: '3 ft 9 in',
    phase_to_phase: '4 ft 8 in'
  },
  '121.1-145.0 kV': {
    phase_to_ground: '3 ft 10 in',
    phase_to_phase: '4 ft 10 in'
  },
  '145.1-169.0 kV': {
    phase_to_ground: '4 ft 4 in',
    phase_to_phase: '5 ft 5 in'
  },
  '169.1-242.0 kV': {
    phase_to_ground: '5 ft 8 in',
    phase_to_phase: '8 ft 5 in'
  },
  '242.1-420.0 kV': {
    phase_to_ground: '9 ft 2 in',
    phase_to_phase: '14 ft 9 in'
  },
  '420.1-550.0 kV': {
    phase_to_ground: '11 ft 11 in',
    phase_to_phase: '20 ft 4 in'
  },
  '550.1-800.0 kV': {
    phase_to_ground: '15 ft 10 in',
    phase_to_phase: '27 ft 10 in'
  },
};

export const HYDRATION_CHART = {
  80: {
    work_level: 'Normal',
    max_mins_worked_between_hydration_breaks: null,
    hydration_target: '8-12 oz. / hr'
  },
  86: {
    work_level: 'Normal',
    max_mins_worked_between_hydration_breaks: null,
    hydration_target: '8-16 oz. / hr'
  },
  91: {
    work_level: 'Normal',
    max_mins_worked_between_hydration_breaks: '50 mins',
    hydration_target: '12-20 oz. / hr'
  },
  96: {
    work_level: 'Normal',
    max_mins_worked_between_hydration_breaks: '45 mins',
    hydration_target: '16-24 oz. / hr'
  },
  151: {
    work_level: 'Normal',
    max_mins_worked_between_hydration_breaks: '40 mins',
    hydration_target: '24-32 oz. / hr'
  },
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Common, shared metadata
export const GENERIC_EMPTY_FORM_METADATA: DeTemplateFormMetadata = {
  template_name: undefined,
  template_version: undefined,
  form_type: undefined,
  user_type: undefined,
  display_name: undefined,
  form_id: undefined,
  form_uuid: undefined,
  form_group_uuid: undefined,
  form_stopped: false,
  all_stop_reason: undefined,
  formWasCompletedBeforeAllStop: undefined,
  start_timestamp: undefined,
  completed_timestamp: undefined,
  stopped_timstamp: undefined,
  submitter_name: undefined,
  submitter_email: undefined,
  work_number: undefined,
  location_address: undefined,
  location_city: undefined,
  location_state: undefined,
  location_zip: undefined,
  location_google_url: undefined,
  nearest_medical_facility_name: undefined,
  nearest_medical_facility_address: undefined,
  nearest_medical_facility_city: undefined,
  nearest_medical_facility_state: undefined,
  nearest_medical_facility_zip: undefined,
  nearest_medical_facility_google_url: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended metadata for line
export const LINE_EMPTY_FORM_METADATA: LineFormMetadata = {
  ...GENERIC_EMPTY_FORM_METADATA,
  user_type: 'line',
  eic: undefined,
  op_center: undefined,
  job_type: undefined,
  circuit_or_feeder: undefined,
  clearance_number: undefined,
  protective_device: undefined,
  hotline_tag_number: undefined,
  energized_vs_de_energized_guide_consulted: undefined,
  required_permits_in_place: undefined,
  permits_required_for_job: undefined,
  permit_numbers: undefined,
  flash_and_contact_hazards_identified: undefined,
  flash_and_contact_hazards: undefined,
  grounding_hazards_identified: undefined,
  specific_hazard_for_the_job: undefined,
  mitigation_or_barrier_for_the_hazard: undefined,
  person_responsible_for_mitigation: undefined,
  other_person_responsible_for_mitigation: undefined,
  will_any_critical_steps_require_three_way_communication: undefined,
  critical_steps: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended metadata for fleet
export const FLEET_EMPTY_FORM_METADATA: FleetFormMetadata = {
  ...GENERIC_EMPTY_FORM_METADATA,
  user_type: 'fleet',
  asset_number: undefined,
  fleet_garage: undefined,
  briefing_leader_name: undefined,
  emergency_contact_name: undefined,
  traveling_required: undefined,
  high_risk_tools_needed: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended metadata for non-craft
export const NON_CRAFT_EMPTY_FORM_METADATA: NonCraftFormMetadata = {
  ...GENERIC_EMPTY_FORM_METADATA,
  user_type: 'non-craft',
  eic: undefined,
  region: undefined,
  job_type: undefined,
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended metadata for Transmission
export const TRANSMISSION_EMPTY_FORM_METADATA: TransmissionFormMetadata = {
  ...GENERIC_EMPTY_FORM_METADATA,
  user_type: 'transmission',
  eic: undefined,
  op_center: undefined,
  department_center: undefined,
  job_type: undefined,
  supervisor: undefined,
  aed_location: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Line Empty Pre JB
export const LINE_EMPTY_PRE_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...LINE_EMPTY_FORM_METADATA,
    template_name: 'pre_job_brief',
    template_version: undefined,
    form_type: 'pre_job_brief',
    display_name: 'Pre-Job Brief',
    form_id: 0
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Line empty Post JB
export const LINE_EMPTY_POST_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...LINE_EMPTY_FORM_METADATA,
    template_name: 'post_job_brief',
    template_version: undefined,
    form_type: 'post_job_brief',
    display_name: 'Post-Job Brief'
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Fleet empty Pre JB
export const FLEET_EMPTY_PRE_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...FLEET_EMPTY_FORM_METADATA,
    template_name: 'pre_job_brief',
    template_version: undefined,
    form_type: 'pre_job_brief',
    display_name: 'Pre-Job Brief',
    form_id: 0
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Fleet empty Post JB
export const FLEET_EMPTY_POST_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...FLEET_EMPTY_FORM_METADATA,
    template_name: 'post_job_brief',
    template_version: undefined,
    form_type: 'post_job_brief',
    display_name: 'Post-Job Brief'
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Non-craft Empty Pre JB
export const NON_CRAFT_EMPTY_PRE_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...NON_CRAFT_EMPTY_FORM_METADATA,
    template_name: 'pre_job_brief',
    template_version: undefined,
    form_type: 'pre_job_brief',
    display_name: 'Pre-Job Brief',
    form_id: 0
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Non-craft Empty Post JB
export const NON_CRAFT_EMPTY_POST_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...NON_CRAFT_EMPTY_FORM_METADATA,
    template_name: 'post_job_brief',
    template_version: undefined,
    form_type: 'post_job_brief',
    display_name: 'Post-Job Brief'
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Transmission Empty Pre JB
export const TRANSMISSION_EMPTY_PRE_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...TRANSMISSION_EMPTY_FORM_METADATA,
    template_name: 'pre_job_brief',
    template_version: undefined,
    form_type: 'pre_job_brief',
    display_name: 'Pre-Job Brief',
    form_id: 0
  },
  form_template: undefined
};

// $_FORM_TYPE_ADJUSTMENT_$ -> Transmission Empty Post JB
export const TRANSMISSION_EMPTY_POST_JB: DeForm = {
  form_value: undefined,
  form_metadata: {
    ...TRANSMISSION_EMPTY_FORM_METADATA,
    template_name: 'post_job_brief',
    template_version: undefined,
    form_type: 'post_job_brief',
    display_name: 'Post-Job Brief'
  },
  form_template: undefined
};

export const NEW_FORM_GROUP = {
  form_group_uuid: undefined,
  completed: false,
  forms: []
};