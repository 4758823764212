import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AppConfig } from './config/app.config';
import { FirebaseService } from './services/firebase/firebase.service';
import { LocalDatabaseService } from './services/storage/local-database.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DynatraceService } from './services/dynatrace/dynatrace.service';
import { LocationService } from './services/location/location.service';
import esriConfig from "@arcgis/core/config.js";
import { ApiService } from './services/api/api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  environment = this.config.getConfig('envName');

  private readonly _destroying$ = new Subject<void>();

  @HostListener('window:offline', ['$event'])
  @HostListener('window:online', ['$event'])
  onNetworkChange(onlineEvent: Event) {
    if (onlineEvent?.type === 'online' || onlineEvent?.type === 'offline') {
      console.log(`Application is now ${onlineEvent.type.toUpperCase()}`);
      if (onlineEvent.type === 'online') {
        // Check for any unsubmitted forms when coming back online
        this.api.submitAnyPendingForms();
      }
    }
  }

  constructor(
    private broadcastService: MsalBroadcastService,
    private router: Router,
    private db: LocalDatabaseService,
    private firebase: FirebaseService,
    private config: AppConfig,
    private http: HttpClient,
    private dynatrace: DynatraceService,
    private api: ApiService,
    private location: LocationService,
    private titleService: Title
  ) {
    this.db.initialize().finally(() => {
      this.firebase.initialize();
      this.location.askForLocation();
    });

    // Set api key for ArcGIS
    esriConfig.apiKey = this.config.getConfig('arcGISApiKey');
    // Configure assets path for ArcGIS
    esriConfig.assetsPath = "./assets";

    const title = 'Job Brief';
    switch (this.environment) {
      case 'local':
        this.titleService.setTitle(`(Local) ${title}`);
        break;
      case 'sbx':
        this.titleService.setTitle(`(SBX) ${title}`);
        break;
      case 'dev':
        this.titleService.setTitle(`(DEV) ${title}`);
        break;
      case 'qa':
        this.titleService.setTitle(`(QA) ${title}`);
        break;
      case 'prod':
        this.titleService.setTitle(`${title}`);
        break;
    }
  }

  ngOnInit() {
    this.broadcastService.msalSubject$
      .pipe(
        tap(msg => {
          if (msg.eventType === EventType.LOGIN_FAILURE
            || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
            console.error(msg.error);
            this.router.navigate(['login']);
          }
        }),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(
        _ => this.dynatrace.tagDynatraceUser(),
        err => console.error(err)
      );

    this.configureDynatrace();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async configureDynatrace() {
    const scriptCode = await (await fetch(this.config.getConfig('dynatraceScript'))?.catch(e => undefined))?.text()?.catch(e => undefined);

    if (scriptCode) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = scriptCode;
      head.insertBefore(script, head.firstChild);

      if (window.dT_) {
        console.log('Dynatrace loaded.')
        if (window.dT_?.initAngularNg) {
          window.dT_.initAngularNg(this.http, HttpHeaders);
          console.log('Dynatrace configured for Angular.')
        }
      }
    }
  }
}
