const azureConfigs = {
  graphUrl: 'https://graph.microsoft.com/v1.0'
};

const apiBaseUrlEnvMap = {
  sbx: 'https://e5rnlayl79.execute-api.us-east-1.amazonaws.com/sbx',
  dev: 'https://ibr5e93ej8.execute-api.us-east-1.amazonaws.com/dev',
  qa: 'https://h85tqykh5c.execute-api.us-east-1.amazonaws.com/qa',
}

const localConfig = (env: 'sbx' | 'dev' | 'qa') => ({
  production: false,
  envName: "local",
  azure_client_id: '9eb2e5cd-5b53-4077-9004-7cc2fcb18825',
  graphUrl: azureConfigs.graphUrl,
  app_url: 'http://localhost:4200/',
  azure_tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
  apiBaseUrl: apiBaseUrlEnvMap[env],
  dynatraceScript: 'https://js-cdn.dynatrace.com/jstag/173eb6dfd63/bf55525kcq/864987718f609c47_complete.js',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  weatherApiKey: '3679bfa30c83445aa08184629221807',
  arcGISApiKey: 'AAPK9ab9fd9c4b2b4c7aa6a69db529e3e4e8Ky5wlrV_3GdkcwC9X8vM5VCAuHmqxrmD7QUAhKE6kAnJB5Xitrldc3eiGB2kAIUq'
});

export const environments = {
  'local-sbx': localConfig('sbx'),
  'local-dev': localConfig('dev'),
  'local-qa': localConfig('qa'),
  sbx: {
    production: false,
    envName: "sbx",
    azure_client_id: "@AZURE-CLIENT-ID@",
    graphUrl: azureConfigs.graphUrl,
    app_url: "@APP-URL@",
    azure_tenant: "@AZURE-TENANT-ID@",
    apiBaseUrl: "@API-BASE-URL@",
    dynatraceScript: "@DYNATRACE-SCRIPT@",
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@'
  },
  dev: {
    production: false,
    envName: "dev",
    azure_client_id: "@AZURE-CLIENT-ID@",
    graphUrl: azureConfigs.graphUrl,
    app_url: "@APP-URL@",
    azure_tenant: "@AZURE-TENANT-ID@",
    apiBaseUrl: "@API-BASE-URL@",
    dynatraceScript: "@DYNATRACE-SCRIPT@",
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@'
  },
  qa: {
    production: false,
    envName: "qa",
    azure_client_id: "@AZURE-CLIENT-ID@",
    graphUrl: azureConfigs.graphUrl,
    app_url: "@APP-URL@",
    azure_tenant: "@AZURE-TENANT-ID@",
    apiBaseUrl: "@API-BASE-URL@",
    dynatraceScript: "@DYNATRACE-SCRIPT@",
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@'
  },
  prod: {
    production: true,
    envName: "prod",
    azure_client_id: "@AZURE-CLIENT-ID@",
    graphUrl: azureConfigs.graphUrl,
    app_url: "@APP-URL@",
    azure_tenant: "@AZURE-TENANT-ID@",
    apiBaseUrl: "@API-BASE-URL@",
    dynatraceScript: "@DYNATRACE-SCRIPT@",
    weatherApiUrl: '@WEATHER-API-URL@',
    weatherApiKey: '@WEATHER-API-KEY@',
    arcGISApiKey: '@ARC-GIS-API-KEY@'
  },
};