<!-- Accordion button -->
<button [attr.aria-label]="'Expand Section ' + section.field_display" *ngIf="isAccordion && displayComponent"
  class="accordion" [class.expanded]="expandedSection" (click)="toggleAccordion()"
  [disabled]="sectionDisabled || summaryView" type="button">
  <span>{{section.field_display}}</span>
  <svg *ngIf="sectionComplete && !summaryView" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 76 76">
    <defs>
      <path id="icon-positive-check-a"
        d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
    </defs>
    <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
  </svg>
  <div *ngIf="!summaryView" class="accordion-triangle" [class.expanded]="expandedSection"></div>
</button>

<!-- Expanded Section -->
<fieldset *ngIf="expandedSection && section.field_name !=='final_review' && (review ? section.field_name !=='initials' && section.field_name !=='participant_sign_off': true)" 
 [formGroup]="section.form_group" [style.padding]="fieldsPadding">
  <legend *ngIf="showLegend">{{section.field_display}} <a *ngIf="review" class="edit-link"
    [routerLink]="['/', 'forms', 'edit', group_uuid, form_uuid]" 
    [fragment]="section?.field_name === 'job_information' || section?.field_name === 'job_description' ? 'job_info_and_description' : section.field_name"
    [queryParams]="{review: true}">Edit</a>
  </legend>
  <p *ngIf="(showLegend || isAccordion) && section.description?.length > 0" class="description">
    {{section.description}}
  </p>
  <div class="fields" [class.showLegend]="showLegend" [class.visible]="visible" [style.border]="fieldsBorder"
    [style.background]="fieldsBackground">
    <de-template-form-component *ngFor="let component of section.components; let i = index" [component]="component"
      [formGroupRef]="formGroupRef" [style]="component.styles || {}" [id]="component.element_id"
      (change)="handleRadioComponentChange(component)" (lastFieldCompleted)="openNextAccordion(component, i)"
      (selectAll)="selectAllCheckboxes()" [logEvent]="logEvent" [componentIsReadOnly]="readOnly || review">
    </de-template-form-component>
  </div>
  <div class="pageBreak" style="page-break-after: always;"></div>
</fieldset>

<!-- Expand Normal Section button -->
<button [attr.aria-label]="'Expand Section ' + section.field_display"
  *ngIf="collapsedSection && !section.popup && !anySectionIsExpanded && !section.hide_in_main_list && displayComponent"
  class="section-expand" (click)="expandSection()" [disabled]="sectionDisabled" type="button">
  <span>{{section.field_display}}</span>
  <svg *ngIf="sectionComplete" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 76 76">
    <defs>
      <path id="icon-positive-check-a"
        d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
    </defs>
    <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
  </svg>
  <div class="section-expand-arrow"></div>
</button>

<!-- Expand Popup Section button -->
<button [attr.aria-label]="'Expand Section ' + section.field_display"
  *ngIf="collapsedSection && section.popup && displayComponent" class="section-expand popup"
  [class.increase-popup-button-height]="section.popup_primary_data?.length > 0"
  [class.filled-button]="section.styleAsDukeFilledButton" [class.error]="!sectionComplete"
  [class.summaryView]="summaryView"
  [class.required-untouched-no-warning]="!sectionComplete && !sectionTouched && !alwaysWarnMissingFields"
  (click)="expandSection()" [disabled]="sectionDisabled || summaryView" type="button">
  <div class="popup-info">
    <span *ngIf="section?.popup_placeholder"
      [class.subscript]="section.popup_primary_data?.length > 0">{{section.popup_placeholder}}</span>
    <span *ngIf="section?.popup_primary_data">{{section.popup_primary_data}}</span>
    <span *ngIf="section?.popup_secondary_data"
      [class.subscript]="section.popup_primary_data?.length > 0">{{section.popup_secondary_data}}</span>
  </div>
  <ng-container *ngIf="!sectionComplete && !summaryView">
    <svg *ngIf="!section.showPopupEditText" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }

          .cls-2 {
            fill: #00789e;
          }
        </style>
      </defs>
      <g id="Group_7927" data-name="Group 7927">
        <g id="Ellipse_78" data-name="Ellipse 78">
          <circle class="cls-1" cx="25" cy="25" r="25" />
          <path class="cls-2"
            d="M25,50A25,25,0,1,1,50,25,25,25,0,0,1,25,50ZM25,4.41A20.59,20.59,0,1,0,45.58,25,20.62,20.62,0,0,0,25,4.41Z" />
        </g>
        <path class="cls-2"
          d="M40.9,23.61,30.36,14.47a2.2,2.2,0,0,0-2.89,3.33l5.76,5H10.72a2.21,2.21,0,1,0,0,4.41h23L27.5,32.47a2.2,2.2,0,1,0,2.84,3.37L40.88,27a2.21,2.21,0,0,0,0-3.35Z" />
      </g>
    </svg>
    <span *ngIf="section.showPopupEditText" class="text-button">
      {{section.popupEditText || 'Edit'}}
    </span>
  </ng-container>
  <svg *ngIf="sectionComplete && section.showCheckWhenComplete" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 76 76">
    <defs>
      <path id="icon-positive-check-a"
        d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
    </defs>
    <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
  </svg>
  <span *ngIf="sectionComplete && !section.showCheckWhenComplete && !summaryView" class="text-button">
    {{section.popupEditText || 'Edit'}}
  </span>
</button>

<!-- Popup Additional Actions -->
<div *ngIf="showAdditionalActionButton" class="additional-actions">
  <button class="ghost-button" (click)="secondaryAction()" type="button"
    [disabled]="!secondaryActionEnabled">{{secondaryActionText}}</button>
</div>

<!-- OR -->

<!-- Warning Banner -->
<p *ngIf="isWarningBanner && displayComponent" class="warning-banner {{warningBanner.color}}"
  [class.warningIcon]="warningBanner.showErrorIcon">
  <svg *ngIf="warningBanner.showErrorIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
    <title>icon alert</title>
    <path d="M70,35A35,35,0,1,1,35,0,35,35,0,0,1,70,35" fill="#9e1b32" />
    <path d="M35,45.73a4.94,4.94,0,0,0-5.11,5v.12a5.11,5.11,0,0,0,10.23,0v-.12A4.94,4.94,0,0,0,35,45.73Z" fill="#fff" />
    <path
      d="M37.87,14.17H32.12c-1.59,0-2.53,1.3-2.35,3L32.3,39.26a2.71,2.71,0,0,0,5.41,0l2.53-22.09C40.4,15.47,39.47,14.17,37.87,14.17Z"
      fill="#fff" />
  </svg>
  <span [innerHTML]="warningBanner.warning" [tabIndex]="warningBanner.clickCallback ? 0 : -1"
    (click)="warningBannerClick()" (keyup.enter)="warningBannerClick()" (keyup.space)="warningBannerClick()"></span>
</p>

<!-- OR -->

<!-- Form Element -->
<ng-container *ngIf="isFormElement && displayComponent">
  <div class="form-element {{formElement.component_type}}" [ngSwitch]="formElement.component_type">
    <!-- Text Input -->
    <de-form-text-input [@fade] *ngSwitchCase="'text_input'" [formElement]="formElement"
      [formControl]="formElement.form_control" [showBorder]="true" [showBackground]="false" [showUnderline]="false"
      [min]="min" [max]="max" [readOnly]="readOnly">
    </de-form-text-input>

    <!-- Radio Section Header -->
    <de-checkbox-section-header *ngSwitchCase="'radio_section_header'" [formElement]="formElement"
      (selectAllOptions)="selectAll.emit()" [logEvent]="logEvent" [readOnly]="readOnly">
    </de-checkbox-section-header>

    <!-- Radio -->
    <de-radio [@fade] *ngSwitchCase="'radio'" [formElement]="formElement" [formControl]="formElement.form_control"
      (valueSelected)="valueChange($event)" [readOnly]="readOnly"></de-radio>

    <!-- Select/Dropdown -->
    <de-select [@fade] *ngSwitchCase="'dropdown'" [formElement]="formElement" [formControl]="formElement.form_control"
      (valueSelected)="valueChange($event)" [formGroupRef]="formGroupRef" [readOnly]="readOnly"></de-select>

    <!-- Add/Remove Section -->
    <de-add-remove-section [@fade] [formElement]="formElement" *ngSwitchCase="'add_remove_section'"
      [formControl]="formElement.form_control" [formGroupRef]="formGroupRef" [logEvent]="logEvent"
      [readOnly]="readOnly">
    </de-add-remove-section>

    <!-- Add/Remove Section -->
    <de-signature-panel [@fade] [formElement]="formElement" *ngSwitchCase="'signature_panel'"
      [formControl]="formElement.form_control" [logEvent]="logEvent" [readOnly]="readOnly">
    </de-signature-panel>
  </div>
</ng-container>