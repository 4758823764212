import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';
import { LocalDatabaseService, USER_SETTINGS_GENERIC_TABLE_NAME } from '../storage/local-database.service';
import { OverlayService } from '../overlay/overlay.service';
import { SelectDialogComponent, SelectDialogConfig } from 'src/app/modules/shared/select-dialog/select-dialog.component';
import { UserType, USER_TYPES, USER_TYPE_SELECTION_OPTIONS } from '../forms/Forms';
import { MsalBroadcastService } from '@azure/msal-angular';
import { UserService } from '../auth/user.service';

/**
 * These are op centers that have been renamed and should not be automatically set
 */
export const DEPRECATED_OP_CENTERS = ['Madison'];

export interface UserSettings {
  user_type?: UserType;
  operation_center?: string;
  department_center?: string;
  fleet_garage?: string;
  recent_addresses?: string[];
  region?: string;
  supervisor?: string;
  aed_location?: string;
  job_type?: string;
  eic?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private _apiEndpoint = this.config.getConfig('apiBaseUrl');
  private _userSettings: UserSettings;

  private _userSettings$ = new BehaviorSubject<UserSettings>(null);

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private localDB: LocalDatabaseService,
    private overlayService: OverlayService,
    private msalBroadcast: MsalBroadcastService,
    private userService: UserService
  ) {
    // Wait for user's token before fetching settings
    this.msalBroadcast.msalSubject$
      .pipe(filter(_ => !!this.userService.azureAccount), take(1))
      .subscribe(_ => this._fetchUserSettingsFromAPI());
  }

  get userSettings() {
    return this._userSettings$.pipe(
      filter(settings => !!settings),
      take(1)
    );
  }

  get userType() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.user_type)
    );
  }

  get opCenter() {
    return this.userSettings.pipe(
      map(userSettings =>
        DEPRECATED_OP_CENTERS.includes(userSettings.operation_center)
          ? undefined
          : userSettings.operation_center
      )
    );
  }

  get department() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.department_center)
    );
  }

  get eic() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.eic)
    );
  }

  get supervisor() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.supervisor)
    );
  }

  get aedLocation() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.aed_location)
    );
  }

  get jobType() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.job_type)
    );
  }

  get fleetGarage() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.fleet_garage)
    );
  }

  get recentAddresses() {
    return this.userSettings.pipe(
      map(userSettings => userSettings.recent_addresses)
    );
  }

  setOpCenter(operation_center: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.operation_center === operation_center
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, operation_center }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred op center to ${operation_center}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred op center.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setDepartment(department_center: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.department_center === department_center
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, department_center }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred  department to ${department_center}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred department.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setEIC(eic: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.eic === eic
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, eic }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred EIC to ${eic}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred EIC.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setSupervisor(supervisor: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.supervisor === supervisor
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, supervisor }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred supervisor to ${supervisor}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred supervisor.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setJobType(job_type: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.job_type === job_type
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, job_type }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred Job Type to ${job_type}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred Job Type.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setAedLocation(aed_location: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.aed_location === aed_location
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, aed_location }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred AED/First Aid/Burn Kit/Fire Ext. Location to ${aed_location}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred AED/First Aid/Burn Kit/Fire Ext. Location.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setGarage(fleet_garage: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.fleet_garage === fleet_garage
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, fleet_garage }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred garage to ${fleet_garage}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred garage.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  setRegion(region: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.region === region
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, region }).pipe(
            map(() => {
              console.log(`Successfully updated user's preferred region to ${region}.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's preferred region.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  addToRecentAddresses(address: string) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
        userSettings.recent_addresses?.includes(address)
          ? of(undefined)
          : this._updateUserSettings({ ...userSettings, recent_addresses: [address, ...(userSettings.recent_addresses ?? []).slice(0, 2)] }).pipe(
            map(() => {
              console.log(`Successfully added ${address} to user's recent addresses.`);
              return undefined;
            }),
            catchError(() => {
              console.log(`Error updating user's recent addresses.`);
              return of(undefined);
            })
          )
    )).toPromise();
  }

  promptUserTypeAndUpdateSettings(): Observable<UserSettings> {
    return this._promptUserType(this._userSettings?.user_type).pipe(
      take(1),
      mergeMap(
        userTypeDisplayValue => {
          this._userSettings.user_type = USER_TYPE_SELECTION_OPTIONS[userTypeDisplayValue];
          return this._updateUserSettings(this._userSettings).pipe(
            map(() => this._userSettings)
          );
        }
      )
    )
  }

  updateUserSettings(newUserSettings: UserSettings) {
    return this.userSettings.pipe(mergeMap(
      userSettings =>
      // Only update user setting if one of the new user settings is different
      Object.keys(newUserSettings).some(s => newUserSettings[s] && newUserSettings[s] !== userSettings[s])
      ? this._updateUserSettings({ ...userSettings, ...newUserSettings }).pipe(
        map(() => {
          console.log(`Successfully updated user settings.`);
          return undefined;
        }),
        catchError(() => {
          console.log(`Error updating user settings.`);
          return of(undefined);
        })
      )
      : of(undefined)
    )).toPromise();
  }

  private _fetchUserSettingsFromAPI() {
    this.http.get<UserSettings>(`${this._apiEndpoint}/user/settings`).pipe(
      mergeMap(response => {
        // Verify that the user type is valid
        this._userSettings = {
          user_type: USER_TYPES.includes(response?.user_type)
            ? response.user_type
            : undefined,
          operation_center: response?.operation_center,
          department_center: response?.department_center,
          fleet_garage: response?.fleet_garage,
          region: response?.region,
          supervisor: response?.supervisor,
          aed_location: response?.aed_location,
          job_type: response?.job_type,
          eic: response?.eic,
          recent_addresses: response?.recent_addresses ?? []
        };

        if (!this._userSettings.user_type) {
          // If the user type had not been set, prompt for the user type and
          // update user settings on the backend (this also stores them locally)
          return this.promptUserTypeAndUpdateSettings();
        } else {
          // Storing user settings locally for offline mode
          this.localDB.postObjectToGenericTable(USER_SETTINGS_GENERIC_TABLE_NAME, this._userSettings)
            .then(() => console.log('Successfully stored user settings in local DB.'))
            .catch(err => console.error(err));

          // Return user settings
          return of(this._userSettings);
        }
      }),
      catchError(_ => {
        console.log('Error fetching user settings, falling back to latest value stored in local DB...');
        return from(this.localDB.getGenericTableValue<UserSettings>(USER_SETTINGS_GENERIC_TABLE_NAME)).pipe(
          catchError(err => {
            console.error('Error fetching user settings from local DB.');
            return throwError(err);
          }),
          mergeMap(settings => {
            if (settings) {
              console.log('Found the following user settings:');
              console.log(settings);
              return of(settings);
            } else {
              console.log('No user settings exist locally.');
              return of({
                user_type: undefined as UserType,
                operation_center: undefined as string,
                department_center: undefined as string,
                fleet_garage: undefined as string,
                supervisor: undefined as string,
                aed_location: undefined as string,
                job_type: undefined as string,
                eic: undefined as string,
                recent_addresses: [] as string[]
              } as UserSettings);
            }
          })
        );
      })
    ).subscribe(userSettings => this._userSettings$.next(userSettings));
  }

  private _promptUserType(initialUserType?: UserType): Observable<string> {
    const drawerRef = this.overlayService.open(SelectDialogComponent, {
      width: '600px',
      height: '400px',
      bottom: true,
      centerHorizontally: true,
      disableClosing: true,
      data: {
        options: Object.keys(USER_TYPE_SELECTION_OPTIONS),
        initialSelection: Object.keys(USER_TYPE_SELECTION_OPTIONS).find(display => USER_TYPE_SELECTION_OPTIONS[display] === initialUserType),
        selectHeader: 'Select User Type',
        selectActionText: 'Confirm User Type'
      } as SelectDialogConfig
    });
    return drawerRef.afterClosed() as Observable<string>;
  }

  private _updateUserSettings(user_settings: UserSettings) {
    return this.http.post(`${this._apiEndpoint}/user/settings`, user_settings)
      .pipe(map(_ => {
        this._userSettings = user_settings;
        this._userSettings$.next(this._userSettings);
        this.localDB.postObjectToGenericTable('user_settings', this._userSettings)
          .then(() => console.log('Successfully updated user settings in local DB.'))
          .catch(err => console.log(err));
      }));
  }
}
