import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeRadioFormElement } from '../../models/DeTemplateForms';
import { FormElementComponent } from '../form-element.component';

@Component({
  selector: 'de-radio',
  templateUrl: './de-radio.component.html',
  styleUrls: ['./de-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeRadioComponent
    }
  ]
})
export class DeRadioComponent extends FormElementComponent<string> implements OnInit, ControlValueAccessor {

  /**
   * Indicates that 'Yes', 'No', and/or 'N/A' are the only options
   */
  yesNoNAQuestion: boolean;

  @Input() formElement: DeRadioFormElement;

  @Input() readOnly: boolean;

  @HostBinding('class.focused') get focused() {
    return this._focused;
  }
  @HostBinding('class.error') get hasError() {
    return !this._hasValue && (this._touched || this.formElement.touch || (this.formElement.touchIfHasValue && this._hasValue)) && this.required;
  }
  @HostBinding('class.required') get required() {
    return this.isRequired(this.formElement);
  }
  @HostBinding('class.hasValue') get hasValue() {
    return this._hasValue;
  }
  @HostBinding('class.showOnlyAnswer') get showOnlyAnswerToQuestion() {
    return this.showOnlyAnswer;
  }
  @HostBinding('class.reverse') get reverse() {
    return this.formElement.checkboxPosition === 'left';
  }
  @HostBinding('class.center') get center() {
    return this.formElement.center;
  }

  @Output() valueSelected = new EventEmitter<string>();

  @ViewChild('labelElement') labelElement: ElementRef<HTMLLabelElement>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.yesNoNAQuestion = !this.options?.some(o => o?.length > 0 && !/^yes|N\/A|no$/i.test(o));
  }

  get name() {
    return this.formElement.field_name ?? '';
  }

  get label() {
    return this.formElement.field_display ?? '';
  }

  get options() {
    return this.formElement.options ?? [];
  }

  get allowToggling() {
    return this.formElement.allowToggling;
  }

  get link() {
    return this.formElement.link;
  }

  get linkText() {
    return this.formElement.link_text;
  }

  get linkVerticalPosition() {
    return this.formElement.link_vertical_position ?? 'top';
  }

  get linkHorizontalPosition() {
    return this.formElement.link_horizontal_position ?? 'right';
  }

  /**
   * Indicates that we should only show the answer, not all options
   */
  get showOnlyAnswer() {
    return this.readOnly && this.yesNoNAQuestion;
  }

  focus() {
    this._focused = true;
  }

  blur() {
    this._focused = false;
  }

  labelClicked() {
    if (this.options?.length === 1 && this.options[0]?.length > 0 && !this.readOnly && !this._disabled) {
      this.valueChange(this.options[0], true);
    }
  }

  valueChange(value: string, event?: boolean): void {
    // If the change was triggered by the mouse/keyboard, then we set the value
    // to null if the value being clicked is the current value AND if 'allowToggling' is set to true.
    super.valueChange(this.allowToggling && event && value === this._value ? null : value);
    if (event) {
      this.valueSelected.emit(this._value);
      // If its an "Other" question and you choose "Yes", it will scroll up "Other" box into view.
      if ((value === 'Yes' || value === 'Checked') && this.label?.includes('Other')) {
        this.labelElement?.nativeElement?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
      }
    }
  }
}
