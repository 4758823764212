<button [attr.aria-label]="showBack ? 'Back' : 'Open Sidebar'" class="hamburger" *ngIf="active" [class.back]="showBack"
  [class.active]="active" (click)="navButtonClicked()">
  <div></div>
</button>

<button aria-label="Go Home" class="logo" *ngIf="!showBack" [class.active]="active" [disabled]="!active"
  (click)="titleClicked()">
  <svg *ngIf="active" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 403.1 403.1"
    style="enable-background:new 0 0 403.1 403.1;" xml:space="preserve">
    <style type="text/css">
      .st0 {
        fill: url(#SVGID_1_);
      }

      .st1 {
        fill: #FFFFFF;
      }

      .st2 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #FFFFFF;
      }
    </style>
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-2.861023e-06" y1="403.11" x2="403.11"
      y2="2.861022e-06" gradientTransform="matrix(6.123234e-17 1 -1 6.123234e-17 403.11 5.722048e-06)">
      <stop offset="0" style="stop-color:#25BCD6" />
      <stop offset="1" style="stop-color:#0582AC" />
    </linearGradient>
    <polygon class="st0" points="0,403.1 0,0 403.1,0 403.1,403.1 " />
    <g>
      <g id="Layer_1_00000041987488729081927700000011989287820570333855_">
        <path class="st1" d="M403.1,127.7v275.4h-52.2H126.7l-51.3-132c-7.2-18.5,2-39.3,20.5-46.5l88.9-34.6l0.5,1.2l-3.6,2.1
			c-13,7.8-25.3,16.8-36.6,26.9c-10.4,9.2-18.5,20.7-23.9,33.5c-3.8,9.2-3.9,19.5-0.3,28.7c3.4,8.8,13.2,13.1,22,9.7l233.5-90.8
			c8.8-3.4,13.1-13.2,9.7-22c-3.6-9.3-10.6-16.8-19.6-21c-12.6-5.9-26.3-8.8-40.2-8.6c-15.2,0.2-30.3,1.9-45.2,4.9l-4.1,0.8
			l-0.5-1.2l88.5-34.4C378.2,114.8,393,117.9,403.1,127.7z" />
        <path class="st1" d="M359.4,173.9c-10.3-4.8-21.5-7.2-32.8-7c-14.1,0.2-28.2,1.7-42,4.5l-18.1,3.7L252,138
			c-5.8-16.8-24.2-25.7-41-19.9s-25.7,24.2-19.9,41c0.3,0.7,0.5,1.5,0.9,2.2l14.3,37.1l-15.8,9.5c-6.1,3.6-11.9,7.6-17.6,11.7
			s-11.2,8.6-16.5,13.3c-8.4,7.5-15.1,16.8-19.4,27.3c-2.1,5.1-2.2,10.8-0.2,16l233.5-90.7C368.3,180.4,364.4,176.2,359.4,173.9z
			 M226.9,161.7c-8.1,3.1-17.3-0.9-20.4-9s0.9-17.3,9-20.4c8.1-3.1,17.2,0.9,20.4,9C239,149.4,235,158.5,226.9,161.7
			C226.9,161.7,226.9,161.7,226.9,161.7z" />
        <path class="st2" d="M119.7,147.1c-0.2,0.2-4,4.2-8.6,4.5c-5.1,0.3-9.9-2.8-11.4-14l-20.2-6c8.8,31.5,33.4,30.4,41.7,16.5
			c0.6-0.9,0.4-1.4,0-1.6S120.2,146.5,119.7,147.1" />
        <path class="st2" d="M119.7,64.3c-8.1-16.5-37-0.2-42,41.9c-0.3,2.8,1,4.2,2.2,4.8c2.6,1.4,5.4,2.3,8.2,2.8
			c2.2,0.5,4.5,0.7,6.8,0.6c2.1-0.1,3.7-1.7,4-3.7c4.3-45.3,14.6-55.3,19.4-45.6C118.8,66.1,120.3,65.6,119.7,64.3" />
        <path class="st2" d="M114.8,78.6c-2.3,0-4,0.4-5.2,2.3c-1.2,2.2-2,4.5-2.3,6.9c-0.4,2.5-0.9,6.3-0.4,7.6c0.4,1.3,1.6,2.3,3,2.3
			c9.2,0.6,36.2,4.4,39.9,12.3c2.5,5.4-5,6.8-10.6,8.2c-1.9,0.3-3.3,1.8-3.6,3.6c-0.6,4.4,0.5,6.7,1.5,8.1c0.8,1.2,2.4,1.5,3.6,0.8
			c0.1,0,0.1-0.1,0.2-0.1c2.9-1.9,5.5-4.2,7.6-7C166.5,98.3,128.6,78.8,114.8,78.6" />
        <path class="st2" d="M124,119.9c-45.3,5-81.1-15.8-54-20.8c0.1,0,3.4-0.8,4.1-2.8s0.8-2.6,1.6-5.4c0.5-1.8,0.9-2.8,1.3-4.2
			c0.7-2.1-0.6-3.4-3.6-1.9C48.7,97,56.1,119.6,76.1,131c21.8,12.3,41.9,7.8,43.9,7.3c2.5-0.5,4.7-2,6-4.2c2-3.1,2.9-6.8,2.4-10.4
			C128.5,123.7,128.4,119.5,124,119.9" />
      </g>
    </g>
  </svg>

  <ng-container *ngIf="!this.isProduction">
    <img src="../../../../assets/graphics/PJBQA_logo.svg" *ngIf="active" alt="QAlogo">
  </ng-container>

  <svg *ngIf="!active" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.999 33.62">
    <g id="Group_46" data-name="Group 46" transform="translate(-899 355)">
      <g id="Group_45" data-name="Group 45">
        <path id="Path_75" data-name="Path 75"
          d="M20.528,29.554a4.938,4.938,0,0,1-2.865,1.5c-1.68.1-3.277-.918-3.77-4.627l-6.7-1.974c2.937,10.413,11.09,10.052,13.822,5.459.188-.293.137-.451,0-.528s-.315-.017-.484.166"
          transform="translate(899 -355)" fill="#005884" />
        <path id="Path_76" data-name="Path 76"
          d="M20.52,2.093C17.832-3.352,8.251,2.022,6.609,15.946a1.469,1.469,0,0,0,.732,1.6,8.106,8.106,0,0,0,2.731.909,7.911,7.911,0,0,0,2.239.186,1.4,1.4,0,0,0,1.345-1.221C15.08,2.462,18.489-.873,20.071,2.333c.186.37.651.2.449-.238"
          transform="translate(899 -355)" fill="#005884" />
        <path id="Path_77" data-name="Path 77"
          d="M18.934,6.838a1.73,1.73,0,0,0-1.714.752,6.548,6.548,0,0,0-.761,2.3,8.39,8.39,0,0,0-.151,2.509,1.038,1.038,0,0,0,.99.755c3.053.189,12.011,1.466,13.239,4.07.841,1.79-1.662,2.248-3.5,2.722a1.492,1.492,0,0,0-1.2,1.2,3.466,3.466,0,0,0,.5,2.685.858.858,0,0,0,1.187.247.613.613,0,0,0,.058-.042,10.459,10.459,0,0,0,2.526-2.3c5.982-8.36-6.584-14.812-11.166-14.9"
          transform="translate(899 -355)" fill="#54b947" />
        <path id="Path_78" data-name="Path 78"
          d="M21.959,20.524c-15.022,1.658-26.881-5.2-17.9-6.868.042,0,1.144-.264,1.362-.912s.28-.86.534-1.788c.16-.588.293-.923.446-1.375.236-.7-.2-1.124-1.205-.636-8.2,4-5.766,11.484.893,15.238a20.742,20.742,0,0,0,14.527,2.4A2.956,2.956,0,0,0,22.6,25.2a5.131,5.131,0,0,0,.8-3.442,1.232,1.232,0,0,0-1.2-1.257,1.168,1.168,0,0,0-.236.018"
          transform="translate(899 -355)" fill="#04beda" />
      </g>
      <g id="Group_44" data-name="Group 44">
        <path id="Path_79" data-name="Path 79"
          d="M38.541,3.758h.41c2.828,0,6.2.869,6.2,5s-3.372,5.006-6.2,5.006h-.41Zm.926-1.887H36.514V15.656h2.9c4.789,0,7.765-2.667,7.765-6.964,0-4.144-3.028-6.822-7.713-6.822Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_80" data-name="Path 80"
          d="M56.533,9.974c0,2.723-1,4.043-3.062,4.043S50.408,12.7,50.408,9.974v-8.1h-2.03v8.674c0,3.229,2.047,5.4,5.1,5.4s5.094-2.167,5.094-5.4V1.874H56.533v8.1"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_81" data-name="Path 81"
          d="M64.371,8.1,70.6,1.871H67.9L62.454,7.439V1.874H60.423V15.656h2.031V9.946l.48-.457,4.992,6.167h2.725L64.37,8.1"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_82" data-name="Path 82"
          d="M79.01,13.769H73.469V9.049h5.38V7.16h-5.38V3.76H79.01V1.873H71.441V15.656H79.01Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_83" data-name="Path 83"
          d="M36.655,33.334h7.57V31.447H38.683V26.726h5.38V24.839h-5.38v-3.4h5.542v-1.89h-7.57Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_84" data-name="Path 84"
          d="M59.792,33.334h7.571V31.447H61.821V26.726H67.2V24.839H61.821v-3.4h5.541v-1.89h-7.57Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_85" data-name="Path 85"
          d="M55.958,19.548V29.2l-8.823-9.654h-1.26V33.334h2.033V23.592L56.7,33.334h1.285V19.548Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_86" data-name="Path 86"
          d="M74.945,23.627c0,1.58-1.044,2.281-3.386,2.281H71.2V21.4h.267c2.37,0,3.474.707,3.474,2.226m1.923-.069a3.876,3.876,0,0,0-1.854-3.363,7.289,7.289,0,0,0-3.63-.647H69.177V33.334h2.03V27.652h.424l3.946,5.682h2.475l-4.275-5.889a3.82,3.82,0,0,0,3.087-3.888Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_87" data-name="Path 87"
          d="M85.843,28.1H89.3a4.394,4.394,0,0,1-4.209,3.635,5.317,5.317,0,0,1-5.04-5.309,5.172,5.172,0,0,1,5.073-5.271h.108A5.157,5.157,0,0,1,89.31,23.27l.12.163,1.441-1.375-.1-.126a7.174,7.174,0,1,0-5.783,11.686,6.4,6.4,0,0,0,6.541-6.255c0-.149,0-.3,0-.445v-.71H85.843v1.886"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_88" data-name="Path 88"
          d="M100.025,19.548,96.832,25.1l-3.193-5.556H91.3l4.511,7.815v5.971h2.03V27.363l4.514-7.815Z"
          transform="translate(899 -355)" fill="#005984" />
        <path id="Path_89" data-name="Path 89"
          d="M102.426,30.531v.591h.367c.317,0,.753.052.753-.271,0-.3-.252-.32-.533-.32Zm1.432,2.016h-.392l-.6-1.088h-.44v1.088h-.338V30.194h.991c.423,0,.809.126.809.623a.6.6,0,0,1-.556.641.574.574,0,0,1-.072,0Zm-2.724-1.2a1.756,1.756,0,0,0,1.747,1.763h.013a1.754,1.754,0,0,0,1.763-1.743v-.016a1.746,1.746,0,0,0-1.742-1.75h-.021a1.749,1.749,0,0,0-1.761,1.735v.011Zm-.34,0a2.086,2.086,0,0,1,2.072-2.1h.028A2.087,2.087,0,0,1,105,31.316v.031a2.088,2.088,0,0,1-2.076,2.1h-.029a2.089,2.089,0,0,1-2.1-2.076Z"
          transform="translate(899 -355)" fill="#005984" />
      </g>
    </g>
  </svg>
</button>

<div class="separator" *ngIf="!showBack" [class.active]="active"></div>

<h1 *ngIf="searching" class="title">Search</h1>

<button *ngIf="!searching" [attr.aria-label]="title" class="title" [class.active]="active" [disabled]="!active"
  (click)="titleClicked()">{{loadingFormsPage ? '' : title}}</button>

<span *ngIf="!searching" [style.flex]="'1 0 auto'"></span>

<!-- <button aria-label="Logout" *ngIf="active && !saving" class="logout navbar-end" (click)="logout()">Logout</button> -->

<button *ngIf="showSearchIcon" class="search" routerLink="search">
  <img class="search-icon" src="../../../../assets/icons/search.svg">
</button>

<button *ngIf="showPrintIcon" class="print" (click)="print()">
  Print <img class="print-icon" src="../../../../assets/icons/printer.svg">
</button>

<p *ngIf="saving" class="saving navbar-end">Saving...</p>