import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeInputFormElement } from '../../models/DeTemplateForms';
import { FormElementComponent } from '../form-element.component';

@Component({
  selector: 'de-form-text-input',
  templateUrl: './de-form-text-input.component.html',
  styleUrls: ['./de-form-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeFormTextInputComponent
    }
  ]
})
export class DeFormTextInputComponent extends FormElementComponent<string> implements OnInit, OnChanges, ControlValueAccessor {

  results: string[];

  @Input() formElement: DeInputFormElement;
  @Input() showBackground = false;
  @Input() grayBackground = false;
  @Input() showBorder = false;
  @Input() showUnderline = true;
  @Input() min: number | string;
  @Input() max: number | string;
  @Input() readOnly: boolean;
  @Input() invalid: boolean;
  /**
   * Optional map function to call on value before valueChanges is called
   */
  @Input() preValidationMapper: (val: string) => string;
  @HostBinding('class.showBackground') get _showBackground() {
    return this.showBackground;
  }
  @HostBinding('class.grayBackground') get _grayBackground() {
    return this.grayBackground;
  }
  @HostBinding('class.showBorder') get _showBorder() {
    return this.showBorder;
  }
  @HostBinding('class.focused') get focused() {
    return this._focused;
  }
  @HostBinding('class.error') get hasError() {
    return (this._touched || this.formElement.touch || (this.formElement.touchIfHasValue && this._hasValue))
      && ((!this._hasValue && this.required) || this.regexValidationFailed);
  }
  @HostBinding('class.invalid') get _invalid() {
    return this.invalid;
  }
  @HostBinding('class.regexValidationFailed') get regexValidationFailed() {
    return !this._matchesRegexValidation;
  }
  @HostBinding('class.required') get required() {
    return this.isRequired(this.formElement);
  }
  @HostBinding('class.hasValue') get hasValue() {
    return this._hasValue;
  }
  @HostBinding('class.isTimeInput') get isTimeInput() {
    return this.type === 'time' || this.type === 'date';
  }

  @HostListener('click', ['$event.target'])
  onClick(textInput: HTMLElement) {
    if (textInput.lastChild instanceof HTMLInputElement) {
      textInput.lastChild.focus();
    }
  }

  @Output() inputFocused = new EventEmitter();
  @Output() inputBlurred = new EventEmitter();

  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('resultsList') resultsList: ElementRef<HTMLUListElement>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // this.type = this.formElement?.input_type;
    if (!this.preValidationMapper && this.formElement.preValidationMapper) {
      switch (this.formElement.preValidationMapper) {
        case 'phone':
          this.preValidationMapper = (rawInput: string) => {
            let input = rawInput?.replace(/\D/g, '').trim();
            input = input?.length > 10 ? input.slice(input.length - 10, input.length) : input;
            if (!input || input.length < 1) {
              return ``;
            } else if (input.length < 4) {
              return input;
            } else if (input.length < 7) {
              return `${input.slice(0, 3)}-${input.slice(3, input.length)}`;
            } else {
              return `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
            }
          }
          break;
      }
    }
  }

  ngOnChanges() {
    this.results = (this.formElement.filterResults && this._value?.length > 0)
      ? this.formElement.results?.filter(r => r?.toLowerCase().includes(this._value.toLowerCase()))
      : this.formElement.results;
  }

  get id() {
    return this.formElement?.element_id;
  }

  get label() {
    return this.formElement?.field_display ?? '';
  }

  get placeholder() {
    return this.formElement?.placeholder ?? '';
  }

  get noResultMessage() {
    return this.resultsHeader?.length > 0
      ? `No ${this.resultsHeader}`
      : (this.formElement?.no_results_message ?? 'No Results');
  }

  get resultsHeader() {
    return this.formElement?.results_header;
  }

  get autocomplete() {
    return this.formElement?.autocomplete ?? '';
  }

  get character_limit() {
    return this.formElement?.validators?.character_limit ?? undefined;
  }

  get type() {
    return (!this.formElement?.type || this.formElement.type === 'text') ? this.autocomplete : this.formElement.type;
  }

  get extended() {
    return this.formElement?.validators?.character_limit >= 500;
  }

  get link() {
    return this.formElement?.link;
  }

  get linkText() {
    return this.formElement?.link_text;
  }

  get linkVerticalPosition() {
    return this.formElement?.link_vertical_position ?? 'top';
  }

  get linkHorizontalPosition() {
    return this.formElement?.link_horizontal_position ?? 'right';
  }

  get hideCharacterCount() {
    return this.formElement?.hideCharacterCount;
  }

  get minVal() {
    return this.min ?? '';
  }

  get maxVal() {
    return this.max ?? '';
  }

  get showResultsDropdown() {
    return this.results && !(this.formElement.hideResultsWhenTyping && this._value?.length > 0)
  }

  focus() {
    super.focus();
    this.inputFocused.emit();
    // If text input has a dropdown list, scroll the field into view
    if (this.results) {
      setTimeout(() => {
        (this.resultsList?.nativeElement?.firstElementChild as HTMLLIElement)?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }

  blurInput(event: FocusEvent) {
    // Only blur if there is NOT a result list at all
    // OR if the related target (being focused) is NOT the button nor li element being selected in the list of results
    if (!(this.results?.length > 0)
      || !(event.relatedTarget instanceof HTMLButtonElement || event.relatedTarget instanceof HTMLLIElement)) {
      this.blur();
    }
  }

  blur() {
    // Added to prevent required fields from being just spaces and no characters
    if (this.formElement.validators?.required && this._value !== this._value?.trim()) {
      this.valueChange(this._value?.trim());
    }
    super.blur();
    this.inputBlurred.emit();
  }

  selectResult(result: string) {
    if (this.formElement.resultCallback) {
      this.formElement.resultCallback(result);
      this.blur();
    } else {
      this.valueChange(result)
      this.blur();
    }
  }

  valueChange(val: string) {
    const el = this.inputElement?.nativeElement;
    if (this.preValidationMapper) {
      val = this.preValidationMapper(val);
      if (el) {
        el.value = val;
      }
    }
    if (el && this.max && val > this.max) {
      el.value = `${this.max}`;
      el.blur();
      setTimeout(() => super.valueChange(`${this.max}`), 50);
    } else if (el && this.min && val < this.min) {
      el.value = `${this.min}`;
      el.blur();
      setTimeout(() => super.valueChange(`${this.min}`), 50);
    } else if (val?.length > 0 && this.formElement?.validators?.regular_expression?.length > 0) {
      const regex = new RegExp(this.formElement?.validators.regular_expression);
      super.valueChange(val, !!val.match(regex));
    } else {
      super.valueChange(val);
    }
    this.ngOnChanges();
  }
}
