import { NgModule } from '@angular/core';
import { DeFormTextInputComponent } from './form-elements/de-form-text-input/de-form-text-input.component';
import { DeTemplateFormComponent } from './de-template-form/de-template-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeTemplateFormComponentComponent } from './form-elements/de-template-form-component/de-template-form-component.component';
import { CommonModule } from '@angular/common';
import { DeCheckboxSectionHeaderComponent } from './form-elements/de-checkbox-section-header/de-checkbox-section-header.component';
import { DeRadioComponent } from './form-elements/de-radio/de-radio.component';
import { DeSelectComponent } from './form-elements/de-select/de-select.component';
import { DeAddRemoveSectionComponent } from './form-elements/de-add-remove-section/de-add-remove-section.component';
import { DeSignaturePanelComponent } from './form-elements/de-signature-panel/de-signature-panel.component';
import { RouterModule } from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';


@NgModule({
  declarations: [
    DeFormTextInputComponent,
    DeTemplateFormComponent,
    DeTemplateFormComponentComponent,
    DeCheckboxSectionHeaderComponent,
    DeRadioComponent,
    DeSelectComponent,
    DeAddRemoveSectionComponent,
    DeSignaturePanelComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    ScrollingModule
  ],
  exports: [
    DeTemplateFormComponent,
    DeFormTextInputComponent,
    DeRadioComponent,
    DeSelectComponent,
    DeSignaturePanelComponent,
    DeCheckboxSectionHeaderComponent,
    DeCheckboxSectionHeaderComponent,
    DeAddRemoveSectionComponent
  ]
})
export class DeTemplateFormsModule { }
