import { Component, EventEmitter, Input, OnDestroy, HostBinding, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { fadeInAnimation } from '../../animations/animations';
import { DeComponent, DeFormElement, DeFormComponentType, DeSection, DeFormElementDependency, DeWarningBanner } from '../../models/DeTemplateForms';
import { dependenciesAreMet, DeTemplateFormsService, generateFormControl } from '../../services/de-template-forms.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'de-template-form-component',
  templateUrl: './de-template-form-component.component.html',
  styleUrls: ['./de-template-form-component.component.scss'],
  animations: [fadeInAnimation]
})
export class DeTemplateFormComponentComponent implements OnInit {

  /**
   * Optional event logger
   */
  @Input() logEvent: (eventName: string, eventParams?: any) => {};

  @Input() formGroupRef: UntypedFormGroup;

  @Input() component: DeComponent;

  @Input() componentIsReadOnly: boolean;
  disabled: boolean;

  @HostBinding('class.setMaxWidth') get setMaxWidth() {
    return this.isFormElement;
  }
  @HostBinding('class.sticky') get sticky() {
    return this.isFormElement && this.formElement.sticky;
  }
  @HostBinding('class.popup') get popup() {
    return this.expandedSection && this.section.popup;
  }
  @HostBinding('class.popupButtonHasError') get popupButtonHasError() {
    return this.collapsedSection
      && this.section.popup
      && this.displayComponent
      && !this.sectionComplete;
  }
  @HostBinding('class.popupButtonIsRequired') get popupButtonIsRequired() {
    return this.popupButtonHasError && !this.sectionTouched;
  }
  @HostBinding('class.popupButton') get popupButton() {
    return !this.expandedSection && this.section.popup;
  }
  @HostBinding('class.hidden') get hidden() {
    return !this.expandedSection && this.section.hidden;
  }
  @HostBinding('class.fullPageVisible') get fullPageVisible() {
    return this.expandedSection && this.section.full_page;
  }
  @HostBinding('class.reduceFieldsHeight') get reduceFieldsHeight() {
    return this.formService.isMobileSafariUserAgent && !this.formService.isStandalonePWA;
  }
  @HostBinding('class.isSection') get isSection() {
    return this.component.component_type === DeFormComponentType.Section;
  }
  @HostBinding('class.isFullPagePrint') get isFullPagePrint() {
    return this.section.full_page_print;
  }

  _showLegend = true;

  displayComponent: boolean;
  disableFormElement: boolean;

  min: number | string;
  max: number | string;

  /**
   * Subject passed by the parent form that will emit when
   * the parent form component is destroyed
   */
  @Input() destroySubject = new Subject<void>();

  @Output() change = new EventEmitter<any>();
  @Output() lastFieldCompleted = new EventEmitter();
  @Output() selectAll = new EventEmitter();
  @Output() sectionExpanded = new EventEmitter<DeSection>();

  private readonly _destroying$ = new Subject<void>();
  review: boolean = false;
  group_uuid: string;
  form_uuid: string;

  constructor(
    private formService: DeTemplateFormsService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }
  
  ngOnInit(): void {
    this.review = this.location.path().includes('final-review') ? true : false;
    this.activatedRoute.paramMap
      .pipe(takeUntil(this._destroying$), take(1))
      .subscribe(
        async params => {
          this.group_uuid = params.get('group_uuid');
          this.form_uuid = params.get('form_uuid');
        });
    // Only show legend if this is a section with form elements or accordions that are direct children
    this._showLegend = !!(
      this.isSection
      && this.section.field_display?.length > 0
      && this.section.components?.find(
        c => c.component_type !== DeFormComponentType.Section
          || (c.component_type === DeFormComponentType.Section && ((c as DeSection).accordion || (c as DeSection).popup))
      )
    );

    this.updateDependencies();

    this.formGroupRef.valueChanges
      .pipe(
        // Only listen for changes while the parent form is visible
        takeUntil(this.destroySubject)
      )
      .subscribe(
        _ => {
          this.updateDependencies();
        }
      );
  }

  get isFormElement() {
    return !this.isSection && !this.isWarningBanner;
  }

  get readOnly() {
    return this.componentIsReadOnly || this.component.read_only;
  }

  get isWarningBanner() {
    return this.component.component_type === DeFormComponentType.WarningBanner;
  }

  get section() {
    return this.component as DeSection;
  }

  get formElement() {
    return this.component as DeFormElement;
  }

  get sectionComplete() {
    return this.section?.form_group?.valid;
  }

  get sectionTouched() {
    return this.section?.form_group?.touched;
  }

  get alwaysWarnMissingFields() {
    return this.section?.alwaysWarnMissingFields;
  }

  get warningBanner() {
    return this.component as DeWarningBanner;
  }

  get expandedSection() {
    return this.isSection && (!(this.section.full_page || this.section.accordion) || this.section.expanded);
  }

  get collapsedSection() {
    return this.isSection && (this.section.full_page || this.section.accordion) && !this.section.expanded;
  }

  get anySectionIsExpanded() {
    return this.formService.anySectionIsExpanded;
  }

  get isAccordion() {
    return this.isSection && this.section.accordion;
  }

  get summaryView() {
    return this.section.summary_view;
  }

  get visible() {
    return !this.isSection || this.section.visible;
  }

  get showLegend() {
    return this._showLegend && !this.isAccordion;
  }

  get sectionDisabled() {
    return this.isSection
      && (
        this.section.disabled
        || !(this.section.components?.length > 0)
      );
  }

  get fieldsBorder() {
    return this.section.fieldsBorder ?? 'none';
  }

  get fieldsBackground() {
    return this.section.fieldsBackground ?? 'white';
  }

  get fieldsPadding() {
    return this.section.fieldsPadding ?? 'auto';
  }

  get showAdditionalActionButton() {
    return this.collapsedSection
      && this.section.popup
      && this.displayComponent
      && !(this.readOnly || this.disabled)
      && this.secondaryActionText?.length > 0;
  }

  get secondaryActionText() {
    return this.section?.secondary_action_text;
  }

  get secondaryActionEnabled() {
    return this.section?.secondary_action_enabled;
  }

  secondaryAction() {
    this.section?.secondary_action_callback?.();
  }

  updateDependencies() {
    this.displayComponent = dependenciesAreMet(this.formGroupRef, this.component.depends_on?.filter(d =>
      !d.disable_only
      // Ignore dependencies for components within Add/Remove sections
      && !/_\d$/i.test(d.dependency_field_name))
    ) && !this.component.hide;

    if (this.isSection) {
      /**
       * Used to track the values being referenced when generating new form controls,
       * allowing us to prevent duplicate form controls within the same group
       */
      const forEachNames = [];
      this.section.components
        ?.forEach((c1, c1_index) => {
          // If forEachOf is set for any of this section's components, we will create a
          // component for each value in the referenced element (forEachOf is a string referencing another
          // field's name/id)
          if (c1.forEachOf?.length > 0 && this.formGroupRef.get(c1.forEachOf)?.value) {
            // console.log('The following component has forEachOf set:')
            // console.log(c1)
            const forEachRef = this.formGroupRef.get(c1.forEachOf) as UntypedFormControl;
            // console.log('...whose form control is:')
            // console.log(forEachRef)
            const refValue = this.formGroupRef.get(c1.forEachOf).value;
            // console.log('...and whose reference value is:')
            // console.log(refValue)
            const list = Array.isArray(refValue) ? refValue : [refValue];
            forEachNames.push(...(list ?? []));
            // console.log('The refValue as a list is:')
            // console.log(list)
            const parentGroup = ((c1 as DeFormElement).form_control ?? (c1 as DeSection).form_group)?.parent as UntypedFormGroup;
            // console.log('The component\'s parent group is:')
            // console.log(parentGroup)
            const controlName = Object.keys(parentGroup?.controls ?? [])
              .find(co => parentGroup.controls[co] === ((c1 as DeFormElement).form_control ?? (c1 as DeSection).form_group));
            // console.log('The component\'s control name is:')
            // console.log(controlName)

            list
              // Maps the list of reference values to a list of strings
              .map(val => {
                if (typeof val === 'object') {
                  const keys = Object.keys(val);
                  if (keys?.length > 1) {
                    // Try to find a key that matches the object name, or default to the first value
                    // in the object
                    const keyMatchingControlName = keys.find(k => controlName.includes(k));
                    return keyMatchingControlName ? val[keyMatchingControlName] : val[keys[0]];
                  } else if (keys?.length === 1 && val[keys[0]]) {
                    // If there is only a single nested object within the object...
                    if (typeof val[keys[0]] === 'object') {
                      const innerKeys = Object.keys(val[keys[0]]);
                      if (innerKeys?.length > 0) {
                        // Try to find a key at the next level that matches the object name
                        const keyMatchingControlName = innerKeys.find(k => controlName.includes(k));
                        return keyMatchingControlName ? val[keys[0]][keyMatchingControlName] : val[keys[0]][innerKeys[0]];
                      }
                    } else if (typeof val[keys[0]] === 'string') {
                      return val[keys[0]];
                    }
                  }
                }
                return val;
              })
              // Only include defined strings
              .filter(val => val && typeof val === 'string')
              // Add ' (1)', ' (2)', etc. to the end of duplicates
              .reduce((set, val) => {
                const occurencesOfVal = set.filter(str => str === val).length;
                return occurencesOfVal > 0 ? [...set, `${val} (${occurencesOfVal + 1})`] : [...set, val]
              }, [])
              // For each value, we want to copy the original element (c1)
              .forEach((refVal, refValIndex) => {
                // console.log(`Creating a copied component for ref value: ${refVal}`)

                // Find previously-used for each names
                const numberOfOtherInstances = forEachNames
                  .filter(previousField => typeof previousField === 'string'
                    && previousField.toLowerCase() === refVal.toLowerCase())
                  .length - 1;
                // Determine the instance # of this form control within its form group
                // by looking at the '(1)' suffix and the previously-used for each names
                let instance = parseInt(refVal.match(/\(\d+\)$/)?.[0]?.replace(/\(|\)/g, '') ?? '0', 10)
                  + (numberOfOtherInstances < 0 ? 0 : numberOfOtherInstances);

                // This will be the field/data name of the copied element
                const field_name = instance > 0
                  ? refVal.replace(/ /g, '_').replace(/_\(\d+\)$|$/, `_${instance}`)
                  : refVal.replace(/ /g, '_');

                // Add the new field name to the end of the element's id
                const element_id = (c1 as DeFormElement).element_id
                  ? `${(c1 as DeFormElement).element_id}.${field_name}`
                  : undefined;
                // Don't include instance in display name
                const displayName = refVal.replace(/ \(\d+\)$/, '');
                // Left untyped so we can set fields whether this is a section or form element
                const newElement: DeComponent | any = {
                  ...c1,
                  form_control: undefined,
                  form_group: undefined,
                  element_id,
                  field_name,
                  field_display: displayName,
                  placeholder: displayName,
                  // Removed so the new element won't be duplicated
                  forEachOf: undefined,
                  forEachRef,
                  popup_primary_data: displayName,
                  // Make a deeper copy of the components belonging to this section (if this is a section)
                  components: (c1 as DeSection).components
                    ? (c1 as DeSection).components?.map(c => ({ ...c }))
                    : undefined
                };
                // console.log('Copy of original element:')
                // console.log(newElement)
                if ((c1 as DeFormElement).form_control) {
                  // Generate a new form control for the form element
                  newElement.form_control = generateFormControl(newElement, this.formGroupRef);
                  // console.log(`Setting new element ${field_name} form control to`)
                  // console.log(newElement.form_control)
                  // Set the control from the parent group to be the new control
                  parentGroup.setControl(field_name, newElement.form_control as UntypedFormControl, { emitEvent: false });
                } else {
                  // Generate a new form group for the section
                  // console.log('Generating a new form group with the following components:')
                  // console.log((newElement as DeSection).components)
                  newElement.form_group = this.formService.generateFormGroup((newElement as DeSection).components, `${field_name}.`)
                  // console.log(`Setting new section ${field_name} form group to`)
                  // console.log(newElement.form_group)
                  // Set the control from the parent group to be the new control
                  parentGroup.setControl(field_name, newElement.form_group as UntypedFormGroup, { emitEvent: false });
                }
                // Add newly-created component to the section
                this.section.components.splice(c1_index + 1 + refValIndex, 0, newElement);
              });

            // Remove old component from section
            this.section.components.splice(c1_index, 1);
            // Remove old component from parent group control
            parentGroup.removeControl(controlName, { emitEvent: true });

            // console.log('The updated section looks like:')
            // console.log(this.section)
          }
        });

      // Section is completed if updated form group is valid
      // this.section.complete = this.section.form_group?.valid;
    }
    if (this.isFormElement) {
      this.disableFormElement = this.readOnly
        || this.formElement.disabled
        || !dependenciesAreMet(this.formGroupRef, this.formElement.depends_on?.filter(d => d.disable_only));

      if (`${this.formElement.validators?.minimum_value ?? ''}`.length > 0) {
        this.min = this.formElement.validators.minimum_value;
      }

      if (`${this.formElement.validators?.maximum_value ?? ''}`.length > 0) {
        this.max = this.formElement.validators.maximum_value;
      }

      if (this.formElement.validators?.greater_than_field?.length > 0) {
        this.min = this.formGroupRef.get(this.formElement.validators.greater_than_field)?.value;
      }

      if (this.formElement.validators?.less_than_field?.length > 0) {
        this.max = this.formGroupRef.get(this.formElement.validators.less_than_field)?.value;
      }

      if (this.formElement.form_control) {
        // Manually disable/enable form control as it seems there is an open Angular issue with the
        // disabled attribute triggering setDisabledState() on the control value accessor instance
        if (this.disableFormElement || !this.displayComponent) {
          if (!this.readOnly) {
            // Only disable in the non-readonly state, as disabling will override the VALID
            // form control status that indicates a section/form group is complete
            this.formElement.form_control.disable({ onlySelf: true, emitEvent: false });
          }
          this.disabled = true;
        } else {
          this.formElement.form_control.enable({ onlySelf: true, emitEvent: false });
          this.disabled = false;
        }
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  expandSection() {
    if (this.summaryView) {
      return;
    }
    if (this.logEvent && this.section?.field_name?.length > 0) {
      this.logEvent(`open section ${this.section.field_name}`);
    }
    if (!location.href.includes('participant_list')) this.formService._isExpanded$.next({expanded: false});
    this.formService.openSection(this.section);
    this.sectionExpanded.emit(this.section);
  }

  toggleAccordion() {
    if (this.isAccordion) {
      if (this.logEvent && this.section?.field_name?.length > 0) {
        this.logEvent(`${this.section.expanded ? 'collapse' : 'expand'} accordion ${this.section.field_name}`);
      }
      this.section.expanded = !this.section.expanded;
    }
  }

  /**
   * Used to open the next accordion when the final radio element (regardless of position)
   * in an accordion is selected AND all other enabled fields in that accordion are valid
   *
   * @param component DeComponent
   */
  handleRadioComponentChange(component: DeComponent) {
    if (this.isAccordion && component.component_type === 'radio') {
      const allEnabledFormControlsInSectionAreValid = !this.section.components.some(
        c => (c as DeFormElement).form_control?.invalid
          || (
            // True if component has no value and depends on the toggled component.
            // This will be the case if there is a field dependent on a radio button matching a certain value.
            !(c as DeFormElement).form_control?.value
            && (c as DeFormElement).depends_on?.some(
              co => co.dependency_field_name === (component as DeFormElement).element_id
                && (
                  (co.matches_any_of?.length > 0 && co.matches_any_of.includes((component as DeFormElement).form_control?.value))
                  || (co.matches_none_of?.length > 0 && !co.matches_none_of.includes((component as DeFormElement).form_control?.value))
                )
            )
          )
      );
      if (allEnabledFormControlsInSectionAreValid) {
        this.lastFieldCompleted.emit();
      }
    }
  }

  openNextAccordion(component: DeSection, index: number) {
    if (component.accordion) {
      // Uncomment the following code if business decides to add back the accordion auto-collapse functionality
      // component.expanded = false;
      const nextAccordion = this.section.components.find((c, i) => (c as DeSection).accordion && i > index);
      if (nextAccordion) {
        (nextAccordion as DeSection).expanded = true;
      }
    }
  }

  selectAllCheckboxes() {
    if (this.isSection) {
      const checkboxes: DeFormElement[] = this.section.components?.filter(c => c.component_type === 'radio') ?? [];
      const value = checkboxes.some(c => c.form_control?.value !== 'Checked')
        ? 'Checked'
        : null;
      checkboxes.forEach((c, i, arr) => c.form_control?.setValue(value, { emitEvent: false }));
    }
    this.formGroupRef.updateValueAndValidity();
  }

  warningBannerClick() {
    if (this.isWarningBanner) {
      this.warningBanner?.clickCallback?.();
    }
  }

  valueChange(val: any) {
    this.change.emit(val);
  }
}
