<label #labelElement (click)="labelClicked()">
  {{label}}
</label>
<div>
  <ng-container *ngIf="!showOnlyAnswer">
    <div *ngFor="let option of options" class="option-active">
      <input *ngIf="option?.length > 0" value="{{option}}" [disabled]="readOnly || _disabled" type="radio"
        [checked]="option === _value" (focus)="focus()" (blur)="blur()" (click)="valueChange(option, $event)"
        [name]="name">
      <button [attr.aria-label]="option" *ngIf="option?.length > 0" class="circle" [disabled]="readOnly || _disabled"
        (focus)="focus()" (blur)="blur()" (click)="valueChange(option, $event)" type="button"
        [style.opacity]="_disabled ? '0.6' : '1'">
        <div *ngIf="option === _value" class="check">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1.3rem"
            height="1.3rem" viewBox="0 0 76 76">
            <defs>
              <path id="icon-positive-check-a"
                d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
            </defs>
            <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
          </svg>
        </div>
      </button>
    </div>
  </ng-container>
  <div *ngIf="showOnlyAnswer" class="option-inactive" [class.YES]="_value === 'Yes'" [class.NO]="_value === 'No'">
    {{_value || 'N/A'}}
  </div>
</div>
<a *ngIf="link?.length > 0" href="{{link}}" target="_blank" class="{{linkVerticalPosition}} {{linkHorizontalPosition}}"
  [class.showOnlyAnswer]="showOnlyAnswer">{{linkText}}</a>