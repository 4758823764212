import { Injectable } from '@angular/core';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class DynatraceService {

  constructor(
    private userService: UserService
  ) { }

  logAction(action: string) {
    // TODO: Eventually cache when offline, then sync events when online
    if (action?.length > 0 && window.dtrum?.enterAction && window.dtrum?.leaveAction && navigator.onLine) {
      const actionId = window.dtrum.enterAction(action);
      window.dtrum.leaveAction(actionId);
    }
  }

  reportError(error: string | Error) {
    // TODO: Eventually cache when offline, then sync error events when online
    if (error && window.dtrum?.reportError && navigator.onLine) {
      window.dtrum.reportError(error);
    }
  }

  /**
   * Capture user info for Dynatrace tagging
   */
  tagDynatraceUser() {
    // TODO: Eventually identify user when online again
    if (window.dtrum?.identifyUser && this.userService.email?.length > 0 && navigator.onLine) {
      window.dtrum.identifyUser(this.userService.email);
    }
  }
}
