<!-- <h2 *ngIf="title?.length > 0">{{title}}</h2> -->

<ul>
  <li *ngFor="let confirm of confirms">{{confirm}}</li>
</ul>

<div class="text-signature" *ngIf="!showSignaturePanel">
  <label for="{{id}}" [class.focused]="_focused" [class.hasValue]="_hasValue">{{label}}</label>
  <input id="{{id}}_input" [disabled]="readOnly || _disabled" [ngModel]="_value" (ngModelChange)="valueChange($event)"
    [placeholder]="_focused ? '' : placeholder" autocomplete="off" [class.hasValue]="_hasValue" (focus)="focus()"
    (blur)="blur()" [required]="required" [class.error]="hasError" [maxlength]="200">
</div>

<ng-container *ngIf="showSignaturePanel">
  <canvas id="{{id}}_canvas"></canvas>
  <button *ngIf="!(readOnly || _disabled)" class="text-button clear-button"
    (click)="clear(); log('clear signature pad')" type="button">Clear</button>
</ng-container>

<div class="toggle-buttons">
  <button *ngIf="!(readOnly || _disabled)" class="filled-button" [disabled]="showSignaturePanel"
    (click)="clear(); setSignaturePanelMethod(true); log('set pad input for signature')" type="button">E Sign</button>
  <button *ngIf="!(readOnly || _disabled)" class="filled-button" [disabled]="!showSignaturePanel"
    (click)="clear(); setSignaturePanelMethod(false); log('set keyboard input for signature')"
    type="button">Keyboard</button>
</div>