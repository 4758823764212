export interface NearestMedicalFacility {
  facility_name: string;
  street_address: string;
  city: string;
  state: string;
  zip: string;
  distance_in_miles: number;
}

/**
 * Mapping of fleet garages to their addresses in the 'Street, City, State Code, Zip Code' format
 *
 * Links to this data source:
 *
 * Carolinas: https://dukeenergy.sharepoint.com/:w:/r/sites/portal_fleet-services/_layouts/15/doc2.aspx?sourcedoc=%7BD308F15D-FDD4-44C8-A8B0-A700BC02D96B%7D&file=Carolina%20Garages.docx&action=default&mobileredirect=true&cid=646873c9-a552-43d5-b775-7c986ecf5b03&ovuser=2ede383a-7e1f-4357-a846-85886b2c0c4d%2CJames.Randolph%40duke-energy.com&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzAxMTEwNTYwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D
 * 
 * Florida: https://dukeenergy.sharepoint.com/:w:/r/sites/portal_fleet-services/_layouts/15/Doc.aspx?sourcedoc=%7B11BE9815-8B92-41AB-845A-0C9BB16E02E6%7D&file=Florida%20Garages.docx&action=default&mobileredirect=true&cid=c93d7477-3793-442e-84fa-afad63ae99cc&ovuser=2ede383a-7e1f-4357-a846-85886b2c0c4d%2CJames.Randolph%40duke-energy.com&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzAxMTEwNTYwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D
 * 
 * Midwest: https://dukeenergy.sharepoint.com/:w:/r/sites/portal_fleet-services/Shared%20Documents/Midwest%20Garages.docx?d=w4643d7d28df2435e8068513d26a28e5b&csf=1&web=1
 */
export const FLEET_GARAGES_TO_ADDRESSES = {
  // North Carolina
  'Practice Garage': '1115 N. Brevard St., Charlotte, NC, 28206',
  'Aberdeen': '1410 McNeill Way, Aberdeen, NC, 28315',
  'Asheville': '5E Oakview Road, Asheville, NC, 28806',
  'Franklin': '271 NP&L Loop, Franklin, NC, 28734',
  'Little Rock': '6301 Wilkinson Blvd., Charlotte, NC, 28214',
  'Toddville': '610 Toddville Road, Charlotte, NC, 28214',
  'Durham': '4412 Hillsborough Rd, Durham, NC, 27705',
  'Garner': '1422 Mechanical Blvd, Garner, NC, 27529',
  'Greensboro Fairfax': '2500 Fairfax Rd, Greensboro, NC, 27407',
  'Hickory': '250 9Th St. Ln. SE, Hickory, NC, 28601',
  'McGuire': '13520 Highway 73, Huntersville, NC, 28078',
  'Rural Hall': '8700 Circle Drive, Rural Hall, NC, 27045',
  'Salisbury': '700 E Ritchie Rd., Salisbury, NC, 28146',
  'Wilmington': '5712 Holly Shelter Rd, Castle Hayne, NC, 28429',

  // South Carolina
  'Anderson': '1636 Pearman Dairy Rd, Anderson, SC, 29625',
  'Catawba': '4800 Concord Road, York, SC, 29745',
  'Florence': '1601-B West Lucas St, Florence, SC, 29501',
  'Greenville/Wenwood': '425 Fairforest Way, Greenville, SC, 29607',
  'Oconee': '7800 Rochester Hwy, Seneca, SC, 29678',
  'Spartanburg': '580 Mt. Pleasant Road, Spartanburg, SC, 29307',

  // Florida (North Coastal)
  'Crystal River': '15760 W. Power Line St., Crystal River, FL, 34428',
  'Inverness': '515 Independence Hwy., Inverness, FL, 34453',
  'Madison': '1450 E. US 90, Madison, FL, 32340',
  'Monticello': '420 Quail Trail, Monticello, FL, 32344',
  'Ocala': '4361 SE Maricamp Rd., Ocala, FL, 34471',
  'Crawfordville (Travelers)': '537 Arran Rd SR 368, Crawfordville, FL, 32326',
  'Odena': '10011 E. Hwy 98 Port St., Joe, FL, 32456',
  'Trenton': '1532 E. Wade St., Trenton, FL, 32693',

  // Florida (South Coastal)
  'Clearwater': '2166 Palmetto St., Clearwater, FL, 33765',
  'Seven Springs': '4121 Saint Lawrence Dr., New Port Richey, FL, 24653',
  'St. Petersburg': '2455 25th Street, St. Petersburg, FL, 33713',
  'Walsingham': '12600 Walsingham Rd., Largo, FL, 33773',

  // Florida (South Central)
  'Highlands/Sebring': '5020 Kenilworth Blvd., Sebring, FL, 33870',
  'Winter Garden': '452 E. Crown Point Rd., Winter Garden, FL, 34787',
  'Lake Wales': '2041 Old Scenic Hwy., Lake Wales, FL, 33898',
  'Buena Vista': '3250 Bonnet Creek Rd., Lake Buena Vista, FL, 32830',
  'SE Orlando': '8407 Boggy Creek Rd., Orlando, FL, 32824',

  // Florida (North Central)
  'Apopka': '275 Ponkan Rd., Apopka, FL, 32703',
  'Jamestown': '2801 W. State Rd. 426, Oviedo, FL, 32765',
  'Longwood': '150 Progress Energy Way, Longwood, FL, 32750',
  'Deland': '400 N. Spring Garden Ave., Deland, FL, 32720',

  // Florida (Wildwood Locations)
  'Wildwood': '4306 E. County Rd. 462, Wildwood, FL, 34785',
  'System Travelers': null,

  // Ohio
  'Brecon': '11777 Solzman Rd., Cincinnati, OH, 45249',
  'Dana': '2010 Dana Ave, Cincinnati, OH, 45207',
  'Fairfield': '1199 Niles Road, Fairfield, OH, 45014',
  'Hamlet': '3443 St. Rt. 132, Hamlet, OH, 45102',
  'Little Miami': '1099 St. Rt. 28, Milford, OH, 45150',
  'Montfort Heights': '5445 Audro Drive, Cincinnati, OH, 45239',
  'Queensgate': '424 Gest Street, Cincinnati, OH, 45203',
  'Todhunter': '593 Todhunter Road, Monroe, OH, 45050',

  // Kentucky
  'Erlanger': '1262 Cox Avenue, Erlanger, KY, 41018',

  // Indiana
  'Fleet Equipment Specialist': '1000 E. Main St., Plainfield, IN, 46168',
  'Fleet Technical Specialist': '2727 Central Ave, Columbus, IN, 47201',
  'Plainfield': '1000 E. Main Street, Plainfield, IN, 46168',
  'Bloomington': '1100 W. 2nd Street, Bloomington, IN, 47403',
  'Carmel': '16475 Southpark Dr., Westfield, IN, 46074',
  'Columbus': '2727 Central Avenue, Columbus, IN, 47201',
  'Clarksville': '1212 Eastern Blvd, Clarksville, IN, 47129',
  'Connersville': '200 E. Veterans Memorial, Connersville, IN, 47129',
  'Greensburg': '342 E. Washington St., Greensburg, IN, 47240',
  'Kokomo': '1619 W. Deffenbaugh, Kokomo, IN, 46902',
  'Lafayette': '3401 Greenbush St., Lafayette, IN, 47905',
  'Noblesville': '100 S. Mill Creek, Noblesville, IN, 46060',
  'Princeton': '3001 W. Broadway St., Princeton, IN, 47670',
  'Shelbyville': '2910 E. SR 44, Shelbyville, IN, 46176',
  'Terre Haute': '301 Home Avenue, Terre Haute, IN, 47803',
  'Vincennes': '800 Wheatland Road, Vincennes, IN, 47591'
};

export const FLEET_GARAGES = Object.keys(FLEET_GARAGES_TO_ADDRESSES)
  .map(
    k => `${k}${(FLEET_GARAGES_TO_ADDRESSES[k] && k !== 'Practice Garage')
      // Get state from address and append to end (e.g. 'Little Rock (NC)')
      ? ` (${FLEET_GARAGES_TO_ADDRESSES[k].split(',').slice(-2, -1).pop().trim()})`
      : ''}`
  )
  .sort();

export const REGIONS = [
  'CAR',
  'FL',
  'MW',
  'GPS'
]

export const OP_CENTERS = [
  "Anderson",
  "Apopka",
  "Asheboro",
  "Asheville",
  "Attica",
  "Aurora",
  "Avon",
  "Bedford",
  "Bloomfield",
  "Bloomington",
  "Brecon",
  "Brooksville",
  "Buena Vista",
  "Burlington",
  "Cape Fear",
  "Carmel",
  "Carrabelle/Crawfordville",
  "Chatham",
  "Cheraw",
  "Cherokee",
  "Clarksville",
  "Clearwater",
  "Clemson",
  "Clermont",
  "Clinton (IN)",
  "Clinton (NC)",
  "Colerain",
  "Columbus",
  "Connersville",
  "Corydon",
  "Crawfordville",
  "Dana",
  "Deland",
  "Duncan",
  "Dunnellon",
  "Durham",
  "Elkin",
  "Erlanger",
  "Eustis",
  "Fairfax",
  "Fairfield",
  "Florence",
  "Fort Mill",
  "Franklin (IN)",
  "Franklin (NC)",
  "Fuquay",
  "Gastonia",
  "Goldsboro",
  "Greencastle",
  "Greensboro",
  "Greensburg",
  "Greenville",
  "Greenwood",
  "Hamlet",
  "Hartsville",
  "Hartwell",
  "Haywood",
  "Henderson",
  "Hendersonville",
  "Hickory",
  "High Point",
  "High Springs/Trenton",
  "Highlands",
  "Huntington",
  "Inverness",
  "Jacksonville",
  "Jamestown",
  "Jasper/Madison (FL)",
  "Kannapolis",
  "Kernersville",
  "Kingstree",
  "Kinston",
  "Kokomo",
  "Lafayette",
  "Lake Wales",
  "Lancaster",
  "Lewisville",
  "Little Miami",
  "Little Rock",
  "Longwood",
  "Loogootee",
  "Madison (IN)",
  "Madison (NC)",
  "Marion (NC)",
  "Marion (SC)",
  "Martinsville",
  "Matthews",
  "Maxton",
  "Monticello/Perry",
  "Mooresville",
  "Morehead City",
  "Mt. Airy",
  "New Bern",
  "New Castle",
  "Newell",
  "Noblesville",
  "North Wilkesboro",
  "Ocala",
  "Odena",
  "Princeton",
  "Queensgate",
  "Raleigh",
  "Reidsville",
  "Robbinsville",
  "Rochester",
  "Rockingham",
  "Roxboro",
  "Rural Hall",
  "Salem",
  "SE Orlando",
  "Salisbury",
  "Sanford",
  "Seven Springs",
  "Seymour",
  "Shelby",
  "Shelbyville",
  "Simpsonville",
  "Southern Pines",
  "Spartanburg",
  "Spindale",
  "Spruce Pine",
  "St. Petersburg",
  "Sullivan",
  "Sumter",
  "Terre Haute",
  "Todhunter",
  "Travelers Rest",
  "Trenton",
  "Vincennes",
  "Wabash",
  "Walsingham",
  "Wenwood",
  "Whiteville",
  "Wildwood",
  "Wilmington North",
  "Wilmington Service",
  "Wilmington South",
  "Winter Garden",
  "York",
  "Zebulon",
  "Zephyrhills",
  // Training Op Centers
  "",
  "Brecon Training",
  "Clermont Training",
  "Dana UG Training",
  "New Hill Training",
  "Plainfield Training",
  "Toddville Training",
  "Winter Garden Training"
];

export const T_OP_CENTERS = [
  // OP Center's list, needs to be cleared out 90days after release
  "Aberdeen",
  "Anderson",
  "Apopka",
  "Asheboro",
  "Asheville",
  "Attica",
  "Aurora",
  "Avon",
  "Beckerdite Tie",
  "Bedford",
  "Bloomfield",
  "Bloomington",
  "Brecon",
  "Brooksville",
  "Buena Vista",
  "Burlington",
  "Cape Fear",
  "Carmel",
  "Carrabelle/Crawfordville",
  "Charlotte",
  "Chatham",
  "Cheraw",
  "Cherokee",
  "Clarksville",
  "Clearwater",
  "Clemson",
  "Clermont",
  "Clinton (IN)",
  "Clinton (NC)",
  "Colerain",
  "Columbus",
  "Connersville",
  "Corydon",
  "Crawfordville",
  "Crystal River",
  "Dana",
  "Debary",
  "Deland",
  "Duncan",
  "Dunnellon",
  "Durham",
  "Elkin",
  "Erlanger",
  "Eustis",
  "Fairfax",
  "Fairfield",
  "Florence",
  "Fort Mill",
  "Franklin (IN)",
  "Franklin (NC)",
  "Fuquay",
  "Garner",
  "Gastonia",
  "Gastonia Junction",
  "Goldsboro",
  "Great Falls",
  "Greencastle",
  "Greensboro",
  "Greensburg",
  "Greenville",
  "Greenwood",
  "Haines City",
  "Hamlet",
  "Hartsville",
  "Hartwell",
  "Haw River",
  "Haywood",
  "Henderson",
  "Hendersonville",
  "Hickory",
  "High Point",
  "High Springs/Trenton",
  "Highlands",
  "Hines Energy",
  "Huntington",
  "Inverness",
  "Jacksonville",
  "Jamestown",
  "Jasper/Madison (FL)",
  "Kannapolis",
  "Kernersville",
  "Kingstree",
  "Kinston",
  "Kokomo",
  "Lafayette",
  "Lake Wales",
  "Lakewood",
  "Lancaster",
  "Leland",
  "Lewisville",
  "Little Miami",
  "Little Rock",
  "Longwood",
  "Loogootee",
  "Madison (IN)",
  "Madison (NC)",
  "Maiden",
  "Marion (NC)",
  "Marion (SC)",
  "Martinsville",
  "Matthews",
  "Maxton",
  "Monticello/Perry",
  "Mooresville",
  "Morehead City",
  "Morning Star",
  "Mt. Airy",
  "New Bern",
  "New Castle",
  "Newell",
  "Newport",
  "Noblesville",
  "North Wilkesboro",
  "Ocala",
  "Odena",
  "Odessa",
  "Plainfield HQ",
  "Princeton",
  "Queensgate",
  "Raleigh",
  "Reidsville",
  "Robbinsville",
  "Rochester",
  "Rockingham",
  "Roxboro",
  "Rural Hall",
  "Salem",
  "SE Orlando",
  "Salisbury",
  "Sanford",
  "Seven Springs",
  "Seymour",
  "Shelby",
  "Shelbyville",
  "Simpsonville",
  "Southern Pines",
  "Spartanburg",
  "Spindale",
  "Spruce Pine",
  "St. Petersburg",
  "Sullivan",
  "Sumter",
  "Terre Haute",
  "Toddville",
  "Todhunter",
  "Travelers Rest",
  "Trenton",
  "Vincennes",
  "Wabash",
  "Walsingham",
  "Wenwood",
  "Whiteville",
  "Wilcox",
  "Wildwood",
  "Wilmington North",
  "Wilmington Service",
  "Wilmington South",
  "Winecoff Tie",
  "Winter Garden",
  "York",
  "Zebulon",
  "Zephyrhills",
  // Training Op Centers
  "",
  "Brecon Training",
  "Cary Training",
  "Clermont Training",
  "Inglis Training",
  "New Hill Training",
  "Plainfield Training",
  "Shelbyville Training",
  "Toddville Training",
  "Toddville Transmission",
  "Wildwood Training",
  "Winter Garden Training"
];

// Transmission Department's List
export const T_DPT_CENTERS = [
  "Relay - Aberdeen",
  "Apopka/Jamestown",
  "Brecon Heavy Equipment 2",
  "Electric Shop - Plainfield 1",
  "Electric Shop - Plainfield 2",
  "Line Construction-Travel 58 - FL",
  "Line Construction-Travel 70 - FL",
  "Line Construction-Travel 99 - FL",
  "Float Track Crew",
  "Relay - Florence",
  "Sub Construction-Travel 46 - FL",
  "Sub Construction-Travel 49 - FL",
  "Sub Construction-Travel 99 - FL",
  "Relay - Garner",
  "Gen Svcs Testing & Analysis - FL",
  "Relay - Goldsboro",
  "Heavy Hauling - Carolinas West",
  "Heavy Hauling - FL",
  "Relay - Leland",
  "Trans Line Construction - Wenwood",
  "Trans Line Maint - Crawfordville",
  "Trans Line Maint - Wilcox",
  "Trans Line Maint - Haines City",
  "Trans Line Maint - Odessa",
  "Trans Line Maint - Wildwood",
  "Nuclear Switchyard - Central",
  "Nuclear Switchyard - South",
  "Nuclear Switchyards - East",
  "Relay - Raleigh",
  "Relay - Blue Ridge",
  "Relay - Piedmont South",
  "Relay - Upstate SC",
  "Relay - BV/Lake Wales",
  "Relay - Monticello",
  "Relay - St Pete/Tarpon Spgs",
  "Relay - Wildwood",
  "Relay - Appalachia",
  "Relay - Foothills",
  "Relay - Piedmont",
  "Relay - Piedmont East",
  "Relay - Piedmont Triad",
  "Sub Construction  - Wenwood",
  "Sub Construction - Toddville",
  "Sub Construction - Erwin",
  "Sub Construction - Florence",
  "Sub Construction - Zebulon",
  "Sub Maint -  Nantahala",
  "Sub Maint -  Newport",
  "Sub Maint -  Spindale",
  "Sub Maint - Anderson",
  "Sub Maint - Central",
  "Sub Maint - Central -Apparatus",
  "Sub Maint - Charlotte",
  "Sub Maint - Durham/Burl",
  "Sub Maint - Gastonia",
  "Sub Maint - Greensboro",
  "Sub Maint - Greenville",
  "Sub Maint - Hickory",
  "Sub Maint - North",
  "Sub Maint - North - Apparatus",
  "Sub Maint - South",
  "Sub Maint - South - Apparatus",
  "Sub Maint - Spartanburg",
  "Sub Maint - Winecoff",
  "Sub Maint - Winston Salem",
  "Sub Maint - Aberdeen",
  "Sub Maint - Garner",
  "Sub Maint - Goldsboro/New Bern",
  "Sub Maint - Oxford/Raleigh",
  "Sub Maint - Sanford",
  "Sub Maint - Apopka",
  "Sub Maint - Asheville",
  "Sub Maint - Buena Vista",
  "Sub Maint - CR",
  "Sub Maint - Florence",
  "Sub Maint - Leland",
  "Sub Maint - Lake Wales",
  "Sub Maint - Monticello",
  "Sub Maint - Odessa",
  "Sub Maint - St Pete",
  "Sub Construction - Hartwell",
  "Sub Construction - IN North",
  "Sub Construction - IN South",
  "Sub Construction - IN West",
  "Sub Construction - Queensgate 2",
  "Sub Maint - Bloomington",
  "Sub Maint - Brecon",
  "Sub Maint - Clarksville",
  "Sub Maint - Hartwell",
  "Sub Maint - Kokomo",
  "Sub Maint - Lafayette",
  "Sub Maint - Queensgate",
  "Sub Maint - Noblesville",
  "Sub Maint - Terre Haute",
  "Sub Maint - Todhunter",
  "Sub Maint - Vincennes",
  "Sub Maint - Colmb/Shelbyville",
  "Test & Relay - IN Central",
  "Test & Relay - IN North",
  "Test & Relay - IN South West",
  "Test & Relay - Queensgate 1",
  "Test & Relay - Queensgate 2",
  "Test & Relay - Queensgate 3",
  "Trans Line - Bloomington",
  "Trans Line - Columbus",
  "Trans Line - Greencastle",
  "Trans Line - Kokomo",
  "Trans Line - Noblesville",
  "Trans Line - Vincennes",
  "Trans Line Construction - Garner",
  "Trans Line Maint - Beckerdite",
  "Trans Line Maint - Haw River",
  "Trans Line Maint - Hickory",
  "Trans Line Maint - Newport",
  "Trans Line Maint - Spartanburg",
  "Trans Line Maint - Toddville",
  "Trans Line Maint - Wenwood",
  "Trans Line Maint - Aberdeen",
  "Trans Line Maint - Florence",
  "Trans Line Maint - Asheville",
  "Trans Line Maint - Garner",
  "Trans Line Maint - Leland",
  "Trans Line Maint - NB",
  "Trans Heavy Moving",
  "Trans Line - Hamlet",
  "Trans Line - Hartwell",
  "Trans Test Crew",
  // Training Departments
  "",
  "Training - Cary",
  "Training - Clearwater",
  "Training - Dana UG",
  "Training - Inglis",
  "Training - New Hill",
  "Training - Shelbyville",
  "Training - Toddville",
  "Training - Wildwood",
  "Training - Winter Garden"
]

/**
   * Describes the object representing the result of the [suggestLocations()](https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-locator.html#suggestLocations) method.
   *
   * [Read more...](https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-locator.html#SuggestionResult)
   */
export interface SuggestionResult extends Object {
  /**
   * Indicates if the result is a Collection.
   *
   * [Read more...](https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-locator.html#SuggestionResult)
   */
  isCollection: boolean;
  /**
   * ID used in combination with the `text` property to uniquely identify a suggestion.
   *
   * [Read more...](https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-locator.html#SuggestionResult)
   */
  magicKey: string;
  /**
   * The string name of the suggested location to geocode.
   *
   * [Read more...](https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-locator.html#SuggestionResult)
   */
  text: string;
}

export const STATE_CODE_TO_NAME = {
  AZ: 'Arizona',
  AL: 'Alabama',
  AK: 'Alaska',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const STATE_NAME_TO_CODE = {
  'Arizona': 'AZ',
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};

export interface SubstationSearchResults {
  substation_name: string,
}

/**
 * Returns an object containing the parts of an address string
 *
 * @param addressStr Address string in the format 'Address Line, City, State Code, Zip(, Country)?'
 */
export const addressPartsFromStr = (addressStr: string) => {
  const resultSegments = addressStr?.split(',')?.map(r => r.trim()) ?? [];
  const stateIndex = resultSegments.findIndex(s => Object.keys(STATE_CODE_TO_NAME).includes(s));
  const zipIndex = resultSegments.findIndex(s => /^\d{5}$/.test(s));

  return {
    address_line_1: resultSegments.slice(0, stateIndex - 1).join(', '),
    address_line_2: null,
    city: resultSegments[stateIndex - 1] ?? null,
    state: STATE_CODE_TO_NAME[resultSegments[stateIndex]] ?? null,
    zip_code: resultSegments[zipIndex] ?? null
  };
}