import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { DeSelectFormElement, DeSelectOptionsDependency } from '../../models/DeTemplateForms';
import { FormElementComponent } from '../form-element.component';
import { AppConfig } from 'src/app/config/app.config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'de-select',
  templateUrl: './de-select.component.html',
  styleUrls: ['./de-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeSelectComponent
    }
  ]
})
export class DeSelectComponent extends FormElementComponent<string[]> implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit {

  @Input() formElement: DeSelectFormElement;

  @Input() readOnly: boolean;

  /**
   * Reference to the entire form group
   */
  @Input() formGroupRef: UntypedFormGroup;

  /**
   * Whether or not the selection dropdown is open
   */
  selecting = false;

  /**
   * Original form element options
   */
  originalFormElementOptions: string[] = [];

  /**
   * Available options, based on filter if searching
   */
  options: string[] = [];

  /**
   * Index of the 'Other' option in the list of selected values.
   *
   * This is used to manage the custom text (customOtherValue) entered
   * into the option summary card when 'Other' is selected.
   *
   * Ultimately, the component value will look like:
   *
   * ['Selected Option 1', ..., 'Other', 'Custom text entered into other textarea']
   */
  otherValueIndex = -1;

  /**
   * Custom text entered into the option summary card when 'Other' is selected.
   *
   * Ultimately, the component value will look like:
   *
   * ['Selected Option 1', ..., 'Other', customOtherValue]
   */
  customOtherValue: string;

  private readonly _destroying$ = new Subject<void>();

  @Input() showBorder = true;
  @HostBinding('class.showBorder') get _showBorder() {
    return this.showBorder;
  }
  @HostBinding('class.focused') get focused() {
    return this._focused || this.selecting;
  }
  @HostBinding('class.error') get hasError() {
    return !this._hasValue && this._touched && this.required;
  }
  @HostBinding('class.required') get required() {
    return this.isRequired(this.formElement);
  }
  @HostBinding('class.hasValue') get hasValue() {
    return this._hasValue;
  }
  @HostBinding('class.open') get isSelecting() {
    return this.selecting;
  }
  @HostBinding('class.otherValue') get hasOtherValue() {
    return this.otherOptionSummaryCardVisible(this._value);
  }

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('hoverOptions') hoverOptions: ElementRef<HTMLUListElement>;
  selectedListItem: HTMLLIElement;

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.selecting && this.hoverOptions?.nativeElement) {
      switch (event.key) {
        case 'ArrowDown':
          this.selectedListItem =
            this.selectedListItem?.nextElementSibling instanceof HTMLLIElement
              ? this.selectedListItem.nextElementSibling
              : this.hoverOptions.nativeElement.firstChild as HTMLLIElement;
          (this.selectedListItem?.firstChild as HTMLButtonElement)?.focus();
          break;
        case 'ArrowUp':
          this.selectedListItem =
            this.selectedListItem?.previousElementSibling instanceof HTMLLIElement
              ? this.selectedListItem.previousElementSibling
              : this.hoverOptions.nativeElement.lastChild as HTMLLIElement;
          (this.selectedListItem?.firstChild as HTMLButtonElement)?.focus();
          break;
        case 'Escape':
          this.toggleSelect();
          break;
      }
    }
  }

  searchFormControl = new UntypedFormControl('');
  searchPlaceholder = '';

  @Output() valueSelected = new EventEmitter<string[]>();

  constructor(
    private http: HttpClient, 
    private config: AppConfig
    ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.searchable) {
      const searchTerm = this.placeholder?.split(' ').map(p => p.match(/^[A-Z]+$/) ? p : p.toLowerCase()).join(' ') ?? 'Search';
      this.searchPlaceholder = `Search ${searchTerm}${searchTerm.charAt(searchTerm.length - 1) === 's' ? '' : '(s)'}...`;

      if (!this.multiple) {
        this.searchFormControl.setValue(this._value?.[0] ?? '');
      }
      this.formElement.form_control.valueChanges
        .pipe(
          debounceTime(100),
          takeUntil(this._destroying$)
        )
        .subscribe(
          change => {
            if (!change?.[0]) {
              this.searchFormControl.setValue('');
            }
          }
        );
      this.searchFormControl.valueChanges.pipe(
        debounceTime(250),
        takeUntil(this._destroying$)
        ).subscribe(
          async (filter: string) =>
          this.options = (filter?.trim().length > 2 && this.formElement.search_api?.length > 0)
          ? (
            await this.http.get(`${this.config.getConfig('apiBaseUrl')}${this.formElement.search_api}?${this.formElement.search_param_name}=${filter.trim().toUpperCase()}`)
            .pipe(map((res: any) => Array.isArray(res) ? res : Array.isArray(res?.data) ? res.data : [filter])).toPromise().catch(e => [filter])
            )
            : filter?.trim().length > 0
            ? this.originalFormElementOptions.filter(o => o.toLowerCase().includes(filter?.trim()?.toLowerCase()))
            : [...this.originalFormElementOptions]
            );
          }

    if (this.otherOptionSummaryCardVisible(this._value)) {
      this.otherValueIndex = this._value.findIndex(o => o === 'Other');

      // Custom other value is found at the end of the array
      // (e.g. ['Selected Option 1', ..., 'Other', customOtherValue])
      const otherValue = this._value[this.otherValueIndex + 1];
      this.customOtherValue = otherValue;
    }
  }

  ngOnInit(): void {
    // Provides a method for reconfiguring options after initialization
    this.formElement.configureOptions = () => this.configureOptions();
    this.configureOptions();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  configureOptions() {
    this.originalFormElementOptions = Array.isArray(this.formElement.options)
      // If the options are a list of strings, just use that list
      ? this.formElement.options
      // If the options are a mapping from dependency values to lists of strings, find the value of that
      // dependency field and get the correct list of options
      : (this.formElement.options?.options?.[this.formGroupRef?.get?.(this.formElement.options?.dependency_field_name)?.value] ?? []);

    // Use a copy of the original options
    this.options = [...this.originalFormElementOptions];
  }

  get id() {
    return this.formElement.element_id;
  }

  get label() {
    return this.formElement.field_display ?? '';
  }

  get multiple() {
    return this.formElement.multiple;
  }

  get placeholder() {
    return this.formElement.placeholder ?? '';
  }

  get size() {
    return this.multiple
      ? (this.options?.length ?? 0)
      : undefined;
  }

  get searchable() {
    return this.formElement.searchable;
  }

  /**
   * Map from options to their corresponding description. These will be shown
   * as cards beneath the main dropdown.
   */
  get option_summary_cards() {
    return this.formElement.option_summary_cards;
  }

  selected(option: string) {
    return this._value?.includes(option);
  }

  toggleSelect(val?: boolean) {
    if (this.readOnly || this._disabled) {
      return;
    }
    this.selecting = val === undefined ? !this.selecting : val;
    if (!this.searchable) {
      setTimeout(() => {
        this.selectedListItem = this.hoverOptions?.nativeElement?.firstChild as HTMLLIElement;
        (this.selectedListItem?.firstChild as HTMLButtonElement)?.focus();
        (this.hoverOptions?.nativeElement?.firstChild as HTMLButtonElement)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }

  removeSelectedOption(option: string, $event: MouseEvent) {
    if (this.selected(option)) {
      this.valueChange(
        option === 'Other' && this.otherOptionSummaryCardVisible(this._value)
          // Remove both 'Other' and the custom option
          ? this._value.slice(0, -2)
          : this._value.filter(v => v !== option)
      );
    }
    $event.stopImmediatePropagation();
  }

  selectOption(option: string) {
    if (!(option?.length > 0) || this.readOnly || this._disabled) {
      return;
    }
    if (this.multiple) {
      if (this.selected(option)) {
        this.valueChange(this._value.filter(v => v !== option));
      } else {
        // Preserves order from original list of options
        this.valueChange(this.originalFormElementOptions.filter(o => this.selected(o) || o === option));
      }
    } else {
      this.valueChange([option]);
      this.searchFormControl.setValue(option);
      this.toggleSelect();
    }
    this.valueSelected.emit(this._value);
  }

  pop() {
    this._value?.pop();
    this.valueChange(this._value);
  }

  hoverOptionFocus(target: HTMLButtonElement) {
    this.selectedListItem = target.parentElement as HTMLLIElement;
  }

  hoverOptionBlur() {
    this.selectedListItem = null;
    setTimeout(() => {
      // Continue selecting if the new active element is another option OR the search field
      this.selecting = document.activeElement?.parentElement?.parentElement === this.hoverOptions?.nativeElement
        || this.searchInput?.nativeElement === document.activeElement;
    }, 150);
  }

  blur() {
    setTimeout(() => {
      if (this.searchable && !this.selectedListItem) {
        this.selecting = false;
        if (this.searchFormControl.value !== this._value?.[0]) {
          if (!(this.searchFormControl.value?.length > 0) && !this.multiple) {
            this.pop();
          } else if (!this.multiple) {
            this.searchFormControl.setValue(this._value?.[0]);
          }
        }
      }
    }, 150);
    super.blur();
  }

  /**
   * True if 'Other' is selected and option summary cards are enabled
   *
   * @param value Form value (list of selected options)
   */
  otherOptionSummaryCardVisible(value: string[]) {
    return value?.length > 0 && this.option_summary_cards && value?.includes('Other');
  }

  customOtherValueChange(value: string) {
    this.customOtherValue = value;
    this.valueChange(this._value);
  }

  valueChange(value: string[]) {
    if (this.otherOptionSummaryCardVisible(value)) {
      this.otherValueIndex = value.findIndex(o => o === 'Other');

      if (this.customOtherValue !== undefined) {
        // Custom other value is placed at the end of the array
        // (e.g. ['Selected Option 1', ..., 'Other', customOtherValue])
        value.splice(this.otherValueIndex + 1, 1, this.customOtherValue);
      }
    } else {
      this.otherValueIndex = -1;
    }
    super.valueChange(value?.length > 0 ? value : null);
  }
}
