import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

/**
 * Common form metadata stored in the Aurora RDS table
 */
export interface DeTemplateFormMetadata {
  /**
   * Form uuid (unique to the individual form)
   */
  form_uuid: string;
  /**
   * Form group uuid (relates individual form to the group, i.e. Pre-Job 1, Pre-Job 2, Post-Job)
   */
  form_group_uuid: string;
  /**
   * Name of the template file in s3
   */
  template_name: 'pre_job_brief' | 'post_job_brief';
  /**
   * Version of the template file in s3
   */
  template_version: number;
  /**
   * Form type (e.g. pre_job_brief or post_job_brief)
   */
  form_type: string;
  /**
   * Form display name (e.g. Pre-Job Brief or Post-Job Brief)
   */
  display_name: string;
  /**
   * Whether or not an ALL STOP was called
   */
  form_stopped: boolean;
  /**
   * All stop submission timestamp
   */
  stopped_timstamp: number;
  /**
   * Reason for an all stop, if one was called
   */
  all_stop_reason: string;
  /**
   * FRONTEND ONLY: Only set to a date string when calling an ALL STOP on a COMPLETED pre-job brief.
   * Used to enable the form for the all stop but perform additional logic on completion.
   */
  formWasCompletedBeforeAllStop?: number;
  /**
   * Form id (within group, indicates where the form is positioned relative to other forms in the group)
   */
  form_id: number;

  /**
   * FRONTEND ONLY: Indicates that the form has been submitted by the user but wasn't successfully
   * submitted to the backend. This is used to sync with the backend when online.
   */
  requiresBackendSubmission?: boolean;

  /**
   * FRONTEND ONLY: Indicates the type of form metadata ('line', 'fleet', etc.)
   */
  user_type: DeTemplateFormType;

  // Additional details related to the form itself
  start_timestamp?: number;
  completed_timestamp?: number;
  submitter_name?: string;
  submitter_email?: string;
  work_number?: string;
  location_address?: string;
  location_city?: string;
  location_state?: string;
  location_zip?: string;
  location_google_url?: string;
  nearest_medical_facility_name?: string;
  nearest_medical_facility_address?: string;
  nearest_medical_facility_city?: string;
  nearest_medical_facility_state?: string;
  nearest_medical_facility_zip?: string;
  nearest_medical_facility_google_url?: string;
  crew_contacts?: DeTemplateFormMetadataCrewContact[];
}

// $_FORM_TYPE_ADJUSTMENT_$ -> Wrappers to cast metadata for a specific form type
export const transmission = (metadata: DeTemplateFormMetadata) => metadata as TransmissionFormMetadata;
export const nonCraft = (metadata: DeTemplateFormMetadata) => metadata as NonCraftFormMetadata;
export const fleet = (metadata: DeTemplateFormMetadata) => metadata as FleetFormMetadata;
export const line = (metadata: DeTemplateFormMetadata) => metadata as LineFormMetadata;

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended type for line metadata
export interface LineFormMetadata extends DeTemplateFormMetadata {
  eic?: string;
  op_center?: string;
  job_type?: string;
  circuit_or_feeder?: string;
  clearance_number?: string;
  protective_device?: string;
  hotline_tag_number?: string;
  energized_vs_de_energized_guide_consulted?: string;
  required_permits_in_place?: string;
  permits_required_for_job?: string;
  permit_numbers?: string;
  flash_and_contact_hazards_identified?: string;
  flash_and_contact_hazards?: string;
  grounding_hazards_identified?: string;
  specific_hazard_for_the_job?: string;
  mitigation_or_barrier_for_the_hazard?: string;
  person_responsible_for_mitigation?: string;
  other_person_responsible_for_mitigation?: string;
  will_any_critical_steps_require_three_way_communication: string;
  critical_steps: string;
}

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended type for fleet metadata
export interface FleetFormMetadata extends DeTemplateFormMetadata {
  asset_number?: string;
  fleet_garage?: string;
  briefing_leader_name?: string;
  emergency_contact_name?: string;
  traveling_required?: boolean;
  high_risk_tools_needed?: boolean;
}

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended type for Non-Craft metadata
export interface NonCraftFormMetadata extends DeTemplateFormMetadata {
  eic?: string;
  region?: string;
  job_type?: string;
  emergency_contact_name?: string;
}

// $_FORM_TYPE_ADJUSTMENT_$ -> Extended type for Transmission metadata
export interface TransmissionFormMetadata extends DeTemplateFormMetadata {
  eic?: string;
  op_center: string,
  department_center: string,
  job_type?: string;
  supervisor?: string;
  substation_name?: string;
  other_non_de_site?: string;
  line_name?: string;
  aed_location?: string;
}

export interface DeTemplateFormMetadataCrewContact {
  /**
   * Full name
   */
  name: string;
  /**
   * '1234567890' format
   */
  phoneNumber: string;
}

/**
 * Represents a single form stored in the backend and in IndexedDB
 */
export interface DeForm {
  /**
   * Value, which will be stored in an S3 bucket on the backend
   */
  form_value: any;
  /**
   * Metadata, which will be stored in DynamoDB
   */
  form_metadata: DeTemplateFormMetadata;
  /**
   * Form template, which is set on the frontend when the template is fetched
   */
  form_template?: DeTemplateForm;
}

/**
 * Represents a form group, which is created on the frontend by reducing the list
 * of forms received from the backend/IndexedDB
 */
export interface DeFormGroup {
  form_group_uuid: string;
  completed: boolean;
  forms: DeForm[];
  user_type: DeTemplateFormType;
}

export type DeTemplateFormName = 'Pre-Job Brief' | 'Post-Job Brief';
// $_FORM_TYPE_ADJUSTMENT_$
export type DeTemplateFormType = 'line' | 'fleet' | 'non-craft' | 'transmission';

export interface DeTemplateForm {
  /**
   * Primary key
   */
  formtype: DeTemplateFormType;
  /**
   * Sort key
   */
  formname: DeTemplateFormName;
  version: number;
  /**
   * Date the form template was created (in the YYYY-MM-DD format)
   */
  created: string;

  submitted?: boolean;

  /**
   * Description displayed at the top of a form
   */
  description?: string;
  /**
   * Additional details displayed beneath the description
   */
  additional_details?: string[];

  /**
   * Displayed as a button at the top of the form
   */
  primaryAction?: string;
  /**
   * Callback when the primary action button has been clicked
   */
  primaryActionCallback?: () => void;

  /**
   * Form itself
   */
  components: DeComponent[];
}

export interface DeComponent {
  /**
   * Indicates what to render on the frontend
   */
  component_type: DeFormComponentType;
  /**
   * Indicates that the component represents a data field
   */
  is_data_field: boolean;
  /**
   * Actual data field name
   */
  field_name?: string;
  /**
   * Component dependencies
   */
  depends_on?: DeFormElementDependency[];
  /**
   * Creates an instance of the component for each item in
   * the referenced field's array, using the values as field names
   */
  forEachOf?: string;
  forEachRef?: UntypedFormControl;
  /**
   * Display name
   */
  field_display?: string;
  description?: string;
  metadata?: any;
  /**
   * Style metadata
   */
  styles?: any;
  /**
   * Indicates that this a standalone component
   * and should therefore not trigger any service
   * open section methods
   */
  standalone?: boolean;
  /**
   * Indicates that the component cannot be interacted with
   */
  read_only?: boolean;
  /**
   * Set to true if the component should remain in the form group but
   * be hidden from the user in the UI
   */
  hide?: boolean;
  /**
   * This will null out the component value if dependecies not met
   */
  remove_values_when_hidden?: boolean;
}

export enum DeFormComponentType {
  /**
   * Represents a form section that will contain sub-components
   */
  Section = 'section',
  /**
   * Represents a group of n* sections of form elements that can be added/removed
   */
  AddRemoveSection = 'add_remove_section',
  WarningBanner = 'warning_banner',
  /**
   * Represents a simple text input (validators can enforce phone, email, etc. formatting)
   */
  TextInput = 'text_input',
  /**
   * Represents a text area
   */
  TextArea = 'text_area',
  /**
   * Represents a checkbox with a label and options
   */
  Checkbox = 'checkbox',
  /**
   * Represents a checkbox with a label and options
   */
  Radio = 'radio',
  /**
   * Represents a radio/checkbox header
   */
  RadioSectionHeader = 'radio_section_header',
  /**
   * Represents a dropdown with a set of options (search ahead can be enabled)
   */
  Dropdown = 'dropdown',
  /**
   * Represents a time entry component
   */
  Time = 'time',
  /**
   * Represents a date entry component
   */
  Date = 'date',
  /**
   * Represents a signature panel
   */
  SignaturePanel = 'signature_panel'
}

export interface DeSection extends DeComponent {
  /**
   * Collection of components
   */
  components: DeComponent[];

  /**
   * Indicates that the section will open
   * into its own separate page
   */
  full_page?: boolean;
  /**
   * Indicates that the section will print
   * on its own separate page
   */
  full_page_print?: boolean;

  /**
   * Hides the section in the initial list of sections
   */
  hide_in_main_list?: boolean;

  /**
   * Indicates that the section is a collapsable accordion
   */
  accordion?: boolean;

  disabled?: boolean;

  /**
   * Indicates that the section will open
   * as a full page popup
   */
  popup?: boolean;

  secondary_action_enabled?: boolean;
  secondary_action_text?: string;
  secondary_action_callback?: () => void;

  hidden?: boolean;

  styleAsDukeFilledButton?: boolean;

  /**
   * Placeholder used to label a popup section button
   */
  popup_placeholder?: string;
  /**
   * Adds data to a popup section button
   */
  popup_primary_data?: string;
  /**
   * Adds secondary data to a popup section button
   */
  popup_secondary_data?: string;

  showPopupEditText?: boolean;

  popupEditText?: string;

  /**
   * Border around fields in section
   */
  fieldsBorder?: string;
  /**
   * Background for fields in section
   */
  fieldsBackground?: string;
  /**
   * Additional padding around fields in section
   */
  fieldsPadding?: string;

  /**
   * Indicates whether a check should appear instead of
   * the edit text when a non-top level section is completed
   */
  showCheckWhenComplete?: boolean;

  /**
   * Used only by the frontend to manage expanded
   * state of a full page section
   */
  expanded?: boolean;
  /**
   * Used only by the frontend to manage *visible*
   * state of a full page section
   */
  visible?: boolean;
  /**
   * Indicates that the popup section should ALWAYS
   * have a red warning when inner fields are incomplete/missing
   */
  alwaysWarnMissingFields?: boolean;
  /**
   * Used only by the frontend to manage form state
   */
  form_group?: UntypedFormGroup;
  /**
   * Used only by the frontend to display form in a summary view
   */
  summary_view?: boolean;
}

export interface DeWarningBanner extends DeComponent {
  warning: string;
  color: 'yellow' | 'orange' | 'red' | 'white';
  showErrorIcon?: boolean;
  /**
   * Function to be called when the warning banner is clicked.
   *
   * This will cause the banner to be styled as clickable if an
   * inner span in the warning message has the "link" class.
   */
  clickCallback?: () => void;
}

export interface DeFormElement extends DeComponent {
  validators?: DeFormElementValidators;
  /**
   * Can link to a chart or documentation
   */
  link?: string;
  link_text?: string;
  link_vertical_position?: 'top' | 'bottom';
  link_horizontal_position?: 'left' | 'right';

  disabled?: boolean;

  sticky?: boolean;

  /**
   * Indicates that the element is initially in a touched state
   */
  touch?: boolean;
  touchIfHasValue?: boolean;

  /**
   * Value to use when initializing the form control
   */
  initial_value?: any;

  /**
   * Specific types of functions that can be used to format
   * the value before validation occurs
   */
  preValidationMapper?: 'phone';

  /**
   * Used only by the frontend to manage form state
   */
  form_control?: UntypedFormControl;
  element_id?: string;
}

export interface DeFormElementValidators {
  required?: boolean | DeFormElementDependency[];
  character_limit?: number;
  minimum_value?: string | number;
  greater_than_field?: string;
  maximum_value?: string | number;
  less_than_field?: string;
  regular_expression?: string;
}

/**
 * Represents a state that a specified field must match
 * in order for another component to be visible and/or enabled
 */
export interface DeFormElementDependency {
  /**
   * Name of the dependency field.
   *
   * MUST BE THE FULL DEPENDENCY PATH!
   *
   * For example, 'section_name.field_name' should be used as
   * the dependency field name instead of 'field_name'.
   *
   * In the event that a field depends on another field within an AddRemoveSection,
   * the other field name should be placed in single brackets like:
   *
   * '{other_field_in_add_remove_section}'
   */
  dependency_field_name: string;
  /**
   * By default, the dependent will not be visible
   * until the dependency is met. This will make the dependent
   * visible but disabled.
   */
  disable_only?: boolean;
  /**
   * Dependency will be met if the field's
   * value matches ANY of the values in the array
   */
  matches_any_of?: any[];
  /**
   * Dependency will be met if the field's
   * value matches NONE of the values in the array
   */
  matches_none_of?: any[];
}

// ==================================== //
// ======== SPECIFIC COMPONENTS ======= //
// ==================================== //

export interface DeInputFormElement extends DeFormElement {
  autocomplete?: string;
  placeholder?: string;
  hideCharacterCount?: boolean;
  /**
   * Input type (defaults to 'text')
   */
  type?: 'text' | 'number' | 'time' | 'date';
  /**
   * Clickable list to display beneath the input element
   */
  results?: string[]
  /**
   * Displayed when an empty list is provided for results
   */
  no_results_message?: string;
  /**
   * Displayed at the top of the results list
   */
  results_header?: string;
  /**
   * Indicates that the results should be filtered by the value of the text input
   */
  filterResults?: boolean;
  /**
   * Indicates that the results should be hidden when user starts typing
   */
  hideResultsWhenTyping?: boolean;
  /**
   * Used on the frontend to perform a callback when a result is selected
   */
  resultCallback?: (result: string) => void;
}

export interface DeSignatureFormElement extends DeFormElement {
  placeholder?: string;
  confirms?: string[];
}

export interface DeRadioSectionHeaderFormElement extends DeFormElement {
  message?: string;
  options: string[];
}

export interface DeRadioFormElement extends DeFormElement {
  options: string[];
  /**
   * Enables checkbox-like toggling
   */
  allowToggling?: boolean;
  checkboxPosition?: 'left' | 'right';
  /**
   * Centers the checkbox and label
   */
  center?: boolean;
}

/**
 * Dependency field name with a mapping from dependency values to lists of options
 */
export interface DeSelectOptionsDependency {
  dependency_field_name: string;
  options: { [dependency_value: string]: string[] };
}

export interface DeSelectFormElement extends DeFormElement {
  /**
   * List of options.
   *
   * This can also be a dependency field name with a mapping from dependency values to lists of options.
   */
  options: string[] | DeSelectOptionsDependency;
  placeholder?: string;
  /**
   * Optionally provide an initial value for the dropdown
   */
  start_with?: string;
  /**
   * Indicates whether the user can search within the options
   */
  searchable?: boolean;
   /**
   * API url if the search results are dynamic from a server
   */
   search_api?: string;
    /**
   * If using an API to search, the name of the query param
   */
  search_param_name?: string;
  /**
   * Enables the ability to select multiple options
   */
  multiple?: boolean;
  /**
   * Indicates that you can edit the displayed option in option summary cards
   */
  allow_editing_option_summary?: boolean;
  /**
   * Map from options to a summary of each option.
   *
   * This will appear as a list of cards beneath the main dropdown,
   * and each card can be removed as a selected option.
   */
  option_summary_cards?: { [option in string]: string };
  /**
   * Reference to the component instance's option configuration method,
   * used in cases where the options need to be re-configured after initialization
   */
  configureOptions?: () => void;
}

export interface DeAddRemoveSectionFormElement extends DeFormElement {
  /**
   * Collection of components
   */
  components: DeComponent[];

  delete_text?: string;
  add_text?: string;
  secondary_action_text?: string[];
  secondary_action_enabled?: boolean[];
  secondary_action_callback?: (callerIndex: number) => void;

  color?: 'white' | 'gray';

  max?: number;

  /**
   * Used only by the frontend to manage form state
   */
  form_group?: UntypedFormGroup;
  displayedComponents?: DeComponent[][];
}