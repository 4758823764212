import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { AppDrawerComponent } from './app-drawer/app-drawer.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { DateDrawerComponent } from './date-drawer/date-drawer.component';
import { DeTemplateFormsModule } from 'projects/de-template-forms/src/public-api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';

@NgModule({
  declarations: [
    AppNavbarComponent,
    AppDrawerComponent,
    DateDrawerComponent,
    SelectDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DeTemplateFormsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AppNavbarComponent,
    AppDrawerComponent,
    DateDrawerComponent
  ]
})
export class SharedModule { }
