import { Component, HostBinding, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/config/app.config';
import { UserService } from 'src/app/services/auth/user.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { NavigationService, PageNavigationTitle } from 'src/app/services/routing/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {

  isProduction: boolean;

  @HostBinding('class.active') get active() {
    return this.navigationService.loggedIn;
  }

  @HostBinding('class.red') get red() {
    return this.navigationService.redNavbar || !this.isProduction;
  }

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private firebase: FirebaseService,
    private config: AppConfig,
  ) {
    this.isProduction = /prod/i.test(this.config.getConfig('envName'))
  }

  ngOnInit(): void {
  }

  get name() {
    return this.userService.fullName;
  }

  get title() {
    return this.navigationService.title;
  }

  get url() {
    return this.navigationService.url;
  }

  get loadingFormsPage() {
    return this.navigationService.loadingFormsPage;
  }

  get showBack() {
    return this.navigationService.showBack;
  }

  get saving() {
    return this.navigationService.isSaving;
  }

  get searching() {
    return this.title === PageNavigationTitle.SEARCH;
  }

  get onHomePage() {
    return this.title === PageNavigationTitle.MAIN_TITLE
      && this.url !== '/contact-us';
  }

  get showSearchIcon() {
    return this.active && this.onHomePage && !this.saving;
  }

  get showPrintIcon() {
    return this.navigationService.printing;
  }

  navButtonClicked() {
    if (this.showBack) {
      this.firebase.logEvent(`back to -> ${this.navigationService.title}`);
      this.navigationService.goBack();
    } else {
      this.firebase.logEvent('open hamburger');
      this.navigationService.openHamburger();
    }
  }

  titleClicked() {
    if (this.showBack) {
      this.firebase.logEvent(`back to -> ${this.navigationService.title}`);
      this.navigationService.goBack();
    } else {
      this.firebase.logEvent('main title clicked');
      this.navigationService.goHome();
    }
  }

  print() {
    if (/Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)) {
      // Safari
      document.execCommand('print');
    } else {
      // All other browsers
      window.print();
    }
  }

  logout() {
    this.firebase.logEvent('logout');
    this.userService.logout();
  }
}
