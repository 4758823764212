import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { env } from '../../../config/env';
import { DynatraceService } from '../dynatrace/dynatrace.service';

/**
 * Used for version
 */
const appInfo = require('../../../../package.json');

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAHGJ5fK4xdYdZrs5TdFLbLk7HB66aQg10",
  authDomain: "job-brief.firebaseapp.com",
  projectId: "job-brief",
  storageBucket: "job-brief.appspot.com",
  messagingSenderId: "893514675677",
  appId: "1:893514675677:web:6ec266a76102da49d17234",
  measurementId: "G-J12WMNP83D"
};

const commonEventParams = {
  env: env.env,
  version: appInfo.version,
  userAgent: navigator.userAgent
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private _app: FirebaseApp;
  private _analytics: Analytics;

  constructor(
    private dynatrace: DynatraceService
  ) { }

  initialize() {
    this._app = initializeApp(firebaseConfig);
    this._analytics = getAnalytics(this._app);
  }

  logEvent(eventName: string, eventParams: any = {}) {
    // TODO: Eventually cache when offline, then sync events when online
    if (navigator.onLine) {
      logEvent(this._analytics, eventName, { ...commonEventParams, ...(eventParams ?? {}) });
      // Also log the action in Dynatrace
      this.dynatrace.logAction(eventName);
    }
  }
}
