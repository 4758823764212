<div *ngIf="_formTemplate" class="form">
  <!-- Form Title -->
  <!-- <h1 *ngIf="!anySectionExpanded">
    <span>{{_formTemplate.formname}}</span>
    <span class="{{status}}">{{status}}</span>
  </h1> -->

  <!-- Header -->
  <div *ngIf="!anySectionExpanded" class="form-description"
    [class.hasDetails]="_formTemplate.additional_details?.length > 0">
    <div *ngIf="_formTemplate.description !== undefined || _formTemplate.additional_details?.length > 0"
      class="form-description-container">
      <!-- Form Description -->
      <h3 *ngIf="_formTemplate.description !== undefined">{{_formTemplate.description}}</h3>
      <!-- Additional Details -->
      <ng-container *ngIf="_formTemplate.additional_details?.length > 0">
        <h3 *ngFor="let detail of _formTemplate.additional_details" [innerHTML]="detail"></h3>
      </ng-container>
    </div>
    <!-- Primary Action -->
    <button *ngIf="_formTemplate.primaryAction?.length > 0" class="[ text-button ]"
      (click)="_formTemplate.primaryActionCallback?.()">
      {{_formTemplate.primaryAction}}
    </button>
  </div>

  <!-- Form Itself -->
  <form [formGroup]="_formGroup">
    <de-template-form-component *ngFor="let component of _formTemplate.components" [component]="component"
      [style]="component.styles ?? ''" [formGroupRef]="_formGroup" [logEvent]="logEvent"
      [componentIsReadOnly]="_form?.form_metadata?.completed_timestamp" [destroySubject]="_destroying$">
    </de-template-form-component>
  </form>
</div>