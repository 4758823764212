import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeRadioSectionHeaderFormElement } from '../../models/DeTemplateForms';

@Component({
  selector: 'de-checkbox-section-header',
  templateUrl: './de-checkbox-section-header.component.html',
  styleUrls: ['./de-checkbox-section-header.component.scss']
})
export class DeCheckboxSectionHeaderComponent implements OnInit {

  /**
   * Optional event logger
   */
  @Input() logEvent: (eventName: string, eventParams?: any) => {};

  @Input() formElement: DeRadioSectionHeaderFormElement;
  @Input() customMessage: string;

  @Input() readOnly: boolean;

  @Output() selectAllOptions = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  get message() {
    return this.customMessage ?? (this.formElement?.message ?? '');
  }

  get options() {
    return this.formElement?.options ?? [];
  }

  get selectAll() {
    return this.formElement?.options?.length === 1 && this.formElement.options[0] === 'Select All';
  }

  log(log: string) {
    if (this.logEvent) {
      this.logEvent(log);
    }
  }
}
