import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private authService: MsalService) { }

  get azureAccount() {
    return this.authService.instance.getAllAccounts()?.[0] ?? undefined;
  }

  get email() {
    return this.azureAccount?.username ?? '';
  }

  get fullName() {
    return this.azureAccount?.name?.length > 0
      ? this.azureAccount.name.split(',').reverse().join(' ').trim()
      : '';
  }

  logout() {
    this.authService.logout();
  }
}
