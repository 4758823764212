  <section [class.fixed-height]="!formElement?.element_id" cdkDropList cdkScrollable cdkDropListAutoScrollStep="7" (cdkDropListDropped)="reorderCrewFavorites($event)" class="crew-favorites-list">
    <de-checkbox-section-header *ngIf="header?.length > 0" [customMessage]="header"></de-checkbox-section-header>

  <div cdkDrag cdkDragBoundary=".crew-favorites-list" cdkDragLockAxis="y" [cdkDragDisabled]="formElement?.element_id"  #componentGroups *ngFor="let group of componentGroups; let i = index" 
    class="component-group {{color}} {{formElement?.element_id ? 'drag-disabled' : ''}}">
    <!-- Go through all components of this section -->
    <de-template-form-component *ngFor="let component of group" [component]="component" [formGroupRef]="formGroupRef"
      [style]="component.styles || {}" [componentIsReadOnly]="readOnly || _disabled || component.disabled"
      (sectionExpanded)="sectionExpanded.emit($event)">
    </de-template-form-component>
    <div class="add-remove-actions">
      <button *ngIf="!(readOnly || _disabled) && secondaryActionText?.[i]?.length > 0" class="ghost-button"
        (click)="secondaryAction(i)" type="button"
        [disabled]="!secondaryActionEnabled?.[i]">{{secondaryActionText?.[i]}}</button>
      <div></div>
        <div *ngIf="!formElement?.element_id" class="handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <button *ngIf="!(readOnly || _disabled) && deleteText?.length > 0" class="ghost-button warning remove"
          (click)="deleteSection(i); log(deleteText)" type="button">{{deleteText}}</button>
    </div>
  </div>
  <button *ngIf="!(readOnly || _disabled) && addText?.length > 0 && (!max || componentGroups?.length < max)"
  class="text-button add" (click)="addSection(); log(addText)" type="button">+
  {{addText}}</button>
</section>