import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { valueIsEmptyArray } from 'src/app/services/utility';
import { DeComponent, DeForm, DeFormComponentType, DeFormElement, DeFormElementDependency, DeInputFormElement, DeRadioFormElement, DeRadioSectionHeaderFormElement, DeSection, DeSelectFormElement, DeSignatureFormElement, DeTemplateForm, DeWarningBanner } from '../models/DeTemplateForms';

const checkForDependencies = (formGroup: UntypedFormGroup) => (dependenciesMet: boolean, dependency: DeFormElementDependency) => {
  const dependencyValue = formGroup?.get(dependency.dependency_field_name)?.value;
  return dependenciesMet
    && (
      !(dependency.matches_any_of?.length > 0)
      || dependency.matches_any_of.some(
        d =>
          // Values are equal
          d === dependencyValue
          // Values are both empty arrays
          || (valueIsEmptyArray(d) && valueIsEmptyArray(dependencyValue))
          // Dependency value is an array and includes the value
          || (Array.isArray(dependencyValue) && (dependencyValue.includes(d) || (valueIsEmptyArray(d) && dependencyValue.find(dep => valueIsEmptyArray(dep)))))
      )
    )
    && (
      !(dependency.matches_none_of?.length > 0)
      || !dependency.matches_none_of.some(
        d =>
          // Values are equal
          d === dependencyValue
          // Values are both empty arrays
          || (valueIsEmptyArray(d) && valueIsEmptyArray(dependencyValue))
          // Dependency value is an array and includes the value
          || (Array.isArray(dependencyValue) && (dependencyValue.includes(d) || (valueIsEmptyArray(d) && dependencyValue.find(dep => valueIsEmptyArray(dep)))))
      )
    );
};

const formControlReducer = (group, element: DeFormElement) => ({
  ...group,
  [element.field_name]: generateFormControl(element)
});

export const generateFormControl = (element: DeFormElement, formGroup?: UntypedFormGroup) =>
  new UntypedFormControl(
    null,
    generateValidators(element, formGroup)
  );

export const generateValidators = (element: DeFormElement, formGroup?: UntypedFormGroup) =>
  Object.keys(element.validators ?? {})
    .filter(k => !!element.validators?.[k])
    .map(
      k => {
        switch (k) {
          case 'required':
            return element.validators[k] === true
              ? Validators.required
              : formGroup && dependenciesAreMet(formGroup, (element.validators[k] as DeFormElementDependency[])?.filter(d =>
                !d.disable_only
                // Ignore dependencies for components within Add/Remove sections
                && !/_\d$/i.test(d.dependency_field_name)
              ))
                ? Validators.required
                : undefined
          case 'character_limit':
            return Validators.maxLength(element.validators.character_limit);
          case 'minimum_value':
            return Validators.min(parseInt(`${element.validators.minimum_value ?? Number.NEGATIVE_INFINITY}`, 10));
          case 'maximum_value':
            return Validators.max(parseInt(`${element.validators.minimum_value ?? Number.POSITIVE_INFINITY}`, 10));
          case 'regular_expression':
            return Validators.pattern(element.validators.regular_expression);
          default:
            return undefined;
        }
      }
    )
    .filter(v => !!v)

export const dependenciesAreMet = (formGroupRef: UntypedFormGroup, dependencies: DeFormElementDependency[]) => {
  return !(dependencies?.length > 0) || dependencies.reduce(
    checkForDependencies(formGroupRef),
    true
  );
}

@Injectable({
  providedIn: 'root'
})
export class DeTemplateFormsService {

  private _openSections$ = new BehaviorSubject<DeSection[]>([]);
  private _closedSection$ = new BehaviorSubject<DeSection>(null);
  public _isExpanded$ = new Subject<{expanded:boolean, index?: number}>()
  private _formTemplate$ = new BehaviorSubject<DeTemplateForm>(null);

  form: DeForm;

  /**
   * Whether or not the browser is a mobile user agent
   */
  isMobileUserAgent: boolean;

  /**
   * Whether or not the browser is a mobile Safari user agent
   */
  isMobileSafariUserAgent: boolean;

  /**
   * Whether or not the site is installed as a PWA
   */
  isStandalonePWA: boolean;

  currentFormReset$ = new Subject<void>();

  constructor() {
    const userAgentStr = navigator.userAgent ?? navigator.vendor;

    // Extensive mobile user agent detection from http://detectmobilebrowsers.com/
    this.isMobileUserAgent =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgentStr)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgentStr.substring(0, 4));

    this.isMobileSafariUserAgent = this.isMobileUserAgent && /iPhone|iPad/i.test(userAgentStr);

    this.isStandalonePWA = (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;

    console.log(`${this.isMobileUserAgent ? 'Mobile' : 'Non-mobile'} user agent detected.`);

    console.log(`Site is${this.isStandalonePWA ? '' : ' not'} installed as a standalone PWA.`);
  }

  get anySectionIsExpanded() {
    return this._openSections$.value?.length > 0;
  }

  get currentSection() {
    return this._openSections$.value?.[this._openSections$.value?.length - 1];
  }

  setForm(form: DeForm) {
    this.form = form;
    this._formTemplate$.next(form.form_template);
    return of(this._formTemplate$.value).toPromise();
  }

  openSections$() {
    return this._openSections$;
  }

  closedSection$() {
    return this._closedSection$;
  }

  isExpanded$() {
    return this._isExpanded$;
  }

  currentFormTemplate$() {
    return this._formTemplate$;
  }

  resetCurrentForm(sectionName?: string) {
    this._openSections$.value?.forEach?.(section => section.expanded = false);
    this._openSections$.next([]);
    if (sectionName?.length > 0) {
      this.openTopLevelSectionByName(sectionName);
    }
  }

  generateFormGroup(components: DeComponent[], parentSectionName: string = ''): UntypedFormGroup {
    // Separate sections and form elements from the current list of components
    const sections: DeSection[] = components?.filter(c => c.component_type === DeFormComponentType.Section) as DeSection[];
    const elements: DeFormElement[] = components?.filter(c => c.is_data_field) as DeFormElement[];

    const sectionFormGroupMap = sections?.length > 0
      ? sections.reduce(
        (group, section) => {
          return { ...group, [section.field_name]: this.generateFormGroup(section.components, `${parentSectionName}${section.field_name}.`) }
        }, {}
      )
      : {};
    sections.forEach(s => {
      s.form_group = sectionFormGroupMap[s.field_name];
    });
    const elementFormControlMap = elements?.reduce(formControlReducer, {}) ?? {};
    elements.forEach(e => {
      e.form_control = elementFormControlMap[e.field_name];
      e.element_id = `${parentSectionName}${e.field_name}`;
      if (e.initial_value !== undefined) {
        e.form_control?.setValue(e.initial_value);
      }
      if (e.touch) {
        e.form_control.markAsTouched();
      }
    });

    return new UntypedFormGroup({
      ...sectionFormGroupMap,
      ...elementFormControlMap
    });
  }

  openTopLevelSectionByName(sectionName: string) {
    const section = this._formTemplate$.value?.components?.find(
      c => c.component_type === DeFormComponentType.Section
        && c.field_name === sectionName
    ) as DeSection;

    if (section) {
      this._openSections$.value?.forEach(
        s => {
          s.expanded = false;
          s.visible = false;
        }
      );
      section.expanded = true;
      section.visible = true;
      this._openSections$.value.push(section);
      this._openSections$.next(this._openSections$.value);
    }
  }

  /**
   * Returns the next high-level section in the form
   *
   * @param formGroupRef Reference to the Angular reactive form group
   * @param section (Optional) Section to use instead of the currently open section
   * @returns 
   */
  getNextFirstLevelSection(formGroupRef: UntypedFormGroup, section?: DeSection) {
    const currentSection = section ?? this._openSections$.value?.[this._openSections$.value.length - 1];
    if (currentSection) {
      const currentSectionIndex = this._formTemplate$.value?.components?.findIndex(c => c.field_name === currentSection.field_name);
      const nextSection = this._formTemplate$.value?.components?.[currentSectionIndex + 1] as DeSection;
      if (nextSection && dependenciesAreMet(formGroupRef, nextSection.depends_on?.filter(d =>
        !d.disable_only
        // Ignore dependencies for components within Add/Remove sections
        && !/_\d$/i.test(d.dependency_field_name))
      )) {
        return nextSection;
      } else if (nextSection) {
        return this.getNextFirstLevelSection(formGroupRef, nextSection);
      }
    }
    return undefined;
  }

  openSection(section: DeSection) {
    if (section) {
      if (!section.standalone && this._openSections$.value?.length > 0) {
        this._openSections$.value[this._openSections$.value.length - 1].visible = false;
        if (!section.popup) {
          // Collapse previous section if new section is not a popup
          this._openSections$.value[this._openSections$.value.length - 1].expanded = false;
        }
      }

      section.expanded = true;
      section.visible = true;
      if (!section.standalone) {
        this._openSections$.value.push(section);
        this._openSections$.next(this._openSections$.value);
      }
    }
  }

  /**
   * Re-emits the open section value to trigger changes
   * in subscribers to the section observable
   */
  notifyOpenSectionSubscribers() {
    this._openSections$.next(this._openSections$.value)
  }

  closeSection() {
    const openSections = this._openSections$.value;
    if (openSections?.length > 0) {
      const closedSection = openSections.pop();
      closedSection.expanded = false;
      closedSection.visible = false;
      if (openSections.length > 0) {
        openSections[openSections.length - 1].expanded = true;
        openSections[openSections.length - 1].visible = true;
      }
      this._openSections$.next(openSections);
      this._closedSection$.next(closedSection);
    }
  }

  /**
   * Checks a form's completed timestamp and the template's form groups
   * to determine if a full form is valid
   *
   * @param form DeForm
   */
  formIsComplete(form: DeForm, formGroupRef: FormGroup) {
    return form?.form_metadata?.completed_timestamp
      || (
        form?.form_template?.components?.length > 0
        // Ensures there isn't a high-level form section where the dependencies are met and the form group is invalid
        && !form?.form_template?.components?.some(
          c => dependenciesAreMet(formGroupRef, c.depends_on) && !(c as DeSection).form_group?.valid
        )
      );
  }
}
