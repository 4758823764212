
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// @ts-ignore
import {env} from '../../config/env';
import buildInfoJson from '../../config/build-info.json';
import {environments} from '../../config/env.config';

@Injectable()
export class AppConfig {
  private config: any;
  currentEnvironment = env.env;
  environmentConfigs = environments;
  constructor(private http: HttpClient) {
  }
  /*
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  public load() {
    this.config = this.environmentConfigs[this.currentEnvironment];
  }
}
