import { EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, Validators } from '@angular/forms';
import { DeFormElement } from '../models/DeTemplateForms';

export class FormElementComponent<T> implements ControlValueAccessor {

  _value: T;
  _disabled = false;
  _touched = false;
  _focused = false;
  _hasValue = false;
  _matchesRegexValidation = true;

  onChange: (value: T) => {};
  onTouched: () => {};

  constructor() { }

  valueChange(value: T, matchesValidators = true) {
    this._matchesRegexValidation = matchesValidators;
    this._value = value;
    this._hasValue = !([undefined, null, ''] as any[]).includes(this._value);
    // TODO: Uncomment if we need to check for empty arrays
      // && !(Array.isArray(this._value) && this._value.length < 1);
    if (this.onChange) {
      this.onChange(value);
    }
  }

  touch() {
    if (!this._touched) {
      this._touched = true;
      if (this.onTouched) {
        this.onTouched();
      }
    }
  }

  focus() {
    this._focused = true;
  }

  blur() {
    this._focused = false;
    this.touch();
  }

  isRequired(formElement: DeFormElement) {
    return formElement?.form_control?.hasValidator(Validators.required) ?? false;
  }

  // ControlValueAccessor methods

  writeValue(val: T): void {
    this.valueChange(val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
}
