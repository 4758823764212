<label for="{{id}}" [class.focused]="_focused" [class.hasValue]="_hasValue">{{label}}</label>

<div class="searchable-input">
  <div *ngIf="searchable && multiple && hasValue">
    <!-- Chips (not visible when option summary cards are enabled) -->
    <ng-container *ngIf="!option_summary_cards">
      <div *ngFor="let value of _value">
        {{value}}
        <button [attr.aria-label]="value" (click)="removeSelectedOption(value, $event)"
          [disabled]="readOnly || _disabled" type="button"></button>
      </div>
    </ng-container>
  </div>
  <input #searchInput *ngIf="searchable" [class.hasValue]="_hasValue" [class.multiple]="multiple"
    (focus)="focus(); toggleSelect(true)" (blur)="blur()" [class.error]="hasError" [class.selecting]="selecting"
    [formControl]="searchFormControl" [placeholder]="searchPlaceholder" [readonly]="readOnly || _disabled">
  <button *ngIf="!readOnly" class="triangle-select-button" (click)="toggleSelect()"></button>
</div>

<div *ngIf="!searchable" class="non-search-select-open" [class.multiple]="multiple">
  <ng-container *ngIf="multiple && hasValue">
    <div *ngFor="let value of _value">
      {{value}}
      <button [attr.aria-label]="value" (click)="selectOption(value)" [disabled]="readOnly || _disabled"
        type="button"></button>
    </div>
  </ng-container>
  <button [disabled]="readOnly || _disabled" aria-label="Toggle Select" [class.hasValue]="_hasValue" (focus)="focus()"
    (blur)="blur()" (click)="toggleSelect()" [class.error]="hasError" [class.selecting]="selecting"
    [class.multiple]="multiple" type="button">
    <span *ngIf="!(multiple && hasValue)">{{_value || placeholder}}</span>
    <span *ngIf="multiple && hasValue">{{placeholder}}</span>
  </button>
  <button *ngIf="!readOnly" class="triangle-select-button" (click)="toggleSelect()"></button>
</div>

<!-- List of selectable options -->
<div *ngIf="selecting" [class.selecting]="selecting" [class.searchable]="searchable" class="styled-select"
  [class.multiple]="multiple" [attr.aria-hidden]="!selecting" (blur)="toggleSelect()">
  <ul #hoverOptions>
    <li *ngFor="let option of options; let i = index" [class.selected]="selected(option)" [class.multiple]="multiple">
      <button #selectOptionButton (click)="selectOption(option)" (blur)="hoverOptionBlur()"
        (focus)="hoverOptionFocus($event.target)" [attr.aria-label]="option" [disabled]="readOnly || _disabled"
        type="button" [class.selected]="selected(option)" [class.multiple]="multiple">
        <div class="circle-checkbox" [class.multiple]="multiple">
          <ng-container *ngIf="!multiple">{{option}}</ng-container>
          <div *ngIf="multiple && selected(option)" class="check">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1.3rem"
              height="1.3rem" viewBox="0 0 76 76">
              <defs>
                <path id="icon-positive-check-a"
                  d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
              </defs>
              <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
            </svg>
          </div>
        </div>
        <input *ngIf="multiple" [value]="option" [disabled]="readOnly || _disabled" type="checkbox"
          [checked]="selected(option)" (click)="selectOption(option)" name="{{id}}-checkbox-{{i}}">
        <label *ngIf="multiple" for="{{id}}-checkbox-{{i}}"
          (click)="selectOptionButton.focus(); selectOptionButton.click()">{{option}}</label>
      </button>
    </li>
  </ul>
</div>

<!-- Option Summary Cards -->
<ul *ngIf="option_summary_cards && _value?.length > 0" class="option-summary-cards">
  <ng-container *ngFor="let selectedOption of _value; let i = index">
    <!-- Hides the custom other value card, as this value appears within the 'Other' card as a textarea -->
    <li *ngIf="!(otherValueIndex > -1) || i <= otherValueIndex">
      <div class="option-summary-cards-header">
        <!-- Readonly checkmark -->
        <div class="option-summary-cards-header-check">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1.3rem"
              height="1.3rem" viewBox="0 0 76 76">
              <defs>
                <path id="icon-positive-check-a"
                  d="M74.9983003,3.51753136 C73.5442721,1.7900074 70.9962608,1.49747078 69.1873805,2.84590882 C68.3270805,3.48983559 48.4155493,18.5442203 23.2556688,52.7987009 L6.83553621,40.3667599 C5.10455028,39.0564036 2.66212913,39.2554669 1.17001925,40.8323951 C-0.323821608,42.4075923 -0.396523017,44.8534755 1.00557559,46.513491 L22.8835068,72.4332743 C23.6988012,73.4008955 24.8966434,73.946156 26.1377604,73.946156 C26.3800984,73.946156 26.6189744,73.9253842 26.8595815,73.8838405 C28.3499604,73.6293856 29.5928083,72.60118 30.1259519,71.1886954 C30.241928,70.8823109 42.0212873,40.3027134 74.669413,9.35441593 C76.3069257,7.80345253 76.4523285,5.24332434 74.9983003,3.51753136" />
              </defs>
              <use fill="#00789E" fill-rule="evenodd" xlink:href="#icon-positive-check-a" />
            </svg>
          </div>
        </div>
        <!-- Option title -->
        <h4>{{selectedOption}}</h4>
        <!-- Removes the option -->
        <button *ngIf="!readOnly && !_disabled" (click)="removeSelectedOption(selectedOption, $event)"
          type="button">Remove</button>
      </div>
      <div class="option-summary-cards-content">
        <!-- Option summary (for non-'Other' options) -->
        <p *ngIf="selectedOption !== 'Other' && !formElement.allow_editing_option_summary">
          {{option_summary_cards[selectedOption]}}</p>
        <!-- Textarea for inputting the 'Other' option description -->
        <ng-container *ngIf="selectedOption === 'Other' || formElement.allow_editing_option_summary">
          <textarea id="{{id}}_other_textarea" name="{{id}}_other_textarea" [disabled]="readOnly || _disabled"
            [ngModel]="customOtherValue" (ngModelChange)="customOtherValueChange($event)"
            [class.hasValue]="customOtherValue?.length > 0" aria-placeholder="Describe Other Hazard"
            placeholder="Describe Other Hazard" autocomplete="off" aria-required="true" required="true"
            maxlength="1000"></textarea>
          <span>{{customOtherValue?.length || 0}}/1000</span>
        </ng-container>
      </div>
    </li>
  </ng-container>
</ul>